/**
 * Author: rinaldiguarsa
 * Created On: 12/31/18
 * Project: tado-tv-player
 */

import React, {Component} from 'react';
import {axiosClientTadoPlayer} from "../utils/axiosClient";
import {BrowserDetect,insideIframe,convertToObject} from '../utils/utils';
import MobileDetect from '../../node_modules/mobile-detect/';
import './Player.less'
import LandingPage from './LandingPage';
import MobilePlayer from './MobilePlayer';
import WebPlayer from './WebPlayer';
import { movieDetail } from '../constants/dummy';

class Player extends Component {
    constructor(props) {
        super(props);
        const { match: { params } } = props;
        let forceMobile = false;
        let client_id = null;
        let noredirect = false;
        let sendp = false;
        let nofs = false;
        let showRotate = false;
        if(this.props.location.search!==undefined){
            const qs = convertToObject(this.props.location.search);
            forceMobile = (qs.forceMobile!==undefined);
            client_id = (qs.client_name!==undefined)?qs.client_name:null;
            noredirect = (qs.noredirect!==undefined);
            sendp = (qs.sendp!==undefined);
            nofs = (qs.nofs!==undefined);
            showRotate = (qs.showRotate!==undefined);
        }

        this.state = {
            busy: true,
            showBlank:false,
            slug: params.slug,
            md : new MobileDetect(window.navigator.userAgent),
            browserDetect : BrowserDetect(),
            inFrame : insideIframe(),
            title:"Semesta Akademi",
            synopsis:"",
            analytic:false,
            is_series:false,
            genre:"",
            artwork:{
                small:"",
                large:""
            },
            thumbnail: {
                mobile: null,
                desktop: null
            },
            series_name:"",
            whiteLabel:false,
            forceMobile:forceMobile,
            client_id:client_id,
            noredirect:noredirect,
            sendp:sendp,
            nofs:nofs,
            showRotate:showRotate,
        }
    }

    componentDidMount() {
        let avail = false;
        let data = {};
        try{
            const loadJson = require(`../assets/data/${this.state.slug}.json`);
            data = loadJson.data || {};
            avail = true;
        }catch (e) {}
        this.setState({
            ...data,
            analytic: false,
            busy:false,
            showBlank: !avail,
            whiteLabel:true
        })
    }

    render() {
        if(!this.state.busy){
            if(this.state.md.mobile()!==null || this.state.forceMobile){
                return (

                    <MobilePlayer clientId={this.state.client_id} forceMobile={this.state.forceMobile}
                                  inFrame={this.state.inFrame} analytic={this.state.analytic}
                                  whiteLabel={this.state.whiteLabel} mobileDetect={this.state.md}
                                  browserDetect={this.state.browserDetect} slug={this.state.slug}
                                  noredirect={this.state.noredirect} sendp={this.state.sendp}
                                  nofs={this.state.nofs} showRotate={this.state.showRotate} showBlank={this.state.showBlank}/>
                )
            }else{
                if(this.state.inFrame || this.state.thumbnail.desktop!=null){
                    return (
                        <WebPlayer clientId={this.state.client_id} analytic={this.state.analytic}
                                   whiteLabel={this.state.whiteLabel} browserDetect={this.state.browserDetect}
                                   mobileDetect={this.state.md} slug={this.state.slug} inFrame={this.state.inFrame}
                                   sendp={this.state.sendp} showBlank={this.state.showBlank}/>
                    )
                }else{
                    return (
                        <LandingPage
                            slug={this.state.slug}
                            title={this.state.title}
                            series_name={this.state.series_name}
                            synopsis={this.state.synopsis}
                            artwork={this.state.artwork.small}
                        />
                    )
                }
            }
        }else{
            return (<div className="loader-container">
                <div className="spinners-container">
                    <div className="spinner-block">
                        <div className="spinner-eff spinner-eff-4">
                            <div className="bar bar-top"></div>
                            <div className="bar bar-right"></div>
                            <div className="bar bar-bottom"></div>
                            <div className="bar bar-left"></div>
                        </div>
                        <span>Loading</span>
                    </div>
                </div>
            </div>);
        }


    }
}

export default Player;
