/**
 * Created by kuyarawa on 11/07/18.
 */

import React, {Component} from 'react'
import _ from 'lodash';
import CONFIG from '../constants/config';
import {
    logger,
    mapTextQuality,
    mapTextSubtitle,
    vis,
    seekTime,
    idleTimePause,
    idleTimeVideoControl,
    voidBridgeTime,
    mapDimensionQuality
} from '../utils/utils'
import MetaTags from 'react-meta-tags';
import {defaultBannerState,generateDefaultStatePlayer,configPlayer} from "../utils/const";
import PausePoster from '../components/PausePoster';
import EndPoster from '../components/EndPoster';
import InitPosterWeb from "../components/InitPosterWeb";
import BridgeControl from "../components/BridgeControl";
import SettingContent from "../components/SettingContent";
import Share from "../components/Share";
import Banner from "../components/Banner"
import Lang from "../utils/Lang"
import Tracking from "../utils/Tracking"
import EpisodeList from '../components/EpisodeList'
import PlayerHelper from '../utils/PlayerHelper'
import LoadingVideo from '../components/loadingVideo'

import shareButton from '../assets/images/Web/Share.png';
import tadologoWhite from '../assets/images/tadoPlayer/Dekstop/TADOlogo.png';

//Bottom Control
import playSmall from '../assets/images/tadoPlayer/Dekstop/Button Small/Play[SMALL].png'
import pauseSmall from '../assets/images/tadoPlayer/Dekstop/Button Small/Pause-Small.png'
import ffSmall from '../assets/images/tadoPlayer/ff-10s.png'
import bwSmall from '../assets/images/tadoPlayer/back-10s.png'
import volumeMaxSmall from '../assets/images/Web/loud.png'
import volumeHalfSmall from '../assets/images/Web/small.png'
import volumeMuteSmall from '../assets/images/Web/mute.png'
import settingSmall from '../assets/images/Web/Settings.png'
import fullScreenSmall from '../assets/images/Web/Fullscreen.png'
import eFullScreenSmall from '../assets/images/Web/Exit-Fullscreen.png'
import EpisodeListSmall from '../assets/images/Web/Button/Episode List.png'
import NextEpisodeSmall from '../assets/images/Web/Next-Episode.png'
import NextEpisodeDisSmall from '../assets/images/Web/Next-EpisodeDisabled.png'

//Splash
import playSplash from '../assets/images/tadoPlayer/Dekstop/Play-Splash.png'
import pauseSplash from '../assets/images/tadoPlayer/Dekstop/Pause-Splash.png'
import forwardSplash from '../assets/images/tadoPlayer/FF-10s-Splash.png'
import backwardSplash from '../assets/images/tadoPlayer/Rewind-10s-Splash.png'

import './WebPlayer.less'

import videojs from 'video.js';
import videojslevel from '../../node_modules/videojs-contrib-quality-levels/dist/videojs-contrib-quality-levels';
import '../assets/css/video-js.less';
import {moviePlot} from "../constants/dummy";
window.videojs = videojs;
window.vis = vis();
const tracking = new Tracking(true);
const playerHelper = new PlayerHelper();
const seekForward = seekTime;
const seekBackward = seekTime*-1;

class WebPlayer extends Component {
    constructor(props) {
        super(props);
        this.lang = new Lang();
        const initTranslate = this.lang.translate();
        const tempState = generateDefaultStatePlayer(this.props.slug,this.props.whiteLabel,this.props.mobileDetect,
            this.props.browserDetect,this.props.analytic,playSmall,volumeMaxSmall,fullScreenSmall,playSplash,initTranslate.setting.automatic,
            initTranslate.setting.off,false,false,initTranslate,this.props.inFrame,null,initTranslate.loading_text_start,this.props.clientId,this.props.sendp)
        this.defaultState = tempState;
        this.state = {...tempState, showBlank: props.showBlank };
        this.lastCheckpoint = this.lastCheckpoint.bind(this);
        this.replayMovie = this.replayMovie.bind(this);
        this.handlerPlayPause = this.handlerPlayPause.bind(this);
        this.handlerVolumeBox = this.handlerVolumeBox.bind(this);
        this.handlerChangeVolume = this.handlerChangeVolume.bind(this);
        this.playNextMovie = this.playNextMovie.bind(this);
        this.handlerVideoJs = this.handlerVideoJs.bind(this);
        this.handlerFullscreen = this.handlerFullscreen.bind(this);
        this.handlerSettingControl = this.handlerSettingControl.bind(this);
        this.handlerShare = this.handlerShare.bind(this);
        this.handlerEpisodeList = this.handlerEpisodeList.bind(this);
        tracking.setAnalyticStatus(this.state.analytic);
        tracking.setInFrame(this.state.inFrame);
        playerHelper.initWatchTimer(this);

    }

    componentDidMount() {
        this.setState({busy: true});
        playerHelper.platformInit((countryId)=>{
            this.setState({
                defaultLang:countryId,
                bridgeDefaultLang:countryId,
                lang:this.lang.translate(countryId)
            })
        },(token)=>{
            tracking.setToken(token)
            this.getMovieDetail()
        });

        logger('player','Initialize clip player object');
        this.player = window.videojs('my-video',configPlayer);
        logger('player','Initialize player clip object');
        window.videojs.registerPlugin('qualityLevels',videojslevel);
        logger('player','Register Plugin Quality Levels');
        this.playerBridge = window.videojs('my-videobridge',configPlayer);
        logger('player','Initialize player bridge object');

        this.initialEventPlayer();
        playerHelper.init(this.player,this.state.isMobile,this.state.bd);
        let _this = this;
        window.vis(function(){
            const player = _this.state.playerInfo.videoType==='bridge'?_this.playerBridge:_this.player;
            if(window.vis()){
                setTimeout(function(){
                    if(!_this.state.playerInfo.hasEnded && !_this.state.elm.isPause && _this.state.playerInfo.hasStart){
                        player.play();
                        playerHelper.sendWatchTimerFallback((data)=>{
                            tracking.bulkWatchTime(data);
                        })
                    }
                },1000);
            } else {
                if(!_this.state.playerInfo.hasEnded && !_this.state.elm.isPause && _this.state.playerInfo.hasStart){
                    player.pause();
                    const buildData = playerHelper.buildDataSendWatchTracker();
                    playerHelper.putDataTrackWatchTimeFallback(buildData);
                    playerHelper.resetWatchTimerData(_this);
                }
            }
        });
        window.addEventListener('offline', (event)=>{
            const condition = navigator.onLine ? "online" : "offline";
            if(condition==='offline'){
                playerHelper.sendWatchTimer(_this,(data)=>{
                    tracking.bulkWatchTime(data,(newData)=>{
                        playerHelper.putDataTrackWatchTimeFallback(newData);
                    });
                })
            }
        });
        window.addEventListener('online', (event)=>{
            const condition = navigator.onLine ? "online" : "offline";
            if(condition==='online'){
                playerHelper.sendWatchTimerFallback((data)=>{
                    tracking.bulkWatchTime(data);
                })
            }
        });
        logger('Event Listener','Add mousemove document event');
        document.addEventListener("mousemove", (event)=>{
            if(_this.state.elm.showPausePoster){
                _this.setElmState({
                    showPausePoster:false,
                },()=>_this.setPausePosterTimeout())
            }
        });
        document.addEventListener('fullscreenchange', ()=>{
            if(!document.fullscreenElement && this.state.elm.isFullscreen){
                this.handlerFullscreen()
            }
        });
        document.addEventListener('webkitfullscreenchange', ()=>{
            if(!document.webkitIsFullScreen && this.state.elm.isFullscreen){
                this.handlerFullscreen()
            }
        });
        document.addEventListener('mozfullscreenchange', ()=>{
            if(!document.mozFullScreen && this.state.elm.isFullscreen){
                this.handlerFullscreen()
            }
        });
        document.addEventListener('MSFullscreenChange', ()=>{
            if(!document.msFullscreenElement && this.state.elm.isFullscreen){
                this.handlerFullscreen()
            }
        });
        document.addEventListener('keyup', function (event) {
            const key = event.keyCode || event.which;
            if(key!==32 && _this.state.playerInfo.videoType==='movie' && !_this.state.playerInfo.hasEnded){
                event.preventDefault();
                if(key===37){
                    _this.seeking(seekBackward);
                }else if(key===39){
                    _this.seeking(seekForward);
                }
                if(key===38 || key===40){
                    let volume = _this.state.playerInfo.volume;
                    if(key===38){
                        volume+=0.1;
                    }else if(key===40){
                        if(volume<=0.11){
                            volume = 0;
                        }else{
                            volume-=0.1;
                        }
                    }
                    if(volume>=0 && volume<=1){
                        _this.changeVolumeBar(volume);
                    }
                }
            }else if(key===32 && !_this.state.playerInfo.hasEnded){
                _this.handlerPlayPause();
            }
        });
        //set switch player layer element every 50 ms
        this.intervalPlayer = setInterval(()=>{
            if(this.state.playerInfo.hasStart && !this.state.playerInfo.hasEnded && !this.state.elm.showPausePoster){
                if(this.state.playerInfo.videoType==='bridge' && this.playerBridge.currentTime()>0){
                    playerHelper.switchPlayer('bridge');
                }else if(this.state.playerInfo.videoType==='movie' && this.player.currentTime()>0){
                    playerHelper.switchPlayer('clip');
                }
            }
        },50);
    }

    getMovieDetail(){
        let avail = false;
        let data = {};
        try{
            const loadJson = require(`../assets/data/${this.state.slug}.json`);
            data = loadJson.data || {};
            avail = true;
        }catch (e) {}
        this.setState({
            ...data,
            analytic: false,
            is_series: false,
            showBlank: !avail,
            busy:false,
            loadingText:this.state.lang.general.loading_text_start,
            bridgeDefaultLang:(moviePlot.data.primary_lang!==null)?moviePlot.data.primary_lang:this.state.defaultLang
        },()=>{
            playerHelper.sendWatchTimerFallback((data)=>{
                tracking.bulkWatchTime(data);
            })
            playerHelper.sendWatchTimer(this,(data)=>{
                tracking.bulkWatchTime(data,(newData)=>{
                    playerHelper.putDataTrackWatchTimeFallback(newData);
                })
            })
            playerHelper.isMoviePotrait = this.state.is_potrait;
            if(!this.state.is_potrait){
                playerHelper.forceHorizontal();
            }
            if(this.state.is_series){
                this.isLastEpisode();
            }
            tracking.pxTrackPageView();
            this.setElmState({
                showAndroidImage: true,
                showIphoneImage: true,
                showFullscreenButton: this.state.allow_fullscreen
            },()=>{
                logger('Set Initial source bridge player','');
                this.playerBridge.src({
                    src: this.state.moviePlot[this.state.playerInfo.currentIndexMovie].bridge_url_resource.auto,
                    type: 'application/x-mpegURL'
                });
                this.setPlayerInfoState({
                    nextIndexMovie:_.findIndex(this.state.moviePlot, { "id": this.state.moviePlot[this.state.playerInfo.currentIndexMovie].default_option})
                },()=>{
                    this.setBannerMedia();
                    playerHelper.switchPlayer('clip');
                    this.generateBridgeObject();
                    if((this.state.bd==='Webkit')) {
                        logger('Set clip source if iOS (mobile) or web with webkit engine ','');
                        this.player.src({
                            src: this.state.moviePlot[this.state.playerInfo.currentIndexMovie].url_resource.auto,
                            type: 'application/x-mpegURL'
                        });
                    }
                });
            });
        })
    }

    initialPlay(){
        if(!this.state.busy){
            this.setElmState({
                showInitialPoster:false,
                showVideoContainer:true,
            },()=>{
                this.setPlayerInfoState({
                    hasStart:true
                },()=>{
                    tracking.pxTrackInitialPlay(this.state.title,this.state.series_name,this.state.genre);
                    const is_endplot = this.state.moviePlot[this.state.playerInfo.currentIndexMovie].metadata.is_endplot;
                    const is_firstplot = this.state.playerInfo.currentIndexMovie===0;
                    tracking.playVideo(this.state.moviePlot[this.state.playerInfo.currentIndexMovie].id,this.state.watch_uid,is_endplot,is_firstplot);
                    if((this.state.bd!=='Webkit')){
                        this.player.src({
                            src: this.state.moviePlot[this.state.playerInfo.currentIndexMovie].url_resource.auto,
                            type: 'application/x-mpegURL'
                        });
                        setTimeout(()=>{
                            this.loadedDataClip(()=>{
                                this.player.play();
                            });
                        },200);
                    }else{
                        this.loadedDataClip();
                        this.setElmState({
                            showSettingContainer:true,
                            showVideoControl:true,
                        },()=>{
                            playerHelper.toggleSettingContainer(true);
                            playerHelper.toggleVideoControl(true);
                            this.player.play();
                        })
                    }
                })
            });
        }
    }

    loadedDataClip(callback = null){
        logger('Player Clip Load Data','');
        this.setState({busy:false,loadingText:this.state.lang.general.loading_text_start});
        if(this.state.playerInfo.hasStart && !this.state.elm.isPause){
            const isFirstClip = this.state.playerInfo.currentIndexMovie===0;
            logger('Player Clip Load Data','set duration and volume clip');
            const representations = playerHelper.composeQualityLevels(this.player.qualityLevels());
            logger('Player Clip Load Data get quality level',this.player.qualityLevels());
            this.setPlayerInfoState({
                duration:this.player.duration(),
                volume:this.player.volume(),
                subtitleList:this.state.moviePlot[this.state.playerInfo.currentIndexMovie].subtitle,
                clipState:'play',
                currentTime:0,
                qualityLevelList:representations,
            },()=>{
                if(!this.state.playerInfo.muted){
                    logger('Player Load Data','set volume bar clip if not mute');
                    this.changeVolumeBar(this.state.playerInfo.volume);
                }
                logger('Player Clip Load Data','set state show video control');
                this.setElmState({
                    showSettingContainer:true,
                    showVideoControl:true,
                    showBridgeControl:false,
                    showSettingButton:true,
                    showShareButton:true,
                    showVolumeBox:false,
                    isOpenVideoControl:isFirstClip,
                    isOpenSettingControl:isFirstClip,
                    playPauseButton:pauseSmall,
                    fsUpper:false,
                    fsLower:true
                },()=>{
                    //reset bar bridge
                    playerHelper.changeBarBridge(0);
                    logger('Player Clip Load Data: set Subtitle list option',this.state.moviePlot[this.state.playerInfo.currentIndexMovie].subtitle);
                    this.setVideoControlTimeout();
                    logger('Player Clip Load Data','change subtitle based on previous subtitle setting');
                    this.changeSubtitle(this.state.playerInfo.subtitle);
                    playerHelper.toggleSettingContainer(isFirstClip);
                    playerHelper.toggleVideoControl(isFirstClip);
                    if(callback!==null){
                        callback();
                    }
                });
            });
        }else if(this.state.elm.isPause){
            logger('Player Clip Load Data','set subtitle clip when clip is paused');
            this.changeSubtitle(this.state.playerInfo.subtitle);
            if(callback!==null){
                callback();
            }
        }

    }

    playerEventClip(){
        let _this = this;
        this.player.ready(function() {
            const qualityLevels = this.qualityLevels();
            qualityLevels.on('addqualitylevel', function(event) {
                logger('add quality level',event.qualityLevel);
                const qualityLevelLabel = _this.getActualQualityLevel();
                const qualityWidth = mapDimensionQuality(qualityLevelLabel);
                const dimensionToCompare=_this.state.is_potrait?event.qualityLevel.width:event.qualityLevel.height;
                if (dimensionToCompare === qualityWidth || qualityLevelLabel==='auto') {
                    event.qualityLevel.enabled = true;
                    logger('set quality level enable',dimensionToCompare);
                } else {
                    event.qualityLevel.enabled = false;
                    logger('set quality level disable',dimensionToCompare);
                }

                if(_this.state.playerInfo.clipState==='play'){
                    const representations = playerHelper.composeQualityLevels(_this.player.qualityLevels());
                    _this.setPlayerInfoState({
                        qualityLevelList: representations
                    });
                }
            });
            //event on metadata loaded
            this.on('loadedmetadata',function(){
                logger('Player Clip Load Metadata',this.videoHeight());
                _this.changeSubtitle(_this.state.playerInfo.subtitle);
            });
            this.on('loadeddata',function(){});
            this.on('loadstart',function(){});

            this.on('ended',function(){
                logger('Player Ended','');
                _this.setElmState({
                    isPause:false,
                    showPausePoster:false,
                    showEpisodeList:false
                },()=>{
                    const playedMovie = _this.state.moviePlot[_this.state.playerInfo.currentIndexMovie];
                    playerHelper.sendWatchTimer(_this,(data)=>{
                        tracking.bulkWatchTime(data,(newData)=>{
                            playerHelper.putDataTrackWatchTimeFallback(newData);
                        });
                    })
                    if(playedMovie.bridge_url!==""){
                        _this.setPlayerInfoState({
                            videoType:'bridge',
                            currentTime:0
                        }, () => {
                            if(_this.state.playerInfo.currentIndexMovie === 0){
                                if(_this.state.inFrame && _this.state.sendp){
                                    window.parent.postMessage("player:ended","*");
                                }
                            }
                            logger('Player Ended','play bridge after movie clip');
                            _this.playBridge()
                            _this.hideAllSettingControl();
                            _this.hideBanner();
                        })
                    }else{
                        if(this.videoControlTimeoutObj!==null){
                            clearTimeout(this.videoControlTimeoutObj);
                        }
                        _this.setPlayerInfoState({
                            hasEnded:true
                        },()=>{
                            // playerHelper.sendWatchTimer(_this,(data)=>{
                            //     tracking.bulkWatchTime(data,(newData)=>{
                            //         playerHelper.putDataTrackWatchTimeFallback(newData);
                            //     });
                            // })
                            logger('Player Ended','End of Plot and show End Poster');
                            tracking.pxTrackCompleteMovie();
                            _this.setElmState({
                                showEndPoster: true
                            },()=>{
                                _this.setElmState({
                                    showSettingContainer:true,
                                    showVideoControl:false,
                                    showSettingButton:false,
                                    showShareButton:true,
                                    isOpenVideoControl:false,
                                    isOpenSettingControl:false,
                                    fsUpper:true,
                                    fsLower:false
                                },()=>{
                                    _this.hideAllSettingControl();
                                    playerHelper.toggleSettingContainer(true);
                                });
                            });
                        });
                    }
                })
            });
            this.on('timeupdate',function(){
                playerHelper.hideLoading()
                if(isNaN(_this.state.playerInfo.duration)){
                    _this.setPlayerInfoState({duration:this.duration()});
                }

                const currentTime = this.currentTime();
                const moviePlayed = _this.state.moviePlot[_this.state.playerInfo.currentIndexMovie];
                if(_this.state.playerInfo.clipState==='play'){
                    playerHelper.calculateWatchTimer(_this.state.watch_uid,moviePlayed.id,currentTime,_this);
                    window.parent.postMessage("player:timeupdate:" + currentTime,"*");
                }

                if(currentTime>0 && _this.state.loadingText!==_this.state.lang.general.loading_text_playing){
                    _this.setState({
                        loadingText:_this.state.lang.general.loading_text_playing
                    })
                }
                _this.toggleBanner(currentTime);
            });
            this.on("canplaythrough", function(){});
            this.on("canplay", function(){});
            this.on("waiting",function(){
                if(_this.state.playerInfo.clipState==='play') playerHelper.showLoading();
            });
            this.on("playing",function(){
                if(_this.state.playerInfo.clipState==='play') playerHelper.hideLoading();
            });
            this.on("play",function(){
                if(_this.state.playerInfo.clipState==='play') playerHelper.hideLoading();
            });
            _this.setElmState({
                playPauseButton:pauseSmall
            })
        });
    }

    playerEventBridge(){
        const _this = this;
        this.playerBridge.ready(function() {
            //event on metadata loaded
            this.on('loadedmetadata',function(){
                logger('Player Bridge Load Metadata',this.videoHeight());
            });
            this.on('loadeddata',function(){});
            this.on('ended',function(){
                logger('Player Bridge Ended','');
                _this.setElmState({
                    isPause:false,
                    showPausePoster:false
                },()=>{
                    logger('Player Bridge Ended','play next movie clip movie clip');
                    _this.playNextMovie(_this.state.moviePlot[_this.state.playerInfo.nextIndexMovie].id,true,false);
                })
            });
            this.on('timeupdate',function(){
                const currTime = this.currentTime();
                const duration = this.duration();
                const moviePlayed = _this.state.moviePlot[_this.state.playerInfo.currentIndexMovie];

                if(isNaN(_this.state.playerInfo.duration)){
                    _this.setPlayerInfoState({
                        duration:this.duration()
                    });
                }
                if(_this.state.playerInfo.bridgeState==='play'){
                    playerHelper.calculateWatchTimer(_this.state.watch_uid,moviePlayed.bridge_id,currTime,_this);
                    window.parent.postMessage("player:timeupdate:" + currTime,"*");
                    if(currTime>0 && _this.state.loadingText!==_this.state.lang.general.loading_text_playing){
                        _this.setState({
                            loadingText:_this.state.lang.general.loading_text_playing
                        })
                    }
                    const percentage = parseFloat((currTime/(duration-voidBridgeTime))*100).toFixed(3);
                    playerHelper.changeBarBridge(percentage)
                    _this.toggleBanner(currTime);
                    if(!_this.state.playerInfo.lockedOpt && (duration-currTime)<=3){
                        const idxMovie = _this.state.moviePlot[_this.state.playerInfo.nextIndexMovie].id;
                        const listOptBridge = document.querySelectorAll('.bridge-option-item');
                        listOptBridge.forEach((item,index)=>{
                            if(!item.classList.contains(`opt-${idxMovie}`)) {
                                item.style.display='none';
                            }
                        });
                        const opt =  document.querySelector(`.opt-${idxMovie}`);
                        if(!opt.classList.contains('active')){
                            opt.classList.add('active');
                        }
                        opt.classList.add('slide-up-fade-in-fast');
                        _this.setPlayerInfoState({
                            lockedOpt:true
                        },()=>{
                            const moviePlayed = _this.state.moviePlot[_this.state.playerInfo.nextIndexMovie];
                            let actualQualityLevel = 'auto';
                            if(moviePlayed.url_resource[_this.state.playerInfo.qualityLevel]!==undefined){
                                actualQualityLevel = _this.state.playerInfo.qualityLevel;
                            }
                            _this.player.src({
                                src: moviePlayed.url_resource.auto,
                                type: 'application/x-mpegURL'
                            });
                            setTimeout(()=>{
                                playerHelper.setQualityLevel(_this.player.qualityLevels(),actualQualityLevel);
                            },200);
                            setTimeout(()=>{
                                const opta =  document.querySelector(`.opt-${idxMovie} a`);
                                if(!opta.classList.contains('blink-border')){
                                    opta.classList.remove('blink-border');
                                }
                                opta.classList.add('blink-border');
                                setTimeout(()=>{
                                    const bridgeControl = document.getElementById('bridge-control');
                                    bridgeControl.classList.remove('slide-up-fade-in');
                                    bridgeControl.classList.add('slide-up-fade-out');
                                },500);
                            },1200);
                        })
                    }
                }

            });
            this.on("waiting",function(){
                if(_this.state.playerInfo.bridgeState==='play') playerHelper.showLoading();
            });
            this.on("playing",function(){
                if(_this.state.playerInfo.bridgeState==='play') playerHelper.hideLoading();
            });
            this.on("play",function(){
                if(_this.state.playerInfo.bridgeState==='play') playerHelper.hideLoading();
            });
        });
    }

    //video js handler
    initialEventPlayer() {
        this.playerEventClip();
        this.playerEventBridge();
    }

    playMovie(isChangeSource = true){
        logger('Play Video Index',this.state.playerInfo.currentIndexMovie);
        const playedMovie = this.state.moviePlot[this.state.playerInfo.currentIndexMovie];
        let actualQualityLevel = 'auto';
        if(playedMovie.url_resource[this.state.playerInfo.qualityLevel]!==undefined){
            actualQualityLevel = this.state.playerInfo.qualityLevel;
        }
        const is_firstplot = this.state.playerInfo.currentIndexMovie===0;
        const is_endplot = playedMovie.metadata.is_endplot;
        tracking.playVideo(playedMovie.id,this.state.watch_uid,is_endplot,is_firstplot);
        this.loadedDataClip(()=> {
            if(isChangeSource){
                this.player.src({
                    src: playedMovie.url_resource.auto,
                    type: 'application/x-mpegURL'
                });
                setTimeout(()=>{
                    playerHelper.setQualityLevel(this.player.qualityLevels(),actualQualityLevel);
                },500);
            }
            this.player.play();
            if(playedMovie.bridge_url!=='') {
                this.setPlayerInfoState({
                    bridgeState: 'prebuffer'
                }, () => {
                    this.playerBridge.src({
                        src: playedMovie.bridge_url_resource[actualQualityLevel],
                        type: 'application/x-mpegURL'
                    });
                });
            }else if(!this.playerBridge.paused()){
                this.playerBridge.pause();
            }
            this.setBannerMedia();
        });

    }

    playBridge(){
        this.setState({loadingText:this.state.lang.general.loading_text_start});
        this.setElmState({
            isPause:false,
            showPausePoster:false
        },()=>{
            this.setPlayerInfoState({
                currentTime:0,
                bridgeState:'play',
                closedBanner:false,
                lockedOpt:false,
                clipState:'prebuffer'
            },()=>{
                logger('Player Bridge Load Data','set state show bridge control');
                this.setElmState({
                    showSettingContainer:true,
                    showVideoControl:false,
                    showBridgeControl:true,
                    showSettingButton:false,
                    showShareButton:false,
                    isOpenVideoControl:false,
                    isOpenSettingControl:false,
                    isPause:false,
                    fsUpper:true,
                    fsLower:false
                },()=>{
                    const playedMovie = this.state.moviePlot[this.state.playerInfo.currentIndexMovie];
                    const is_endplot = playedMovie.metadata.is_endplot;
                    const is_firstplot = this.state.playerInfo.currentIndexMovie===0;
                    tracking.playVideo(playedMovie.bridge_id,this.state.watch_uid,is_endplot,false);
                    logger('Player Bridge Load Data','hide video control on bridge');
                    playerHelper.toggleSettingContainer(true);
                    playerHelper.toggleVideoControl(true);
                    clearTimeout(this.videoControlTimeoutObj);
                    logger('Player Bridge Load Data','clear text subtitle on bridge clip');
                    playerHelper.clearTextTrack(this.player);
                    this.setBannerMedia();
                    this.playerBridge.muted(this.state.playerInfo.muted);
                    this.playerBridge.volume(this.state.playerInfo.volume);
                    this.playerBridge.play();
                });
            })
        });
    }
    isLastEpisode(){
        const episodeCount = this.state.episode_list.length;
        if(episodeCount>0){
            const indexMovie = _.findIndex(this.state.episode_list, { "id": this.state.id})
            const lastEpisode = indexMovie+1===episodeCount;
            this.setPlayerInfoState({
                isLastEpisode:lastEpisode,
                nextEpisodeSlug:!lastEpisode?this.state.episode_list[indexMovie+1].slug:""
            },()=>{
                logger('Episode',this.state.playerInfo.isLastEpisode+'-'+this.state.playerInfo.nextEpisodeSlug)
            })
        }
    }
    playNextEpisode(slug=""){
        slug = slug!==""?slug:this.state.playerInfo.nextEpisodeSlug;
        this.setState({
            slug:slug,
            busy: true
        },()=>{
            playerHelper.sendWatchTimer(this,(data)=>{
                tracking.bulkWatchTime(data,(newData)=>{
                    playerHelper.putDataTrackWatchTimeFallback(newData);
                });
            })
            playerHelper.getMovieDetail(this.state.defaultLang.toLowerCase(),this.state.slug,this.state.client_id).then(response => {
                this.setState({
                    ...response.data.data,
                    busy:false
                },()=>{
                    playerHelper.isMoviePotrait = this.state.is_potrait;
                    this.setElmState({
                        showEpisodeList:false,
                        showFullscreenButton: !this.state.isIphone && this.state.allow_fullscreen
                    },()=>{
                        this.replayMovie(()=>{
                            if(this.state.is_series){
                                this.isLastEpisode();
                            }
                        });
                    })
                })

            })
                .catch(error => {
                    this.setState({showBlank:true});
                    this.setState({busy: false})
                })
        })
    }
    //end videojs handler

    setElmState(data,callback=null){
        let elm = {...this.state.elm};
        _.forEach(data, function(value, key) {
            elm[key] = value;
        });
        this.setState({
            elm
        },callback);
    }

    setPlayerInfoState(data,callback = null){
        let playerInfo = {...this.state.playerInfo};
        _.forEach(data, function(value, key) {
            playerInfo[key] = value;
        });
        this.setState({
            playerInfo
        },callback);
    }

    setBridgeObjectState(data,callback = null){
        let bridgeObject = {...this.state.bridgeObject};
        _.forEach(data, function(value, key) {
            bridgeObject[key] = value;
        });
        this.setState({
            bridgeObject
        },callback);
    }
    //Handler Event
    handlerPlayPause(){
        if(!this.state.busy){
            const _this = this;
            const player = this.state.playerInfo.videoType==='bridge'?this.playerBridge:this.player;
            player.ready(function(){
                if(!player.paused()){
                    _this.pauseMovie();
                }else{
                    _this.unPauseMovie();
                }
            })
            this.hideAllSettingControl();
        }
    }

    pauseMovie(){
        this.setElmState({
            isPause:true,
            splashButton:pauseSplash,
            playPauseButton:playSmall,
            isOpenSettingControl:true,
            isOpenVideoControl:true,
        },()=>{
            playerHelper.toggleVideoControl(this.state.elm.isOpenVideoControl)
            playerHelper.toggleSettingContainer(this.state.elm.isOpenSettingControl)
            this.setPausePosterTimeout();
            playerHelper.toggleSplash();
            const player = this.state.playerInfo.videoType==='bridge'?this.playerBridge:this.player;
            player.pause();
            playerHelper.sendWatchTimer(this,(data)=>{
                tracking.bulkWatchTime(data,(newData)=>{
                    playerHelper.putDataTrackWatchTimeFallback(newData);
                });
            })
            if(this.videoControlTimeoutObj!==null){
                clearTimeout(this.videoControlTimeoutObj);
            }
        });
    }

    unPauseMovie(){
        this.setElmState({
            isPause:false,
            showPausePoster:false,
            splashButton:playSplash,
            playPauseButton:pauseSmall,
        },()=>{
            playerHelper.toggleSplash();
            const player = this.state.playerInfo.videoType==='bridge'?this.playerBridge:this.player;
            player.pause();
            player.play();
            this.setVideoControlTimeout();
        });
    }

    handlerVolumeBox(state){
        this.setElmState({
            showVolumeBox:state==='in'
        },()=>{
            if(this.state.elm.showVolumeBox){
                this.clearVideoControlTimeOut();
            }else if(!this.state.elm.isPause){
                this.setVideoControlTimeout()
            }
        })
    }

    toggleMuteVolume(event){
        if(event.target.tagName==='BUTTON' || event.target.tagName === 'IMG'){
            let volume = 0;
            if(this.state.playerInfo.muted){
                volume = this.state.playerInfo.volume;
            }
            this.changeVolumeBar(volume);
        }
    }

    handlerChangeVolume(event){
        this.changeVolumeBar(event.target.value);
    }

    getActualQualityLevel(){
        const moviePlayed = this.state.moviePlot[this.state.playerInfo.currentIndexMovie];
        let actualQualityLevel = 'auto';
        if(moviePlayed.url_resource[this.state.playerInfo.qualityLevel]!==undefined){
            actualQualityLevel = this.state.playerInfo.qualityLevel;
        }
        return actualQualityLevel;
    }

    replayMovie(callback=null){
        this.setPlayerInfoState({
            hasEnded:false,
            videoType:'movie',
            currentIndexMovie:0,
            currentTime:0,
            nextIndexMovie:_.findIndex(this.state.moviePlot, { "id":this.state.moviePlot[0].default_option})
        },()=>{
            this.setElmState({
                showEndPoster:false,
                playPauseButton:pauseSmall,
                isPause:false
            },()=>{
                this.generateBridgeObject();
                this.playMovie();
                if(callback!==null)callback()
            })
        })
    }

    lastCheckpoint(is_end_movie=true){
        if(this.state.playerInfo.currentIndexMovie!==0) {
            const lastIndexMovie = this.state.playerInfo.currentIndexMovie;
            let currentIndexMovie = this.state.playerInfo.lastIndexMovie;
            let nextIndexMovie = _.findIndex(this.state.moviePlot, { "id": this.state.moviePlot[currentIndexMovie].default_option});
            this.setPlayerInfoState({
                currentIndexMovie:currentIndexMovie,
                nextIndexMovie:nextIndexMovie,
                videoType:'bridge'
            }, () => {
                this.setElmState({
                    showEndPoster:false,
                    isPause:false
                },()=>{
                    this.player.pause();
                    playerHelper.sendWatchTimer(this,(data)=>{
                        tracking.bulkWatchTime(data,(newData)=>{
                            playerHelper.putDataTrackWatchTimeFallback(newData);
                        });
                    })
                    if(!is_end_movie){
                        const playedMovie = this.state.moviePlot[this.state.playerInfo.currentIndexMovie];
                        let actualQualityLevel = 'auto';
                        if(playedMovie.url_resource[actualQualityLevel]!==undefined){
                            actualQualityLevel = this.state.playerInfo.qualityLevel;
                        }
                        this.playerBridge.src({
                            src: playedMovie.bridge_url_resource[actualQualityLevel],
                            type: 'application/x-mpegURL'
                        });

                    }else{
                        this.setPlayerInfoState({
                            hasEnded:false,
                        },()=>{
                            this.playerBridge.currentTime(0);
                        });
                    }
                    playerHelper.clearTextTrack(this.player);
                    this.generateBridgeObject();
                    this.playBridge()
                })
            })
        }
    }
    handlerVideoJs(){
        if(this.state.playerInfo.videoType==='movie' && !this.state.elm.isPause){
            this.setElmState({
                showVolumeBox:false,
                isOpenSettingControl:true,
                isOpenVideoControl:true,
            },()=>{
                playerHelper.toggleVideoControl(this.state.elm.isOpenVideoControl)
                playerHelper.toggleSettingContainer(this.state.elm.isOpenSettingControl)
                if(!this.state.elm.showSettingContent && !this.state.elm.showQualityContent && !this.state.elm.showSubtitleContent){
                    this.setVideoControlTimeout();
                }
            })
        }
    }

    playNextMovie(id,isActive,isChangeSource = true){
        this.setState({busy:true});
        this.setPlayerInfoState({
            nextIndexMovie:_.findIndex(this.state.moviePlot,{id:id})
        },()=>{
            logger('play next movie active',isActive);
            if(isActive){
                let lastIndexMovie = this.state.playerInfo.currentIndexMovie;
                let currentIndexMovie = this.state.playerInfo.nextIndexMovie;
                let nextIndexMovie = this.state.moviePlot[currentIndexMovie].default_option!==undefined?_.findIndex(this.state.moviePlot, { "id": this.state.moviePlot[currentIndexMovie].default_option}):0;
                this.setPlayerInfoState({
                    lastIndexMovie:lastIndexMovie,
                    currentIndexMovie:currentIndexMovie,
                    nextIndexMovie:nextIndexMovie,
                    videoType:'movie'
                },()=>{
                    playerHelper.sendWatchTimer(this,(data)=>{
                        tracking.bulkWatchTime(data,(newData)=>{
                            playerHelper.putDataTrackWatchTimeFallback(newData);
                        });
                    })
                    // playerHelper.resetWatchTimer(this);
                    this.setElmState({
                        showBridgeControl:false,
                        isPause:false
                    },()=>{
                        this.generateBridgeObject();
                        this.playMovie(isChangeSource);
                    })
                });
            }
        })
    }

    seeking(time){
        if(this.state.playerInfo.videoType==='movie'){
            const now = this.player.currentTime();
            const seek = now+time;
            this.clearVideoControlTimeOut();
            this.setElmState({
                splashButton:time<0?backwardSplash:forwardSplash
            },()=>{
                playerHelper.toggleSplash();
                this.player.currentTime(seek);
                this.setVideoControlTimeout();
                if(seek<=0){
                    this.lastCheckpoint(false);
                }
            })
        }
    }

    handlerFullscreen(){
        this.setElmState({
            isFullscreen:!this.state.elm.isFullscreen,
            fullScreenButton:this.state.elm.isFullscreen?fullScreenSmall:eFullScreenSmall
        },()=>{
            const player = this.state.playerInfo.videoType==='movie'?this.player:this.playerBridge;
            playerHelper.adjustFullscreen(this.state.elm.isFullscreen,this.state.isMobile,player,
                this.state.playerInfo.qualityLevel,this.state.bd);
            if(this.state.playerInfo.videoType==='bridge'){
                logger('ANEH:','kenapa setiap state di bridge dan fullscreen, pas di exit fullscreen malah nge play player clip. kan bangsat','warn');
                this.player.volume(0);
                setTimeout(()=>{this.player.pause();this.player.volume(this.state.playerInfo.volume);},2000);
            }
        })
    }
    handlerShare(){
        this.setElmState({
            showShareControl:!this.state.elm.showShareControl
        },()=>{
            if(!this.state.elm.showEndPoster){
                if(this.state.elm.showShareControl){
                    this.pauseMovie();
                }
            }
        });
    }
    handlerSettingControl(){
        this.setElmState({
            showSettingContent:!this.state.elm.showSettingContent
        },()=>{
            if(this.state.elm.showSettingContent){
                clearTimeout(this.videoControlTimeoutObj);
            }else{
                if(!this.state.elm.isPause){
                    this.setVideoControlTimeout();
                }
            }
            this.setElmState({
                showSubtitleContent:false,
                showQualityContent:false
            })
        });
    }
    handlerEpisodeList(){
        this.setElmState({
            showEpisodeList:!this.state.elm.showEpisodeList
        })
    }
    //End Handler Event

    //action
    generateBridgeObject(){
        logger('Set Bridge Option ','');
        this.setBridgeObjectState({
            bridgeQuestion:this.state.moviePlot[this.state.playerInfo.currentIndexMovie].title,
            bridgeOption:this.state.moviePlot[this.state.playerInfo.currentIndexMovie].option
        },()=>{
            const listOptBridge = document.querySelectorAll('.bridge-option-item');
            listOptBridge.forEach((item,index)=>{
                item.style.display='block';
            });
        });
    }
    setVideoControlTimeout(){
        this.clearVideoControlTimeOut();
        let _this = this;
        this.videoControlTimeoutObj = setTimeout(function () {
            if(_this.state.elm.showVideoControl && _this.state.playerInfo.videoType==='movie'){
                playerHelper.toggleVideoControl(false);
                playerHelper.toggleSettingContainer(false);
                _this.setElmState({
                    showVolumeBox:false,
                    isOpenVideoControl:false,
                    isOpenSettingControl:false
                })
            }
        },idleTimeVideoControl*1000);
    }

    clearVideoControlTimeOut(){
        if(this.videoControlTimeoutObj!==null){
            clearTimeout(this.videoControlTimeoutObj);
        }
    }

    setPausePosterTimeout(){
        this.clearPausePosterTimeout();
        let _this = this;
        this.pausePosterTimeout = setTimeout(function () {
            if(_this.state.elm.isPause){
                _this.setElmState({
                    showPausePoster:true
                })
            }
        },idleTimePause*1000);
    }

    clearPausePosterTimeout(){
        if(this.pausePosterTimeout!==null){
            clearTimeout(this.pausePosterTimeout);
        }
    }

    changeVolumeBar(value){
        playerHelper.addStyleVolumeBar(value,this.state.isMobile,this.state.bd);
        document.getElementById('volume-bar').value = value;
        value = parseFloat(value);
        if(value <= 0){
            this.setPlayerInfoState({
                muted:true,
            },()=>{
                this.setElmState({
                    volumeButton:volumeMuteSmall
                },()=>this.player.muted(true))
            })
        }else{
            this.setPlayerInfoState({
                muted:false,
                volume:value
            },()=>{
                if(this.player.muted()){
                    this.player.muted(false);
                }
                if(value>0.5){
                    this.setElmState({
                        volumeButton:volumeMaxSmall
                    })
                }else{
                    this.setElmState({
                        volumeButton:volumeHalfSmall
                    })
                }
                this.player.volume(this.state.playerInfo.volume);
            })
        }
    }
    changeQualityLevels(index,currentTime=null){
        const moviePlayed = this.state.moviePlot[this.state.playerInfo.currentIndexMovie];
        let actualQualityLevel = index;
        if(moviePlayed.url_resource[actualQualityLevel]===undefined){
            actualQualityLevel = 'auto';
        }
        this.setPlayerInfoState({
            qualityLevel:actualQualityLevel,
            qualityLevelLabel:mapTextQuality(index,this.state.lang.setting),
            currentTime:currentTime===null?this.player.currentTime():currentTime,
        },()=>{
            this.playerBridge.src({
                src: moviePlayed.bridge_url_resource[actualQualityLevel],
                type: 'application/x-mpegURL'
            });
            playerHelper.setQualityLevel(this.player.qualityLevels(),actualQualityLevel);
            if(!this.state.elm.isPause){
                this.setVideoControlTimeout();
                playerHelper.showLoading();
            }
            this.hideAllSettingControl();
        })
    }

    changeSubtitle(langId){
        if(!this.state.busy && langId!=='off'){
            playerHelper.reAssignTrackElement();
            let indexSubs = _.findIndex(this.state.playerInfo.subtitleList,{lang:langId});
            if(indexSubs===-1){
                return this.changeSubtitle('off');
            }
            this.setState({busy: true})
            playerHelper.fetchSubtitle(langId,this.state.playerInfo.subtitleList[indexSubs].file,this.player,
                this.state.playerInfo.currentIndexMovie,(langId)=>{
                    this.setState({
                        busy:false,
                        bridgeDefaultLang:langId
                    },()=>{
                        this.setPlayerInfoState({
                            subtitle:langId,
                            subtitleLabel:mapTextSubtitle(langId,this.state.lang.setting)
                        },()=>this.hideAllSettingControl())
                    });
                });
        }else{
            this.setState({
                bridgeDefaultLang:this.state.primary_lang!==null?this.state.primary_lang:this.state.defaultLang
            },()=>{
                this.setPlayerInfoState({
                    subtitle:'off',
                    subtitleLabel:mapTextSubtitle('off',this.state.lang.setting)
                },()=>{
                    playerHelper.clearTextTrack(this.player);
                    this.hideAllSettingControl();
                })
            });
        }
    }

    hideAllSettingControl(){
        this.setElmState({
            showSettingContent:false,
            showQualityContent:false,
            showSubtitleContent:false
        });
    }
    //action

    //Banner
    setBannerMedia(){
        const playedMovie = this.state.moviePlot[this.state.playerInfo.currentIndexMovie];
        let media = this.state.playerInfo.videoType==='movie'?playedMovie.metadata.media:(playedMovie.bridge_metadata!==null?playedMovie.bridge_metadata.media:null);
        if(media!==null && media.banner.horizontal!==null){
            let bannerInfo = {...this.state.bannerInfo};
            _.forEach(media, function(value, key) {
                bannerInfo[key] = value;
            });
            this.setState({
                bannerInfo
            },()=>{
                logger('Banner ada :',this.state.bannerInfo);
                this.resetBanner();
            });
        }else{
            this.setState({
                bannerInfo:defaultBannerState
            },()=>{
                logger('Banner ga ada :',this.state.bannerInfo);
                this.resetBanner();
            });
        }
    }

    toggleBanner(currTime = null){
        if(currTime>=this.state.bannerInfo.banner.horizontal_time.start && !this.state.elm.showBanner && !this.state.playerInfo.closedBanner && this.state.bannerInfo.banner.horizontal!==null){
            const playedMovie = this.state.moviePlot[this.state.playerInfo.currentIndexMovie];
            this.setElmState({showBanner:true},()=>{
                tracking.bannerImpression(this.state.bannerInfo.id,'hr',(this.state.playerInfo.videoType==='movie')?2:1,(this.state.playerInfo.videoType==='movie')?playedMovie.id:playedMovie.bridge_id);
            })
        }
    }
    hideBanner(){
        this.setPlayerInfoState({
            closedBanner:true
        },()=>this.setElmState({showBanner:false}))
    }
    resetBanner(){
        this.setPlayerInfoState({
            closedBanner:false
        },()=>this.setElmState({showBanner:false}))
    }

    render() {
        return (
            <div id="wrapper">
                {CONFIG.env!=='production'?<MetaTags>
                    <meta name="robots" content="noindex" />
                    <meta name="googlebot" content="noindex" />
                </MetaTags>:""}

                <div className="show-blank" style={{display:(this.state.showBlank)?'block':'none'}}></div>
                <div id={this.state.isMobile?"mobile-player":"web-player"}>
                    <InitPosterWeb
                        showInitialPoster={this.state.elm.showInitialPoster}
                        genre={this.state.genre}
                        title={this.state.title}
                        series_name={this.state.series_name}
                        duration={this.state.duration.long}
                        synopsis={this.state.synopsis}
                        initialPlay={() =>this.initialPlay()}
                        artwork={this.state.artwork.large}
                        thumbnail={this.state.thumbnail}
                        load={this.state.busy}
                        whiteLabel={this.state.whiteLabel}
                    />
                    <PausePoster
                        showPausePoster={this.state.elm.showPausePoster}
                        genre={this.state.genre}
                        title={this.state.title}
                        series_name={this.state.series_name}
                        duration={this.state.duration.long}
                        synopsis={this.state.synopsis}
                    />
                    <EndPoster
                        showEndPoster={this.state.elm.showEndPoster}
                        title={this.state.title}
                        is_series={this.state.is_series}
                        isLastEpisode={this.state.playerInfo.isLastEpisode}
                        showIphoneImage={this.state.elm.showIphoneImage}
                        showAndroidImage={this.state.elm.showAndroidImage}
                        checkPointClick={() =>this.lastCheckpoint()}
                        replayMovie={() =>this.replayMovie()}
                        nextEpisode={() =>this.playNextEpisode()}
                        langText={this.state.lang.end_poster}
                        MovieId={this.state.id}
                        whiteLabel={this.state.whiteLabel}
                        isMobile = {this.state.isMobile}

                    />
                    <div className="splash">
                        <img src={this.state.elm.splashButton} alt="splash" />
                    </div>
                    <div id="video-container" className="video-container" style={{
                        display:(this.state.elm.showVideoContainer)?'flex':'none',
                        cursor:'pointer'
                    }}
                         onMouseMove={this.handlerVideoJs} onClick={this.handlerPlayPause}
                    >
                        <video id="my-video" className="video-js"  playsInline >
                            <p className="vjs-no-js">
                                To view this video please enable JavaScript, and consider upgrading to a web browser
                                that
                                <a rel="noopener noreferrer" href="https://videojs.com/html5-video-support/" target="_blank">supports HTML5
                                    video</a>
                            </p>
                            <track kind='captions' src='' srcLang='' label='' id="track-subtitle"/>
                        </video>
                        <video id="my-videobridge" className="video-js"  playsInline >
                            <p className="vjs-no-js">
                                To view this video please enable JavaScript, and consider upgrading to a web browser
                                that
                                <a rel="noopener noreferrer" href="https://videojs.com/html5-video-support/"
                                   target="_blank">supports HTML5
                                    video</a>
                            </p>
                            <track kind='captions' src='' srcLang='' label='' id="track-subtitle"/>
                        </video>
                    </div>
                    <div className="setting-control" id="setting-control" style={{
                        display:(this.state.elm.showSettingContainer)?'block':'none'
                    }}>
                        {!this.state.whiteLabel?<img alt="tado-logo" src={tadologoWhite} className="tado-logo-left setting-content"/>:''}
                        <a href="#fullscreen" style={{
                            display:this.state.elm.showFullscreenButton && this.state.elm.fsUpper ? 'block':'none'
                        }} id="fullscreen-btn" onClick={this.handlerFullscreen}><img alt="fullscreen" src={this.state.elm.fullScreenButton} className="setting-content"/></a>
                        <a href="#share" id="share" onClick={this.handlerShare} style={{
                            display:(this.state.elm.showShareButton && this.state.shareable)?'block':'none'
                        }}><img alt="setting" src={shareButton} className="setting-content" /></a>
                        <a href="#elist" className="episode-list" onClick={this.handlerEpisodeList} style={{
                            display:(this.state.is_series && this.state.playerInfo.hasEnded)?'block':'none'
                        }}><img src={EpisodeListSmall} className="setting-content" alt="logo" /></a>
                    </div>
                    <SettingContent
                        showSettingContent={this.state.elm.showSettingContent}
                        showQualityContent={this.state.elm.showQualityContent}
                        showSubtitleContent={this.state.elm.showSubtitleContent}
                        qualityLevelList={this.state.playerInfo.qualityLevelList}
                        subtitleList={this.state.playerInfo.subtitleList}
                        qualityLevel={this.state.playerInfo.qualityLevel}
                        subtitle={this.state.playerInfo.subtitle}
                        subtitleLabel={this.state.playerInfo.subtitleLabel}
                        qualityLevelLabel={this.state.playerInfo.qualityLevelLabel}
                        lang={this.state.lang.setting}
                        changeSubtitle={(lang)=>{
                            this.changeSubtitle(lang);
                        }}
                        changeQualityLevels={(index)=>{
                            this.changeQualityLevels(index);
                        }}
                        hideSettingWithTimeout={()=>{
                            this.hideAllSettingControl();
                            this.setVideoControlTimeout();
                        }}
                        hideAllSettingControl={()=>this.hideAllSettingControl()}
                        handlerQualityLevelContainer={()=>{
                            this.clearVideoControlTimeOut();
                            this.setElmState({
                                showSettingContent:false,
                                showSubtitleContent:false,
                                showQualityContent:true
                            });
                        }}
                        handlerSubtitleContainer={()=>{
                            this.clearVideoControlTimeOut();
                            this.setElmState({
                                showSettingContent:false,
                                showSubtitleContent:true,
                                showQualityContent:false
                            });
                        }}
                        handlerBackToSetting={()=>{
                            this.clearVideoControlTimeOut();
                            this.setElmState({
                                showSettingContent:true,
                                showSubtitleContent:false,
                                showQualityContent:false
                            });
                        }}
                    />
                    <div className="bottom-control" id="bottom-control">
                        <div className="new-video-control" id="new-video-control" style={{
                            display:this.state.elm.showVideoControl?'flex':'none'
                        }}>
                            <div className={this.state.elm.isOpenVideoControl?'left':'left hide'}>
                                <button className="play" onClick={this.handlerPlayPause}>
                                    <img src={this.state.elm.playPauseButton} alt="logo" />
                                </button>
                                <button className="bw" onClick={()=>this.seeking(seekBackward)}>
                                    <img src={bwSmall} alt="logo" />
                                </button>
                                <button className="ff" onClick={()=>this.seeking(seekForward)}>
                                    <img src={ffSmall} alt="logo" />
                                </button>
                                <button className="vol" onMouseEnter={()=>this.handlerVolumeBox('in')} onMouseLeave={()=>this.handlerVolumeBox('out')} onClick={(event)=>this.toggleMuteVolume(event)}>
                                    <div className="volume-box" style={{
                                        display:(this.state.elm.showVolumeBox)?'block':'none'
                                    }}>
                                        <input className="volume-bar" id="volume-bar" type="range" min="0" max="1"
                                               step="0.1" onInput={this.handlerChangeVolume}/>
                                    </div>
                                    <img src={this.state.elm.volumeButton} alt="logo" />
                                </button>
                            </div>
                            <div className={this.state.elm.isOpenVideoControl?'center':'center hide'}>
                                <div className="title-center">
                                    {(this.state.series_name!==''?this.state.series_name+': ':'')+this.state.title}
                                </div>
                            </div>
                            <div className={this.state.elm.isOpenVideoControl?'right':'right hide'} >
                                <button className="fullscreen" onClick={this.handlerFullscreen} style={{
                                    display:this.state.elm.showFullscreenButton && this.state.elm.fsLower ? 'block':'none'
                                }} >
                                    <img src={this.state.elm.fullScreenButton} alt="logo" />
                                </button>
                                <button className="setting" onClick={this.handlerSettingControl} style={{
                                    display:(this.state.elm.showSettingButton)?'block':'none'
                                }}>
                                    <img src={settingSmall} alt="logo" />
                                </button>
                                <button className="episode-list" onClick={this.handlerEpisodeList} style={{
                                    display:(this.state.is_series)?'block':'none'
                                }}>
                                    <img src={EpisodeListSmall} alt="logo" />
                                </button>
                                <button className="next-episode" onClick={()=>{
                                    if(!this.state.playerInfo.isLastEpisode){
                                        this.playNextEpisode();
                                    }
                                }} style={{
                                    display:(this.state.is_series)?'block':'none'
                                }}>
                                    <img src={(this.state.playerInfo.isLastEpisode)?NextEpisodeDisSmall:NextEpisodeSmall} alt="logo" />
                                </button>

                            </div>
                        </div>
                        <BridgeControl
                            title={this.state.title}
                            series={this.state.series_name}
                            genre={this.state.genre}
                            bridgeLang={this.state.bridgeDefaultLang}
                            showBridgeControl={this.state.elm.showBridgeControl}
                            bridgeOption={this.state.bridgeObject.bridgeOption}
                            bridgeQuestion={this.state.bridgeObject.bridgeQuestion}
                            playNextMovie={(id,isActive)=>this.playNextMovie(id,isActive)}
                            langText={this.state.lang.bridge}
                            orientation={'landscape'}
                            trackLockedOption={()=>tracking.pxTrackDownloadVideoPlayer(this.state.title,this.state.series_name,this.state.genre,"locked_option")}
                            lockedOpt={this.state.playerInfo.lockedOpt}
                            allowDbCLick={this.state.bridge_allow_skip}
                        />
                        <LoadingVideo text={this.state.loadingText} />
                    </div>
                    <Share
                        showModal={this.state.elm.showShareControl}
                        handlerShare={()=>this.handlerShare()}
                        slug={this.state.slug}
                        langText = {this.state.lang}
                        isMobile = {this.state.isMobile}
                    />
                    <Banner
                        analyticStatus = {this.state.analytic}
                        showBanner={this.state.elm.showBanner}
                        type={this.state.playerInfo.videoType}
                        is_endplot={this.state.playerInfo.hasEnded}
                        media={this.state.bannerInfo}
                        closeBanner={(event,id,type,src)=>{
                            if(this.state.analytic){
                                const playedMovie = this.state.moviePlot[this.state.playerInfo.currentIndexMovie];
                                if(event==='click'){
                                    tracking.bannerClick(id,type,src,(this.state.playerInfo.videoType==='movie')?playedMovie.id:playedMovie.bridge_id);
                                }else{
                                    tracking.bannerClose(id,type,src,(this.state.playerInfo.videoType==='movie')?playedMovie.id:playedMovie.bridge_id);
                                }
                            }
                            this.hideBanner()
                        }}
                        isMobile = {this.state.isMobile}
                    />
                    <EpisodeList
                        showList={this.state.elm.showEpisodeList}
                        showingId={this.state.id}
                        episodeList={this.state.episode_list}
                        close={()=>{
                            this.handlerEpisodeList()
                        }}
                        playNextEpisode={(slug)=>{
                            this.playNextEpisode(slug);
                        }}
                        lang={this.state.lang.series}
                        isMobile = {this.state.isMobile}
                    />

                </div>
            </div>
        );
    }
}
export default WebPlayer;
