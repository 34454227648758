export const movieDetail = {
  "status": 1,
  "data": {
    "id": 205,
    "analytic": false,
    "shareable": false,
    "allow_fullscreen": true,
    "bridge_allow_skip": true,
    "is_potrait": true,
    "is_series": true,
    "series_name": "Dewasa Ala Dilla Ep. 1",
    "title": "Dewasa Ala Dilla - Episode 1",
    "genre": "Drama",
    "synopsis": "Setelah diajari menabung oleh ibunya sejak kecil, kini Dilla memiliki tabungan yang cukup banyak. Dilla pun bingung apa yang harus dia lakukan dengan uang tabungannya itu.",
    "primary_lang": "ID",
    "thumbnail": {
      "mobile": "https://vod.tadotv.com/thumbnail/mobile/large/1562234938210-x9nyz.jpg",
      "desktop": "https://vod.tadotv.com/thumbnail/desktop/large/1562235143752-HoDHG.jpg"
    },
    "artwork": {
      "small": "https://vod.tadotv.com/thumbnail/small/1562225509199-iCxPC.jpg",
      "large": "https://vod.tadotv.com/thumbnail/large/1562225509199-iCxPC.jpg"
    }
  }
}

export const moviePlot = {
  "status": 1,
  "data": {
    "id": 205,
    "analytic": false,
    "shareable": false,
    "allow_fullscreen": true,
    "bridge_allow_skip": true,
    "is_potrait": true,
    "is_series": false,
    "series_name": "Dewasa Ala Dilla Ep. 1",
    "title": "Dewasa Ala Dilla - Episode 1",
    "genre": "Drama",
    "synopsis": "Setelah diajari menabung oleh ibunya sejak kecil, kini Dilla memiliki tabungan yang cukup banyak. Dilla pun bingung apa yang harus dia lakukan dengan uang tabungannya itu.",
    "primary_lang": "ID",
    "url": "https://s3-ap-southeast-1.amazonaws.com/tado-hls/result/P9iJFh2qBE.mp4",
    "thumbnail": {
      "mobile": "https://vod.tadotv.com/thumbnail/mobile/large/1562234938210-x9nyz.jpg",
      "desktop": "https://vod.tadotv.com/thumbnail/desktop/large/1562235143752-HoDHG.jpg"
    },
    "artwork": {
      "small": "https://vod.tadotv.com/thumbnail/small/1562225509199-iCxPC.jpg",
      "large": "https://vod.tadotv.com/thumbnail/large/1562225509199-iCxPC.jpg"
    },
    "duration": {
      "short": "20 m",
      "long": "sekitar 20 menit",
      "time": "19:38"
    },
    "view": "PG-13",
    "episode_list": [],
    "watch_uid": "351fb757-91a4-3aed-bc3d-4a49da176318",
    "moviePlot": [
      {
        "id": 3016,
        "title": {
          "ID": "Mau celengan yang mana?"
        },
        "clip_title": "Intro",
        "subtitle": [],
        "url": "https://vod.tadotv.com/result/rs_1562261252331Lg4ZYnkl_1562261240384-vIolt/master_anon_rs_1562261252331Lg4ZYnkl_1562261240384-vIolt.m3u8",
        "url_resource": {
          "auto": "https://vod.tadotv.com/result/rs_1562261252331Lg4ZYnkl_1562261240384-vIolt/master_auto_rs_1562261252331Lg4ZYnkl_1562261240384-vIolt.m3u8",
          "anon": "https://vod.tadotv.com/result/rs_1562261252331Lg4ZYnkl_1562261240384-vIolt/master_anon_rs_1562261252331Lg4ZYnkl_1562261240384-vIolt.m3u8",
          "low": "https://vod.tadotv.com/result/rs_1562261252331Lg4ZYnkl_1562261240384-vIolt/master_low_rs_1562261252331Lg4ZYnkl_1562261240384-vIolt.m3u8",
          "basic": "https://vod.tadotv.com/result/rs_1562261252331Lg4ZYnkl_1562261240384-vIolt/master_basic_rs_1562261252331Lg4ZYnkl_1562261240384-vIolt.m3u8",
          "high": "https://vod.tadotv.com/result/rs_1562261252331Lg4ZYnkl_1562261240384-vIolt/master_high_rs_1562261252331Lg4ZYnkl_1562261240384-vIolt.m3u8",
          "ultra": "https://vod.tadotv.com/result/rs_1562261252331Lg4ZYnkl_1562261240384-vIolt/master_ultra_rs_1562261252331Lg4ZYnkl_1562261240384-vIolt.m3u8"
        },
        "bridge_id": 3017,
        "bridge_url": "https://vod.tadotv.com/result/rs_1562262713588Vf00jQca_1562262702774-Ub0GM/master_anon_rs_1562262713588Vf00jQca_1562262702774-Ub0GM.m3u8",
        "bridge_url_resource": {
          "auto": "https://vod.tadotv.com/result/rs_1562262713588Vf00jQca_1562262702774-Ub0GM/master_auto_rs_1562262713588Vf00jQca_1562262702774-Ub0GM.m3u8",
          "anon": "https://vod.tadotv.com/result/rs_1562262713588Vf00jQca_1562262702774-Ub0GM/master_anon_rs_1562262713588Vf00jQca_1562262702774-Ub0GM.m3u8",
          "low": "https://vod.tadotv.com/result/rs_1562262713588Vf00jQca_1562262702774-Ub0GM/master_low_rs_1562262713588Vf00jQca_1562262702774-Ub0GM.m3u8",
          "basic": "https://vod.tadotv.com/result/rs_1562262713588Vf00jQca_1562262702774-Ub0GM/master_basic_rs_1562262713588Vf00jQca_1562262702774-Ub0GM.m3u8",
          "high": "https://vod.tadotv.com/result/rs_1562262713588Vf00jQca_1562262702774-Ub0GM/master_high_rs_1562262713588Vf00jQca_1562262702774-Ub0GM.m3u8",
          "ultra": "https://vod.tadotv.com/result/rs_1562262713588Vf00jQca_1562262702774-Ub0GM/master_ultra_rs_1562262713588Vf00jQca_1562262702774-Ub0GM.m3u8"
        },
        "bridge_subtitle": [],
        "bridge_metadata": {
          "media": {
            "id": 3,
            "link": "https://www.pegadaian.co.id/produk/tabungan-emas",
            "button": {
              "text": "Nabung Sekarang",
              "text_color": "#ffffff",
              "color": "#004d43"
            },
            "banner": {
              "horizontal": "https://vod.tadotv.com/banner/horizontal/1562751350078-ep3ps.jpg",
              "vertical_long": null,
              "vertical": null,
              "horizontal_time": {
                "start": 1,
                "end": 0
              }
            }
          }
        },
        "primary_lang": "ID",
        "duration": 41,
        "default_option": 3018,
        "option": [
          {
            "id": 3018,
            "title": {
              "ID": "Celengan berwarna merah"
            },
            "duration": 164,
            "locked": 0
          },
          {
            "id": 3019,
            "title": {
              "ID": "Celengan berwarna hijau"
            },
            "duration": 171,
            "locked": 0
          }
        ],
        "metadata": {
          "is_endplot": false,
          "media": null
        }
      },
      {
        "id": 3018,
        "title": {
          "ID": "Dengarkan Reno dulu?"
        },
        "clip_title": "Celengan berwarna merah",
        "subtitle": [],
        "url": "https://vod.tadotv.com/result/rs_1562261252305gIQ0DrMZ_1562261235383-4mHpl/master_anon_rs_1562261252305gIQ0DrMZ_1562261235383-4mHpl.m3u8",
        "url_resource": {
          "auto": "https://vod.tadotv.com/result/rs_1562261252305gIQ0DrMZ_1562261235383-4mHpl/master_auto_rs_1562261252305gIQ0DrMZ_1562261235383-4mHpl.m3u8",
          "anon": "https://vod.tadotv.com/result/rs_1562261252305gIQ0DrMZ_1562261235383-4mHpl/master_anon_rs_1562261252305gIQ0DrMZ_1562261235383-4mHpl.m3u8",
          "low": "https://vod.tadotv.com/result/rs_1562261252305gIQ0DrMZ_1562261235383-4mHpl/master_low_rs_1562261252305gIQ0DrMZ_1562261235383-4mHpl.m3u8",
          "basic": "https://vod.tadotv.com/result/rs_1562261252305gIQ0DrMZ_1562261235383-4mHpl/master_basic_rs_1562261252305gIQ0DrMZ_1562261235383-4mHpl.m3u8",
          "high": "https://vod.tadotv.com/result/rs_1562261252305gIQ0DrMZ_1562261235383-4mHpl/master_high_rs_1562261252305gIQ0DrMZ_1562261235383-4mHpl.m3u8",
          "ultra": "https://vod.tadotv.com/result/rs_1562261252305gIQ0DrMZ_1562261235383-4mHpl/master_ultra_rs_1562261252305gIQ0DrMZ_1562261235383-4mHpl.m3u8"
        },
        "bridge_id": 3020,
        "bridge_url": "https://vod.tadotv.com/result/rs_1562262752538yI0Ktl6Y_1562262741084-k5RJi/master_anon_rs_1562262752538yI0Ktl6Y_1562262741084-k5RJi.m3u8",
        "bridge_url_resource": {
          "auto": "https://vod.tadotv.com/result/rs_1562262752538yI0Ktl6Y_1562262741084-k5RJi/master_auto_rs_1562262752538yI0Ktl6Y_1562262741084-k5RJi.m3u8",
          "anon": "https://vod.tadotv.com/result/rs_1562262752538yI0Ktl6Y_1562262741084-k5RJi/master_anon_rs_1562262752538yI0Ktl6Y_1562262741084-k5RJi.m3u8",
          "low": "https://vod.tadotv.com/result/rs_1562262752538yI0Ktl6Y_1562262741084-k5RJi/master_low_rs_1562262752538yI0Ktl6Y_1562262741084-k5RJi.m3u8",
          "basic": "https://vod.tadotv.com/result/rs_1562262752538yI0Ktl6Y_1562262741084-k5RJi/master_basic_rs_1562262752538yI0Ktl6Y_1562262741084-k5RJi.m3u8",
          "high": "https://vod.tadotv.com/result/rs_1562262752538yI0Ktl6Y_1562262741084-k5RJi/master_high_rs_1562262752538yI0Ktl6Y_1562262741084-k5RJi.m3u8",
          "ultra": "https://vod.tadotv.com/result/rs_1562262752538yI0Ktl6Y_1562262741084-k5RJi/master_ultra_rs_1562262752538yI0Ktl6Y_1562262741084-k5RJi.m3u8"
        },
        "bridge_subtitle": [],
        "bridge_metadata": {
          "media": {
            "id": 3,
            "link": "https://www.pegadaian.co.id/produk/tabungan-emas",
            "button": {
              "text": "Nabung Sekarang",
              "text_color": "#ffffff",
              "color": "#004d43"
            },
            "banner": {
              "horizontal": "https://vod.tadotv.com/banner/horizontal/1562751350078-ep3ps.jpg",
              "vertical_long": null,
              "vertical": null,
              "horizontal_time": {
                "start": 1,
                "end": 0
              }
            }
          }
        },
        "primary_lang": "ID",
        "duration": 164,
        "default_option": 3022,
        "option": [
          {
            "id": 3022,
            "title": {
              "ID": "Ngobrol dengan Reno"
            },
            "duration": 378,
            "locked": 0
          },
          {
            "id": 3023,
            "title": {
              "ID": "Tinggalkan Reno"
            },
            "duration": 341,
            "locked": 0
          }
        ],
        "metadata": {
          "is_endplot": false,
          "media": null
        }
      },
      {
        "id": 3019,
        "title": {
          "ID": "Dengarkan Reno dulu?"
        },
        "clip_title": "Celengan berwarna hijau",
        "subtitle": [],
        "url": "https://vod.tadotv.com/result/rs_1575441917995KoA4QNfC_1562261239420-ePBUG/master_anon_rs_1575441917995KoA4QNfC_1562261239420-ePBUG.m3u8",
        "url_resource": {
          "auto": "https://vod.tadotv.com/result/rs_1575441917995KoA4QNfC_1562261239420-ePBUG/master_auto_rs_1575441917995KoA4QNfC_1562261239420-ePBUG.m3u8",
          "anon": "https://vod.tadotv.com/result/rs_1575441917995KoA4QNfC_1562261239420-ePBUG/master_anon_rs_1575441917995KoA4QNfC_1562261239420-ePBUG.m3u8",
          "low": "https://vod.tadotv.com/result/rs_1575441917995KoA4QNfC_1562261239420-ePBUG/master_low_rs_1575441917995KoA4QNfC_1562261239420-ePBUG.m3u8",
          "basic": "https://vod.tadotv.com/result/rs_1575441917995KoA4QNfC_1562261239420-ePBUG/master_basic_rs_1575441917995KoA4QNfC_1562261239420-ePBUG.m3u8",
          "high": "https://vod.tadotv.com/result/rs_1575441917995KoA4QNfC_1562261239420-ePBUG/master_high_rs_1575441917995KoA4QNfC_1562261239420-ePBUG.m3u8",
          "ultra": "https://vod.tadotv.com/result/rs_1575441917995KoA4QNfC_1562261239420-ePBUG/master_ultra_rs_1575441917995KoA4QNfC_1562261239420-ePBUG.m3u8"
        },
        "bridge_id": 3021,
        "bridge_url": "https://vod.tadotv.com/result/rs_1562262752546Di1cdCbP_1562262741129-32mfm/master_anon_rs_1562262752546Di1cdCbP_1562262741129-32mfm.m3u8",
        "bridge_url_resource": {
          "auto": "https://vod.tadotv.com/result/rs_1562262752546Di1cdCbP_1562262741129-32mfm/master_auto_rs_1562262752546Di1cdCbP_1562262741129-32mfm.m3u8",
          "anon": "https://vod.tadotv.com/result/rs_1562262752546Di1cdCbP_1562262741129-32mfm/master_anon_rs_1562262752546Di1cdCbP_1562262741129-32mfm.m3u8",
          "low": "https://vod.tadotv.com/result/rs_1562262752546Di1cdCbP_1562262741129-32mfm/master_low_rs_1562262752546Di1cdCbP_1562262741129-32mfm.m3u8",
          "basic": "https://vod.tadotv.com/result/rs_1562262752546Di1cdCbP_1562262741129-32mfm/master_basic_rs_1562262752546Di1cdCbP_1562262741129-32mfm.m3u8",
          "high": "https://vod.tadotv.com/result/rs_1562262752546Di1cdCbP_1562262741129-32mfm/master_high_rs_1562262752546Di1cdCbP_1562262741129-32mfm.m3u8",
          "ultra": "https://vod.tadotv.com/result/rs_1562262752546Di1cdCbP_1562262741129-32mfm/master_ultra_rs_1562262752546Di1cdCbP_1562262741129-32mfm.m3u8"
        },
        "bridge_subtitle": [],
        "bridge_metadata": {
          "media": {
            "id": 3,
            "link": "https://www.pegadaian.co.id/produk/tabungan-emas",
            "button": {
              "text": "Nabung Sekarang",
              "text_color": "#ffffff",
              "color": "#004d43"
            },
            "banner": {
              "horizontal": "https://vod.tadotv.com/banner/horizontal/1562751350078-ep3ps.jpg",
              "vertical_long": null,
              "vertical": null,
              "horizontal_time": {
                "start": 1,
                "end": 0
              }
            }
          }
        },
        "primary_lang": "ID",
        "duration": 171,
        "default_option": 3066,
        "option": [
          {
            "id": 3066,
            "title": {
              "ID": "Ngobrol dengan Reno"
            },
            "duration": 378,
            "locked": 0
          },
          {
            "id": 3067,
            "title": {
              "ID": "Tinggalkan Reno"
            },
            "duration": 341,
            "locked": 0
          }
        ],
        "metadata": {
          "is_endplot": false,
          "media": null
        }
      },
      {
        "id": 3022,
        "title": {
          "ID": "Investasi apa yang akan dicoba?"
        },
        "clip_title": "Ngobrol dengan Reno",
        "subtitle": [],
        "url": "https://vod.tadotv.com/result/rs_1562261560618jwPNsuqf_1562261546627-41h8z/master_anon_rs_1562261560618jwPNsuqf_1562261546627-41h8z.m3u8",
        "url_resource": {
          "auto": "https://vod.tadotv.com/result/rs_1562261560618jwPNsuqf_1562261546627-41h8z/master_auto_rs_1562261560618jwPNsuqf_1562261546627-41h8z.m3u8",
          "anon": "https://vod.tadotv.com/result/rs_1562261560618jwPNsuqf_1562261546627-41h8z/master_anon_rs_1562261560618jwPNsuqf_1562261546627-41h8z.m3u8",
          "low": "https://vod.tadotv.com/result/rs_1562261560618jwPNsuqf_1562261546627-41h8z/master_low_rs_1562261560618jwPNsuqf_1562261546627-41h8z.m3u8",
          "basic": "https://vod.tadotv.com/result/rs_1562261560618jwPNsuqf_1562261546627-41h8z/master_basic_rs_1562261560618jwPNsuqf_1562261546627-41h8z.m3u8",
          "high": "https://vod.tadotv.com/result/rs_1562261560618jwPNsuqf_1562261546627-41h8z/master_high_rs_1562261560618jwPNsuqf_1562261546627-41h8z.m3u8",
          "ultra": "https://vod.tadotv.com/result/rs_1562261560618jwPNsuqf_1562261546627-41h8z/master_ultra_rs_1562261560618jwPNsuqf_1562261546627-41h8z.m3u8"
        },
        "bridge_id": 3024,
        "bridge_url": "https://vod.tadotv.com/result/rs_1562262805865Hri8COxE_1562262794549-MYlf1/master_anon_rs_1562262805865Hri8COxE_1562262794549-MYlf1.m3u8",
        "bridge_url_resource": {
          "auto": "https://vod.tadotv.com/result/rs_1562262805865Hri8COxE_1562262794549-MYlf1/master_auto_rs_1562262805865Hri8COxE_1562262794549-MYlf1.m3u8",
          "anon": "https://vod.tadotv.com/result/rs_1562262805865Hri8COxE_1562262794549-MYlf1/master_anon_rs_1562262805865Hri8COxE_1562262794549-MYlf1.m3u8",
          "low": "https://vod.tadotv.com/result/rs_1562262805865Hri8COxE_1562262794549-MYlf1/master_low_rs_1562262805865Hri8COxE_1562262794549-MYlf1.m3u8",
          "basic": "https://vod.tadotv.com/result/rs_1562262805865Hri8COxE_1562262794549-MYlf1/master_basic_rs_1562262805865Hri8COxE_1562262794549-MYlf1.m3u8",
          "high": "https://vod.tadotv.com/result/rs_1562262805865Hri8COxE_1562262794549-MYlf1/master_high_rs_1562262805865Hri8COxE_1562262794549-MYlf1.m3u8",
          "ultra": "https://vod.tadotv.com/result/rs_1562262805865Hri8COxE_1562262794549-MYlf1/master_ultra_rs_1562262805865Hri8COxE_1562262794549-MYlf1.m3u8"
        },
        "bridge_subtitle": [],
        "bridge_metadata": {
          "media": {
            "id": 3,
            "link": "https://www.pegadaian.co.id/produk/tabungan-emas",
            "button": {
              "text": "Nabung Sekarang",
              "text_color": "#ffffff",
              "color": "#004d43"
            },
            "banner": {
              "horizontal": "https://vod.tadotv.com/banner/horizontal/1562751350078-ep3ps.jpg",
              "vertical_long": null,
              "vertical": null,
              "horizontal_time": {
                "start": 1,
                "end": 0
              }
            }
          }
        },
        "primary_lang": "ID",
        "duration": 378,
        "default_option": 3026,
        "option": [
          {
            "id": 3026,
            "title": {
              "ID": "Investasi properti"
            },
            "duration": 58,
            "locked": 0
          },
          {
            "id": 3027,
            "title": {
              "ID": "Investasi ke teman"
            },
            "duration": 122,
            "locked": 0
          }
        ],
        "metadata": {
          "is_endplot": false,
          "media": null
        }
      },
      {
        "id": 3023,
        "title": {
          "ID": "Investasi apa yang akan dicoba?"
        },
        "clip_title": "Tinggalkan Reno",
        "subtitle": [],
        "url": "https://vod.tadotv.com/result/rs_1575441917991W4i2FyPh_1562261548116-jljAo/master_anon_rs_1575441917991W4i2FyPh_1562261548116-jljAo.m3u8",
        "url_resource": {
          "auto": "https://vod.tadotv.com/result/rs_1575441917991W4i2FyPh_1562261548116-jljAo/master_auto_rs_1575441917991W4i2FyPh_1562261548116-jljAo.m3u8",
          "anon": "https://vod.tadotv.com/result/rs_1575441917991W4i2FyPh_1562261548116-jljAo/master_anon_rs_1575441917991W4i2FyPh_1562261548116-jljAo.m3u8",
          "low": "https://vod.tadotv.com/result/rs_1575441917991W4i2FyPh_1562261548116-jljAo/master_low_rs_1575441917991W4i2FyPh_1562261548116-jljAo.m3u8",
          "basic": "https://vod.tadotv.com/result/rs_1575441917991W4i2FyPh_1562261548116-jljAo/master_basic_rs_1575441917991W4i2FyPh_1562261548116-jljAo.m3u8",
          "high": "https://vod.tadotv.com/result/rs_1575441917991W4i2FyPh_1562261548116-jljAo/master_high_rs_1575441917991W4i2FyPh_1562261548116-jljAo.m3u8",
          "ultra": "https://vod.tadotv.com/result/rs_1575441917991W4i2FyPh_1562261548116-jljAo/master_ultra_rs_1575441917991W4i2FyPh_1562261548116-jljAo.m3u8"
        },
        "bridge_id": 3025,
        "bridge_url": "https://vod.tadotv.com/result/rs_1562262805879GTmpd6pc_1562262794590-uNSNw/master_anon_rs_1562262805879GTmpd6pc_1562262794590-uNSNw.m3u8",
        "bridge_url_resource": {
          "auto": "https://vod.tadotv.com/result/rs_1562262805879GTmpd6pc_1562262794590-uNSNw/master_auto_rs_1562262805879GTmpd6pc_1562262794590-uNSNw.m3u8",
          "anon": "https://vod.tadotv.com/result/rs_1562262805879GTmpd6pc_1562262794590-uNSNw/master_anon_rs_1562262805879GTmpd6pc_1562262794590-uNSNw.m3u8",
          "low": "https://vod.tadotv.com/result/rs_1562262805879GTmpd6pc_1562262794590-uNSNw/master_low_rs_1562262805879GTmpd6pc_1562262794590-uNSNw.m3u8",
          "basic": "https://vod.tadotv.com/result/rs_1562262805879GTmpd6pc_1562262794590-uNSNw/master_basic_rs_1562262805879GTmpd6pc_1562262794590-uNSNw.m3u8",
          "high": "https://vod.tadotv.com/result/rs_1562262805879GTmpd6pc_1562262794590-uNSNw/master_high_rs_1562262805879GTmpd6pc_1562262794590-uNSNw.m3u8",
          "ultra": "https://vod.tadotv.com/result/rs_1562262805879GTmpd6pc_1562262794590-uNSNw/master_ultra_rs_1562262805879GTmpd6pc_1562262794590-uNSNw.m3u8"
        },
        "bridge_subtitle": [],
        "bridge_metadata": {
          "media": {
            "id": 3,
            "link": "https://www.pegadaian.co.id/produk/tabungan-emas",
            "button": {
              "text": "Nabung Sekarang",
              "text_color": "#ffffff",
              "color": "#004d43"
            },
            "banner": {
              "horizontal": "https://vod.tadotv.com/banner/horizontal/1562751350078-ep3ps.jpg",
              "vertical_long": null,
              "vertical": null,
              "horizontal_time": {
                "start": 1,
                "end": 0
              }
            }
          }
        },
        "primary_lang": "ID",
        "duration": 341,
        "default_option": 3028,
        "option": [
          {
            "id": 3028,
            "title": {
              "ID": "Investasi properti"
            },
            "duration": 58,
            "locked": 0
          },
          {
            "id": 3029,
            "title": {
              "ID": "Investasi ke teman"
            },
            "duration": 122,
            "locked": 0
          }
        ],
        "metadata": {
          "is_endplot": false,
          "media": null
        }
      },
      {
        "id": 3026,
        "title": {
          "ID": "Apa lagi sih si Reno ini?"
        },
        "clip_title": "Investasi properti",
        "subtitle": [],
        "url": "https://vod.tadotv.com/result/rs_1562262055509zzdwPFQ4_1562262040192-HIhyl/master_anon_rs_1562262055509zzdwPFQ4_1562262040192-HIhyl.m3u8",
        "url_resource": {
          "auto": "https://vod.tadotv.com/result/rs_1562262055509zzdwPFQ4_1562262040192-HIhyl/master_auto_rs_1562262055509zzdwPFQ4_1562262040192-HIhyl.m3u8",
          "anon": "https://vod.tadotv.com/result/rs_1562262055509zzdwPFQ4_1562262040192-HIhyl/master_anon_rs_1562262055509zzdwPFQ4_1562262040192-HIhyl.m3u8",
          "low": "https://vod.tadotv.com/result/rs_1562262055509zzdwPFQ4_1562262040192-HIhyl/master_low_rs_1562262055509zzdwPFQ4_1562262040192-HIhyl.m3u8",
          "basic": "https://vod.tadotv.com/result/rs_1562262055509zzdwPFQ4_1562262040192-HIhyl/master_basic_rs_1562262055509zzdwPFQ4_1562262040192-HIhyl.m3u8",
          "high": "https://vod.tadotv.com/result/rs_1562262055509zzdwPFQ4_1562262040192-HIhyl/master_high_rs_1562262055509zzdwPFQ4_1562262040192-HIhyl.m3u8",
          "ultra": "https://vod.tadotv.com/result/rs_1562262055509zzdwPFQ4_1562262040192-HIhyl/master_ultra_rs_1562262055509zzdwPFQ4_1562262040192-HIhyl.m3u8"
        },
        "bridge_id": 3030,
        "bridge_url": "https://vod.tadotv.com/result/rs_1562262865604iF1ow6ez_1562262854375-zp4kw/master_anon_rs_1562262865604iF1ow6ez_1562262854375-zp4kw.m3u8",
        "bridge_url_resource": {
          "auto": "https://vod.tadotv.com/result/rs_1562262865604iF1ow6ez_1562262854375-zp4kw/master_auto_rs_1562262865604iF1ow6ez_1562262854375-zp4kw.m3u8",
          "anon": "https://vod.tadotv.com/result/rs_1562262865604iF1ow6ez_1562262854375-zp4kw/master_anon_rs_1562262865604iF1ow6ez_1562262854375-zp4kw.m3u8",
          "low": "https://vod.tadotv.com/result/rs_1562262865604iF1ow6ez_1562262854375-zp4kw/master_low_rs_1562262865604iF1ow6ez_1562262854375-zp4kw.m3u8",
          "basic": "https://vod.tadotv.com/result/rs_1562262865604iF1ow6ez_1562262854375-zp4kw/master_basic_rs_1562262865604iF1ow6ez_1562262854375-zp4kw.m3u8",
          "high": "https://vod.tadotv.com/result/rs_1562262865604iF1ow6ez_1562262854375-zp4kw/master_high_rs_1562262865604iF1ow6ez_1562262854375-zp4kw.m3u8",
          "ultra": "https://vod.tadotv.com/result/rs_1562262865604iF1ow6ez_1562262854375-zp4kw/master_ultra_rs_1562262865604iF1ow6ez_1562262854375-zp4kw.m3u8"
        },
        "bridge_subtitle": [],
        "bridge_metadata": {
          "media": {
            "id": 3,
            "link": "https://www.pegadaian.co.id/produk/tabungan-emas",
            "button": {
              "text": "Nabung Sekarang",
              "text_color": "#ffffff",
              "color": "#004d43"
            },
            "banner": {
              "horizontal": "https://vod.tadotv.com/banner/horizontal/1562751350078-ep3ps.jpg",
              "vertical_long": null,
              "vertical": null,
              "horizontal_time": {
                "start": 1,
                "end": 0
              }
            }
          }
        },
        "primary_lang": "ID",
        "duration": 58,
        "default_option": 3031,
        "option": [
          {
            "id": 3031,
            "title": {
              "ID": "Tunggu Reno"
            },
            "duration": 198,
            "locked": 0
          },
          {
            "id": 3032,
            "title": {
              "ID": "Tinggalkan Reno"
            },
            "duration": 191,
            "locked": 0
          }
        ],
        "metadata": {
          "is_endplot": false,
          "media": null
        }
      },
      {
        "id": 3027,
        "title": {
          "ID": "Apa lagi sih si Reno ini?"
        },
        "clip_title": "Investasi ke teman",
        "subtitle": [],
        "url": "https://vod.tadotv.com/result/rs_1562262055513kAnybH8p_1562262041199-CVQZp/master_anon_rs_1562262055513kAnybH8p_1562262041199-CVQZp.m3u8",
        "url_resource": {
          "auto": "https://vod.tadotv.com/result/rs_1562262055513kAnybH8p_1562262041199-CVQZp/master_auto_rs_1562262055513kAnybH8p_1562262041199-CVQZp.m3u8",
          "anon": "https://vod.tadotv.com/result/rs_1562262055513kAnybH8p_1562262041199-CVQZp/master_anon_rs_1562262055513kAnybH8p_1562262041199-CVQZp.m3u8",
          "low": "https://vod.tadotv.com/result/rs_1562262055513kAnybH8p_1562262041199-CVQZp/master_low_rs_1562262055513kAnybH8p_1562262041199-CVQZp.m3u8",
          "basic": "https://vod.tadotv.com/result/rs_1562262055513kAnybH8p_1562262041199-CVQZp/master_basic_rs_1562262055513kAnybH8p_1562262041199-CVQZp.m3u8",
          "high": "https://vod.tadotv.com/result/rs_1562262055513kAnybH8p_1562262041199-CVQZp/master_high_rs_1562262055513kAnybH8p_1562262041199-CVQZp.m3u8",
          "ultra": "https://vod.tadotv.com/result/rs_1562262055513kAnybH8p_1562262041199-CVQZp/master_ultra_rs_1562262055513kAnybH8p_1562262041199-CVQZp.m3u8"
        },
        "bridge_id": 3039,
        "bridge_url": "https://vod.tadotv.com/result/rs_1562262865640rpZoiUjx_1562262854414-Dddz9/master_anon_rs_1562262865640rpZoiUjx_1562262854414-Dddz9.m3u8",
        "bridge_url_resource": {
          "auto": "https://vod.tadotv.com/result/rs_1562262865640rpZoiUjx_1562262854414-Dddz9/master_auto_rs_1562262865640rpZoiUjx_1562262854414-Dddz9.m3u8",
          "anon": "https://vod.tadotv.com/result/rs_1562262865640rpZoiUjx_1562262854414-Dddz9/master_anon_rs_1562262865640rpZoiUjx_1562262854414-Dddz9.m3u8",
          "low": "https://vod.tadotv.com/result/rs_1562262865640rpZoiUjx_1562262854414-Dddz9/master_low_rs_1562262865640rpZoiUjx_1562262854414-Dddz9.m3u8",
          "basic": "https://vod.tadotv.com/result/rs_1562262865640rpZoiUjx_1562262854414-Dddz9/master_basic_rs_1562262865640rpZoiUjx_1562262854414-Dddz9.m3u8",
          "high": "https://vod.tadotv.com/result/rs_1562262865640rpZoiUjx_1562262854414-Dddz9/master_high_rs_1562262865640rpZoiUjx_1562262854414-Dddz9.m3u8",
          "ultra": "https://vod.tadotv.com/result/rs_1562262865640rpZoiUjx_1562262854414-Dddz9/master_ultra_rs_1562262865640rpZoiUjx_1562262854414-Dddz9.m3u8"
        },
        "bridge_subtitle": [],
        "bridge_metadata": {
          "media": {
            "id": 3,
            "link": "https://www.pegadaian.co.id/produk/tabungan-emas",
            "button": {
              "text": "Nabung Sekarang",
              "text_color": "#ffffff",
              "color": "#004d43"
            },
            "banner": {
              "horizontal": "https://vod.tadotv.com/banner/horizontal/1562751350078-ep3ps.jpg",
              "vertical_long": null,
              "vertical": null,
              "horizontal_time": {
                "start": 1,
                "end": 0
              }
            }
          }
        },
        "primary_lang": "ID",
        "duration": 122,
        "default_option": 3040,
        "option": [
          {
            "id": 3040,
            "title": {
              "ID": "Tunggu Reno"
            },
            "duration": 198,
            "locked": 0
          },
          {
            "id": 3041,
            "title": {
              "ID": "Tinggalkan Reno"
            },
            "duration": 191,
            "locked": 0
          }
        ],
        "metadata": {
          "is_endplot": false,
          "media": null
        }
      },
      {
        "id": 3028,
        "title": {
          "ID": "Apa lagi sih si Reno ini?"
        },
        "clip_title": "Investasi properti",
        "subtitle": [],
        "url": "https://vod.tadotv.com/result/rs_1562262055509zzdwPFQ4_1562262040192-HIhyl/master_anon_rs_1562262055509zzdwPFQ4_1562262040192-HIhyl.m3u8",
        "url_resource": {
          "auto": "https://vod.tadotv.com/result/rs_1562262055509zzdwPFQ4_1562262040192-HIhyl/master_auto_rs_1562262055509zzdwPFQ4_1562262040192-HIhyl.m3u8",
          "anon": "https://vod.tadotv.com/result/rs_1562262055509zzdwPFQ4_1562262040192-HIhyl/master_anon_rs_1562262055509zzdwPFQ4_1562262040192-HIhyl.m3u8",
          "low": "https://vod.tadotv.com/result/rs_1562262055509zzdwPFQ4_1562262040192-HIhyl/master_low_rs_1562262055509zzdwPFQ4_1562262040192-HIhyl.m3u8",
          "basic": "https://vod.tadotv.com/result/rs_1562262055509zzdwPFQ4_1562262040192-HIhyl/master_basic_rs_1562262055509zzdwPFQ4_1562262040192-HIhyl.m3u8",
          "high": "https://vod.tadotv.com/result/rs_1562262055509zzdwPFQ4_1562262040192-HIhyl/master_high_rs_1562262055509zzdwPFQ4_1562262040192-HIhyl.m3u8",
          "ultra": "https://vod.tadotv.com/result/rs_1562262055509zzdwPFQ4_1562262040192-HIhyl/master_ultra_rs_1562262055509zzdwPFQ4_1562262040192-HIhyl.m3u8"
        },
        "bridge_id": 3048,
        "bridge_url": "https://vod.tadotv.com/result/rs_1562262865604iF1ow6ez_1562262854375-zp4kw/master_anon_rs_1562262865604iF1ow6ez_1562262854375-zp4kw.m3u8",
        "bridge_url_resource": {
          "auto": "https://vod.tadotv.com/result/rs_1562262865604iF1ow6ez_1562262854375-zp4kw/master_auto_rs_1562262865604iF1ow6ez_1562262854375-zp4kw.m3u8",
          "anon": "https://vod.tadotv.com/result/rs_1562262865604iF1ow6ez_1562262854375-zp4kw/master_anon_rs_1562262865604iF1ow6ez_1562262854375-zp4kw.m3u8",
          "low": "https://vod.tadotv.com/result/rs_1562262865604iF1ow6ez_1562262854375-zp4kw/master_low_rs_1562262865604iF1ow6ez_1562262854375-zp4kw.m3u8",
          "basic": "https://vod.tadotv.com/result/rs_1562262865604iF1ow6ez_1562262854375-zp4kw/master_basic_rs_1562262865604iF1ow6ez_1562262854375-zp4kw.m3u8",
          "high": "https://vod.tadotv.com/result/rs_1562262865604iF1ow6ez_1562262854375-zp4kw/master_high_rs_1562262865604iF1ow6ez_1562262854375-zp4kw.m3u8",
          "ultra": "https://vod.tadotv.com/result/rs_1562262865604iF1ow6ez_1562262854375-zp4kw/master_ultra_rs_1562262865604iF1ow6ez_1562262854375-zp4kw.m3u8"
        },
        "bridge_subtitle": [],
        "bridge_metadata": {
          "media": {
            "id": 3,
            "link": "https://www.pegadaian.co.id/produk/tabungan-emas",
            "button": {
              "text": "Nabung Sekarang",
              "text_color": "#ffffff",
              "color": "#004d43"
            },
            "banner": {
              "horizontal": "https://vod.tadotv.com/banner/horizontal/1562751350078-ep3ps.jpg",
              "vertical_long": null,
              "vertical": null,
              "horizontal_time": {
                "start": 1,
                "end": 0
              }
            }
          }
        },
        "primary_lang": "ID",
        "duration": 58,
        "default_option": 3050,
        "option": [
          {
            "id": 3050,
            "title": {
              "ID": "Tunggu Reno"
            },
            "duration": 198,
            "locked": 0
          },
          {
            "id": 3051,
            "title": {
              "ID": "Tinggalkan Reno"
            },
            "duration": 191,
            "locked": 0
          }
        ],
        "metadata": {
          "is_endplot": false,
          "media": null
        }
      },
      {
        "id": 3029,
        "title": {
          "ID": "Apa lagi sih si Reno ini?"
        },
        "clip_title": "Investasi ke teman",
        "subtitle": [],
        "url": "https://vod.tadotv.com/result/rs_1562262055513kAnybH8p_1562262041199-CVQZp/master_anon_rs_1562262055513kAnybH8p_1562262041199-CVQZp.m3u8",
        "url_resource": {
          "auto": "https://vod.tadotv.com/result/rs_1562262055513kAnybH8p_1562262041199-CVQZp/master_auto_rs_1562262055513kAnybH8p_1562262041199-CVQZp.m3u8",
          "anon": "https://vod.tadotv.com/result/rs_1562262055513kAnybH8p_1562262041199-CVQZp/master_anon_rs_1562262055513kAnybH8p_1562262041199-CVQZp.m3u8",
          "low": "https://vod.tadotv.com/result/rs_1562262055513kAnybH8p_1562262041199-CVQZp/master_low_rs_1562262055513kAnybH8p_1562262041199-CVQZp.m3u8",
          "basic": "https://vod.tadotv.com/result/rs_1562262055513kAnybH8p_1562262041199-CVQZp/master_basic_rs_1562262055513kAnybH8p_1562262041199-CVQZp.m3u8",
          "high": "https://vod.tadotv.com/result/rs_1562262055513kAnybH8p_1562262041199-CVQZp/master_high_rs_1562262055513kAnybH8p_1562262041199-CVQZp.m3u8",
          "ultra": "https://vod.tadotv.com/result/rs_1562262055513kAnybH8p_1562262041199-CVQZp/master_ultra_rs_1562262055513kAnybH8p_1562262041199-CVQZp.m3u8"
        },
        "bridge_id": 3049,
        "bridge_url": "https://vod.tadotv.com/result/rs_1562262865640rpZoiUjx_1562262854414-Dddz9/master_anon_rs_1562262865640rpZoiUjx_1562262854414-Dddz9.m3u8",
        "bridge_url_resource": {
          "auto": "https://vod.tadotv.com/result/rs_1562262865640rpZoiUjx_1562262854414-Dddz9/master_auto_rs_1562262865640rpZoiUjx_1562262854414-Dddz9.m3u8",
          "anon": "https://vod.tadotv.com/result/rs_1562262865640rpZoiUjx_1562262854414-Dddz9/master_anon_rs_1562262865640rpZoiUjx_1562262854414-Dddz9.m3u8",
          "low": "https://vod.tadotv.com/result/rs_1562262865640rpZoiUjx_1562262854414-Dddz9/master_low_rs_1562262865640rpZoiUjx_1562262854414-Dddz9.m3u8",
          "basic": "https://vod.tadotv.com/result/rs_1562262865640rpZoiUjx_1562262854414-Dddz9/master_basic_rs_1562262865640rpZoiUjx_1562262854414-Dddz9.m3u8",
          "high": "https://vod.tadotv.com/result/rs_1562262865640rpZoiUjx_1562262854414-Dddz9/master_high_rs_1562262865640rpZoiUjx_1562262854414-Dddz9.m3u8",
          "ultra": "https://vod.tadotv.com/result/rs_1562262865640rpZoiUjx_1562262854414-Dddz9/master_ultra_rs_1562262865640rpZoiUjx_1562262854414-Dddz9.m3u8"
        },
        "bridge_subtitle": [],
        "bridge_metadata": {
          "media": {
            "id": 3,
            "link": "https://www.pegadaian.co.id/produk/tabungan-emas",
            "button": {
              "text": "Nabung Sekarang",
              "text_color": "#ffffff",
              "color": "#004d43"
            },
            "banner": {
              "horizontal": "https://vod.tadotv.com/banner/horizontal/1562751350078-ep3ps.jpg",
              "vertical_long": null,
              "vertical": null,
              "horizontal_time": {
                "start": 1,
                "end": 0
              }
            }
          }
        },
        "primary_lang": "ID",
        "duration": 122,
        "default_option": 3054,
        "option": [
          {
            "id": 3054,
            "title": {
              "ID": "Tunggu Reno"
            },
            "duration": 198,
            "locked": 0
          },
          {
            "id": 3055,
            "title": {
              "ID": "Tinggalkan Reno"
            },
            "duration": 191,
            "locked": 0
          }
        ],
        "metadata": {
          "is_endplot": false,
          "media": null
        }
      },
      {
        "id": 3031,
        "title": {
          "ID": "Coba atau jangan?"
        },
        "clip_title": "Tunggu Reno",
        "subtitle": [],
        "url": "https://vod.tadotv.com/result/rs_1575441917974ECXjLy4L_1562262210383-qVwnD/master_anon_rs_1575441917974ECXjLy4L_1562262210383-qVwnD.m3u8",
        "url_resource": {
          "auto": "https://vod.tadotv.com/result/rs_1575441917974ECXjLy4L_1562262210383-qVwnD/master_auto_rs_1575441917974ECXjLy4L_1562262210383-qVwnD.m3u8",
          "anon": "https://vod.tadotv.com/result/rs_1575441917974ECXjLy4L_1562262210383-qVwnD/master_anon_rs_1575441917974ECXjLy4L_1562262210383-qVwnD.m3u8",
          "low": "https://vod.tadotv.com/result/rs_1575441917974ECXjLy4L_1562262210383-qVwnD/master_low_rs_1575441917974ECXjLy4L_1562262210383-qVwnD.m3u8",
          "basic": "https://vod.tadotv.com/result/rs_1575441917974ECXjLy4L_1562262210383-qVwnD/master_basic_rs_1575441917974ECXjLy4L_1562262210383-qVwnD.m3u8",
          "high": "https://vod.tadotv.com/result/rs_1575441917974ECXjLy4L_1562262210383-qVwnD/master_high_rs_1575441917974ECXjLy4L_1562262210383-qVwnD.m3u8",
          "ultra": "https://vod.tadotv.com/result/rs_1575441917974ECXjLy4L_1562262210383-qVwnD/master_ultra_rs_1575441917974ECXjLy4L_1562262210383-qVwnD.m3u8"
        },
        "bridge_id": 3033,
        "bridge_url": "https://vod.tadotv.com/result/rs_1562262919785e1tB2Z0f_1562262908485-rKS8g/master_anon_rs_1562262919785e1tB2Z0f_1562262908485-rKS8g.m3u8",
        "bridge_url_resource": {
          "auto": "https://vod.tadotv.com/result/rs_1562262919785e1tB2Z0f_1562262908485-rKS8g/master_auto_rs_1562262919785e1tB2Z0f_1562262908485-rKS8g.m3u8",
          "anon": "https://vod.tadotv.com/result/rs_1562262919785e1tB2Z0f_1562262908485-rKS8g/master_anon_rs_1562262919785e1tB2Z0f_1562262908485-rKS8g.m3u8",
          "low": "https://vod.tadotv.com/result/rs_1562262919785e1tB2Z0f_1562262908485-rKS8g/master_low_rs_1562262919785e1tB2Z0f_1562262908485-rKS8g.m3u8",
          "basic": "https://vod.tadotv.com/result/rs_1562262919785e1tB2Z0f_1562262908485-rKS8g/master_basic_rs_1562262919785e1tB2Z0f_1562262908485-rKS8g.m3u8",
          "high": "https://vod.tadotv.com/result/rs_1562262919785e1tB2Z0f_1562262908485-rKS8g/master_high_rs_1562262919785e1tB2Z0f_1562262908485-rKS8g.m3u8",
          "ultra": "https://vod.tadotv.com/result/rs_1562262919785e1tB2Z0f_1562262908485-rKS8g/master_ultra_rs_1562262919785e1tB2Z0f_1562262908485-rKS8g.m3u8"
        },
        "bridge_subtitle": [],
        "bridge_metadata": {
          "media": {
            "id": 3,
            "link": "https://www.pegadaian.co.id/produk/tabungan-emas",
            "button": {
              "text": "Nabung Sekarang",
              "text_color": "#ffffff",
              "color": "#004d43"
            },
            "banner": {
              "horizontal": "https://vod.tadotv.com/banner/horizontal/1562751350078-ep3ps.jpg",
              "vertical_long": null,
              "vertical": null,
              "horizontal_time": {
                "start": 1,
                "end": 0
              }
            }
          }
        },
        "primary_lang": "ID",
        "duration": 198,
        "default_option": 3035,
        "option": [
          {
            "id": 3035,
            "title": {
              "ID": "Coba"
            },
            "duration": 188,
            "locked": 0
          },
          {
            "id": 3036,
            "title": {
              "ID": "Jangan"
            },
            "duration": 113,
            "locked": 0
          }
        ],
        "metadata": {
          "is_endplot": false,
          "media": null
        }
      },
      {
        "id": 3032,
        "title": {
          "ID": "Coba atau jangan?"
        },
        "clip_title": "Tinggalkan Reno",
        "subtitle": [],
        "url": "https://vod.tadotv.com/result/rs_1562262265369r3NdUd1v_1562262253857-YDru8/master_anon_rs_1562262265369r3NdUd1v_1562262253857-YDru8.m3u8",
        "url_resource": {
          "auto": "https://vod.tadotv.com/result/rs_1562262265369r3NdUd1v_1562262253857-YDru8/master_auto_rs_1562262265369r3NdUd1v_1562262253857-YDru8.m3u8",
          "anon": "https://vod.tadotv.com/result/rs_1562262265369r3NdUd1v_1562262253857-YDru8/master_anon_rs_1562262265369r3NdUd1v_1562262253857-YDru8.m3u8",
          "low": "https://vod.tadotv.com/result/rs_1562262265369r3NdUd1v_1562262253857-YDru8/master_low_rs_1562262265369r3NdUd1v_1562262253857-YDru8.m3u8",
          "basic": "https://vod.tadotv.com/result/rs_1562262265369r3NdUd1v_1562262253857-YDru8/master_basic_rs_1562262265369r3NdUd1v_1562262253857-YDru8.m3u8",
          "high": "https://vod.tadotv.com/result/rs_1562262265369r3NdUd1v_1562262253857-YDru8/master_high_rs_1562262265369r3NdUd1v_1562262253857-YDru8.m3u8",
          "ultra": "https://vod.tadotv.com/result/rs_1562262265369r3NdUd1v_1562262253857-YDru8/master_ultra_rs_1562262265369r3NdUd1v_1562262253857-YDru8.m3u8"
        },
        "bridge_id": 3034,
        "bridge_url": "https://vod.tadotv.com/result/rs_15622629197817I1zuJ9I_1562262908529-TIbQ2/master_anon_rs_15622629197817I1zuJ9I_1562262908529-TIbQ2.m3u8",
        "bridge_url_resource": {
          "auto": "https://vod.tadotv.com/result/rs_15622629197817I1zuJ9I_1562262908529-TIbQ2/master_auto_rs_15622629197817I1zuJ9I_1562262908529-TIbQ2.m3u8",
          "anon": "https://vod.tadotv.com/result/rs_15622629197817I1zuJ9I_1562262908529-TIbQ2/master_anon_rs_15622629197817I1zuJ9I_1562262908529-TIbQ2.m3u8",
          "low": "https://vod.tadotv.com/result/rs_15622629197817I1zuJ9I_1562262908529-TIbQ2/master_low_rs_15622629197817I1zuJ9I_1562262908529-TIbQ2.m3u8",
          "basic": "https://vod.tadotv.com/result/rs_15622629197817I1zuJ9I_1562262908529-TIbQ2/master_basic_rs_15622629197817I1zuJ9I_1562262908529-TIbQ2.m3u8",
          "high": "https://vod.tadotv.com/result/rs_15622629197817I1zuJ9I_1562262908529-TIbQ2/master_high_rs_15622629197817I1zuJ9I_1562262908529-TIbQ2.m3u8",
          "ultra": "https://vod.tadotv.com/result/rs_15622629197817I1zuJ9I_1562262908529-TIbQ2/master_ultra_rs_15622629197817I1zuJ9I_1562262908529-TIbQ2.m3u8"
        },
        "bridge_subtitle": [],
        "bridge_metadata": {
          "media": {
            "id": 3,
            "link": "https://www.pegadaian.co.id/produk/tabungan-emas",
            "button": {
              "text": "Nabung Sekarang",
              "text_color": "#ffffff",
              "color": "#004d43"
            },
            "banner": {
              "horizontal": "https://vod.tadotv.com/banner/horizontal/1562751350078-ep3ps.jpg",
              "vertical_long": null,
              "vertical": null,
              "horizontal_time": {
                "start": 1,
                "end": 0
              }
            }
          }
        },
        "primary_lang": "ID",
        "duration": 191,
        "default_option": 3037,
        "option": [
          {
            "id": 3037,
            "title": {
              "ID": "Coba"
            },
            "duration": 188,
            "locked": 0
          },
          {
            "id": 3038,
            "title": {
              "ID": "Jangan"
            },
            "duration": 113,
            "locked": 0
          }
        ],
        "metadata": {
          "is_endplot": false,
          "media": null
        }
      },
      {
        "id": 3035,
        "title": "",
        "clip_title": "Coba",
        "subtitle": [],
        "url": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_anon_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
        "url_resource": {
          "auto": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_auto_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
          "anon": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_anon_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
          "low": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_low_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
          "basic": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_basic_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
          "high": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_high_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
          "ultra": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_ultra_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8"
        },
        "bridge_id": "",
        "bridge_url": "",
        "bridge_url_resource": "",
        "bridge_subtitle": [],
        "bridge_metadata": null,
        "primary_lang": "ID",
        "duration": 188,
        "default_option": "",
        "option": [],
        "metadata": {
          "is_endplot": true,
          "media": {
            "id": 1,
            "link": "https://www.pegadaian.co.id/produk/tabungan-emas",
            "button": {
              "text": "Nabung Sekarang",
              "text_color": "#ffffff",
              "color": "#004d43"
            },
            "banner": {
              "horizontal": "https://vod.tadotv.com/banner/horizontal/1562751350078-ep3ps.jpg",
              "vertical_long": "https://vod.tadotv.com/banner/vertical_long/1562751851664-OFuwT.jpg",
              "vertical": "https://vod.tadotv.com/banner/vertical_regular/1563345607635-mdV52.jpg",
              "vertical_time": {
                "start": 140,
                "end": 0
              },
              "vertical_long_time": {
                "start": 140,
                "end": 0
              },
              "horizontal_time": {
                "start": 140,
                "end": 0
              }
            }
          }
        }
      },
      {
        "id": 3036,
        "title": "",
        "clip_title": "Jangan",
        "subtitle": [],
        "url": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_anon_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
        "url_resource": {
          "auto": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_auto_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
          "anon": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_anon_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
          "low": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_low_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
          "basic": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_basic_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
          "high": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_high_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
          "ultra": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_ultra_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8"
        },
        "bridge_id": "",
        "bridge_url": "",
        "bridge_url_resource": "",
        "bridge_subtitle": [],
        "bridge_metadata": null,
        "primary_lang": "ID",
        "duration": 113,
        "default_option": "",
        "option": [],
        "metadata": {
          "is_endplot": true,
          "media": {
            "id": 1,
            "link": "https://www.pegadaian.co.id/produk/tabungan-emas",
            "button": {
              "text": "Nabung Sekarang",
              "text_color": "#ffffff",
              "color": "#004d43"
            },
            "banner": {
              "horizontal": "https://vod.tadotv.com/banner/horizontal/1562751350078-ep3ps.jpg",
              "vertical_long": "https://vod.tadotv.com/banner/vertical_long/1562751851664-OFuwT.jpg",
              "vertical": "https://vod.tadotv.com/banner/vertical_regular/1563345607635-mdV52.jpg",
              "vertical_time": {
                "start": 112,
                "end": 0
              },
              "vertical_long_time": {
                "start": 112,
                "end": 0
              },
              "horizontal_time": {
                "start": 112,
                "end": 0
              }
            }
          }
        }
      },
      {
        "id": 3037,
        "title": "",
        "clip_title": "Coba",
        "subtitle": [],
        "url": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_anon_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
        "url_resource": {
          "auto": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_auto_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
          "anon": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_anon_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
          "low": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_low_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
          "basic": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_basic_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
          "high": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_high_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
          "ultra": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_ultra_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8"
        },
        "bridge_id": "",
        "bridge_url": "",
        "bridge_url_resource": "",
        "bridge_subtitle": [],
        "bridge_metadata": null,
        "primary_lang": "ID",
        "duration": 188,
        "default_option": "",
        "option": [],
        "metadata": {
          "is_endplot": true,
          "media": {
            "id": 1,
            "link": "https://www.pegadaian.co.id/produk/tabungan-emas",
            "button": {
              "text": "Nabung Sekarang",
              "text_color": "#ffffff",
              "color": "#004d43"
            },
            "banner": {
              "horizontal": "https://vod.tadotv.com/banner/horizontal/1562751350078-ep3ps.jpg",
              "vertical_long": "https://vod.tadotv.com/banner/vertical_long/1562751851664-OFuwT.jpg",
              "vertical": "https://vod.tadotv.com/banner/vertical_regular/1563345607635-mdV52.jpg",
              "vertical_time": {
                "start": 140,
                "end": 0
              },
              "vertical_long_time": {
                "start": 140,
                "end": 0
              },
              "horizontal_time": {
                "start": 140,
                "end": 0
              }
            }
          }
        }
      },
      {
        "id": 3038,
        "title": "",
        "clip_title": "Jangan",
        "subtitle": [],
        "url": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_anon_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
        "url_resource": {
          "auto": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_auto_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
          "anon": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_anon_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
          "low": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_low_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
          "basic": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_basic_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
          "high": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_high_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
          "ultra": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_ultra_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8"
        },
        "bridge_id": "",
        "bridge_url": "",
        "bridge_url_resource": "",
        "bridge_subtitle": [],
        "bridge_metadata": null,
        "primary_lang": "ID",
        "duration": 113,
        "default_option": "",
        "option": [],
        "metadata": {
          "is_endplot": true,
          "media": {
            "id": 1,
            "link": "https://www.pegadaian.co.id/produk/tabungan-emas",
            "button": {
              "text": "Nabung Sekarang",
              "text_color": "#ffffff",
              "color": "#004d43"
            },
            "banner": {
              "horizontal": "https://vod.tadotv.com/banner/horizontal/1562751350078-ep3ps.jpg",
              "vertical_long": "https://vod.tadotv.com/banner/vertical_long/1562751851664-OFuwT.jpg",
              "vertical": "https://vod.tadotv.com/banner/vertical_regular/1563345607635-mdV52.jpg",
              "vertical_time": {
                "start": 112,
                "end": 0
              },
              "vertical_long_time": {
                "start": 112,
                "end": 0
              },
              "horizontal_time": {
                "start": 112,
                "end": 0
              }
            }
          }
        }
      },
      {
        "id": 3040,
        "title": {
          "ID": "Coba atau jangan?"
        },
        "clip_title": "Tunggu Reno",
        "subtitle": [],
        "url": "https://vod.tadotv.com/result/rs_1575441917974ECXjLy4L_1562262210383-qVwnD/master_anon_rs_1575441917974ECXjLy4L_1562262210383-qVwnD.m3u8",
        "url_resource": {
          "auto": "https://vod.tadotv.com/result/rs_1575441917974ECXjLy4L_1562262210383-qVwnD/master_auto_rs_1575441917974ECXjLy4L_1562262210383-qVwnD.m3u8",
          "anon": "https://vod.tadotv.com/result/rs_1575441917974ECXjLy4L_1562262210383-qVwnD/master_anon_rs_1575441917974ECXjLy4L_1562262210383-qVwnD.m3u8",
          "low": "https://vod.tadotv.com/result/rs_1575441917974ECXjLy4L_1562262210383-qVwnD/master_low_rs_1575441917974ECXjLy4L_1562262210383-qVwnD.m3u8",
          "basic": "https://vod.tadotv.com/result/rs_1575441917974ECXjLy4L_1562262210383-qVwnD/master_basic_rs_1575441917974ECXjLy4L_1562262210383-qVwnD.m3u8",
          "high": "https://vod.tadotv.com/result/rs_1575441917974ECXjLy4L_1562262210383-qVwnD/master_high_rs_1575441917974ECXjLy4L_1562262210383-qVwnD.m3u8",
          "ultra": "https://vod.tadotv.com/result/rs_1575441917974ECXjLy4L_1562262210383-qVwnD/master_ultra_rs_1575441917974ECXjLy4L_1562262210383-qVwnD.m3u8"
        },
        "bridge_id": 3042,
        "bridge_url": "https://vod.tadotv.com/result/rs_1562262919785e1tB2Z0f_1562262908485-rKS8g/master_anon_rs_1562262919785e1tB2Z0f_1562262908485-rKS8g.m3u8",
        "bridge_url_resource": {
          "auto": "https://vod.tadotv.com/result/rs_1562262919785e1tB2Z0f_1562262908485-rKS8g/master_auto_rs_1562262919785e1tB2Z0f_1562262908485-rKS8g.m3u8",
          "anon": "https://vod.tadotv.com/result/rs_1562262919785e1tB2Z0f_1562262908485-rKS8g/master_anon_rs_1562262919785e1tB2Z0f_1562262908485-rKS8g.m3u8",
          "low": "https://vod.tadotv.com/result/rs_1562262919785e1tB2Z0f_1562262908485-rKS8g/master_low_rs_1562262919785e1tB2Z0f_1562262908485-rKS8g.m3u8",
          "basic": "https://vod.tadotv.com/result/rs_1562262919785e1tB2Z0f_1562262908485-rKS8g/master_basic_rs_1562262919785e1tB2Z0f_1562262908485-rKS8g.m3u8",
          "high": "https://vod.tadotv.com/result/rs_1562262919785e1tB2Z0f_1562262908485-rKS8g/master_high_rs_1562262919785e1tB2Z0f_1562262908485-rKS8g.m3u8",
          "ultra": "https://vod.tadotv.com/result/rs_1562262919785e1tB2Z0f_1562262908485-rKS8g/master_ultra_rs_1562262919785e1tB2Z0f_1562262908485-rKS8g.m3u8"
        },
        "bridge_subtitle": [],
        "bridge_metadata": {
          "media": {
            "id": 3,
            "link": "https://www.pegadaian.co.id/produk/tabungan-emas",
            "button": {
              "text": "Nabung Sekarang",
              "text_color": "#ffffff",
              "color": "#004d43"
            },
            "banner": {
              "horizontal": "https://vod.tadotv.com/banner/horizontal/1562751350078-ep3ps.jpg",
              "vertical_long": null,
              "vertical": null,
              "horizontal_time": {
                "start": 1,
                "end": 0
              }
            }
          }
        },
        "primary_lang": "ID",
        "duration": 198,
        "default_option": 3044,
        "option": [
          {
            "id": 3044,
            "title": {
              "ID": "Coba"
            },
            "duration": 188,
            "locked": 0
          },
          {
            "id": 3045,
            "title": {
              "ID": "Jangan"
            },
            "duration": 113,
            "locked": 0
          }
        ],
        "metadata": {
          "is_endplot": false,
          "media": null
        }
      },
      {
        "id": 3041,
        "title": {
          "ID": "Coba atau jangan?"
        },
        "clip_title": "Tinggalkan Reno",
        "subtitle": [],
        "url": "https://vod.tadotv.com/result/rs_1562262265369r3NdUd1v_1562262253857-YDru8/master_anon_rs_1562262265369r3NdUd1v_1562262253857-YDru8.m3u8",
        "url_resource": {
          "auto": "https://vod.tadotv.com/result/rs_1562262265369r3NdUd1v_1562262253857-YDru8/master_auto_rs_1562262265369r3NdUd1v_1562262253857-YDru8.m3u8",
          "anon": "https://vod.tadotv.com/result/rs_1562262265369r3NdUd1v_1562262253857-YDru8/master_anon_rs_1562262265369r3NdUd1v_1562262253857-YDru8.m3u8",
          "low": "https://vod.tadotv.com/result/rs_1562262265369r3NdUd1v_1562262253857-YDru8/master_low_rs_1562262265369r3NdUd1v_1562262253857-YDru8.m3u8",
          "basic": "https://vod.tadotv.com/result/rs_1562262265369r3NdUd1v_1562262253857-YDru8/master_basic_rs_1562262265369r3NdUd1v_1562262253857-YDru8.m3u8",
          "high": "https://vod.tadotv.com/result/rs_1562262265369r3NdUd1v_1562262253857-YDru8/master_high_rs_1562262265369r3NdUd1v_1562262253857-YDru8.m3u8",
          "ultra": "https://vod.tadotv.com/result/rs_1562262265369r3NdUd1v_1562262253857-YDru8/master_ultra_rs_1562262265369r3NdUd1v_1562262253857-YDru8.m3u8"
        },
        "bridge_id": 3043,
        "bridge_url": "https://vod.tadotv.com/result/rs_15622629197817I1zuJ9I_1562262908529-TIbQ2/master_anon_rs_15622629197817I1zuJ9I_1562262908529-TIbQ2.m3u8",
        "bridge_url_resource": {
          "auto": "https://vod.tadotv.com/result/rs_15622629197817I1zuJ9I_1562262908529-TIbQ2/master_auto_rs_15622629197817I1zuJ9I_1562262908529-TIbQ2.m3u8",
          "anon": "https://vod.tadotv.com/result/rs_15622629197817I1zuJ9I_1562262908529-TIbQ2/master_anon_rs_15622629197817I1zuJ9I_1562262908529-TIbQ2.m3u8",
          "low": "https://vod.tadotv.com/result/rs_15622629197817I1zuJ9I_1562262908529-TIbQ2/master_low_rs_15622629197817I1zuJ9I_1562262908529-TIbQ2.m3u8",
          "basic": "https://vod.tadotv.com/result/rs_15622629197817I1zuJ9I_1562262908529-TIbQ2/master_basic_rs_15622629197817I1zuJ9I_1562262908529-TIbQ2.m3u8",
          "high": "https://vod.tadotv.com/result/rs_15622629197817I1zuJ9I_1562262908529-TIbQ2/master_high_rs_15622629197817I1zuJ9I_1562262908529-TIbQ2.m3u8",
          "ultra": "https://vod.tadotv.com/result/rs_15622629197817I1zuJ9I_1562262908529-TIbQ2/master_ultra_rs_15622629197817I1zuJ9I_1562262908529-TIbQ2.m3u8"
        },
        "bridge_subtitle": [],
        "bridge_metadata": {
          "media": {
            "id": 3,
            "link": "https://www.pegadaian.co.id/produk/tabungan-emas",
            "button": {
              "text": "Nabung Sekarang",
              "text_color": "#ffffff",
              "color": "#004d43"
            },
            "banner": {
              "horizontal": "https://vod.tadotv.com/banner/horizontal/1562751350078-ep3ps.jpg",
              "vertical_long": null,
              "vertical": null,
              "horizontal_time": {
                "start": 1,
                "end": 0
              }
            }
          }
        },
        "primary_lang": "ID",
        "duration": 191,
        "default_option": 3046,
        "option": [
          {
            "id": 3046,
            "title": {
              "ID": "Coba"
            },
            "duration": 188,
            "locked": 0
          },
          {
            "id": 3047,
            "title": {
              "ID": "Jangan"
            },
            "duration": 113,
            "locked": 0
          }
        ],
        "metadata": {
          "is_endplot": false,
          "media": null
        }
      },
      {
        "id": 3044,
        "title": "",
        "clip_title": "Coba",
        "subtitle": [],
        "url": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_anon_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
        "url_resource": {
          "auto": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_auto_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
          "anon": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_anon_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
          "low": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_low_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
          "basic": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_basic_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
          "high": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_high_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
          "ultra": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_ultra_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8"
        },
        "bridge_id": "",
        "bridge_url": "",
        "bridge_url_resource": "",
        "bridge_subtitle": [],
        "bridge_metadata": null,
        "primary_lang": "ID",
        "duration": 188,
        "default_option": "",
        "option": [],
        "metadata": {
          "is_endplot": true,
          "media": {
            "id": 1,
            "link": "https://www.pegadaian.co.id/produk/tabungan-emas",
            "button": {
              "text": "Nabung Sekarang",
              "text_color": "#ffffff",
              "color": "#004d43"
            },
            "banner": {
              "horizontal": "https://vod.tadotv.com/banner/horizontal/1562751350078-ep3ps.jpg",
              "vertical_long": "https://vod.tadotv.com/banner/vertical_long/1562751851664-OFuwT.jpg",
              "vertical": "https://vod.tadotv.com/banner/vertical_regular/1563345607635-mdV52.jpg",
              "vertical_time": {
                "start": 140,
                "end": 0
              },
              "vertical_long_time": {
                "start": 140,
                "end": 0
              },
              "horizontal_time": {
                "start": 140,
                "end": 0
              }
            }
          }
        }
      },
      {
        "id": 3045,
        "title": "",
        "clip_title": "Jangan",
        "subtitle": [],
        "url": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_anon_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
        "url_resource": {
          "auto": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_auto_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
          "anon": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_anon_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
          "low": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_low_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
          "basic": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_basic_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
          "high": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_high_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
          "ultra": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_ultra_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8"
        },
        "bridge_id": "",
        "bridge_url": "",
        "bridge_url_resource": "",
        "bridge_subtitle": [],
        "bridge_metadata": null,
        "primary_lang": "ID",
        "duration": 113,
        "default_option": "",
        "option": [],
        "metadata": {
          "is_endplot": true,
          "media": {
            "id": 1,
            "link": "https://www.pegadaian.co.id/produk/tabungan-emas",
            "button": {
              "text": "Nabung Sekarang",
              "text_color": "#ffffff",
              "color": "#004d43"
            },
            "banner": {
              "horizontal": "https://vod.tadotv.com/banner/horizontal/1562751350078-ep3ps.jpg",
              "vertical_long": "https://vod.tadotv.com/banner/vertical_long/1562751851664-OFuwT.jpg",
              "vertical": "https://vod.tadotv.com/banner/vertical_regular/1563345607635-mdV52.jpg",
              "vertical_time": {
                "start": 112,
                "end": 0
              },
              "vertical_long_time": {
                "start": 112,
                "end": 0
              },
              "horizontal_time": {
                "start": 112,
                "end": 0
              }
            }
          }
        }
      },
      {
        "id": 3046,
        "title": "",
        "clip_title": "Coba",
        "subtitle": [],
        "url": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_anon_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
        "url_resource": {
          "auto": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_auto_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
          "anon": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_anon_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
          "low": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_low_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
          "basic": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_basic_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
          "high": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_high_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
          "ultra": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_ultra_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8"
        },
        "bridge_id": "",
        "bridge_url": "",
        "bridge_url_resource": "",
        "bridge_subtitle": [],
        "bridge_metadata": null,
        "primary_lang": "ID",
        "duration": 188,
        "default_option": "",
        "option": [],
        "metadata": {
          "is_endplot": true,
          "media": {
            "id": 1,
            "link": "https://www.pegadaian.co.id/produk/tabungan-emas",
            "button": {
              "text": "Nabung Sekarang",
              "text_color": "#ffffff",
              "color": "#004d43"
            },
            "banner": {
              "horizontal": "https://vod.tadotv.com/banner/horizontal/1562751350078-ep3ps.jpg",
              "vertical_long": "https://vod.tadotv.com/banner/vertical_long/1562751851664-OFuwT.jpg",
              "vertical": "https://vod.tadotv.com/banner/vertical_regular/1563345607635-mdV52.jpg",
              "vertical_time": {
                "start": 140,
                "end": 0
              },
              "vertical_long_time": {
                "start": 140,
                "end": 0
              },
              "horizontal_time": {
                "start": 140,
                "end": 0
              }
            }
          }
        }
      },
      {
        "id": 3047,
        "title": "",
        "clip_title": "Jangan",
        "subtitle": [],
        "url": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_anon_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
        "url_resource": {
          "auto": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_auto_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
          "anon": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_anon_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
          "low": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_low_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
          "basic": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_basic_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
          "high": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_high_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
          "ultra": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_ultra_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8"
        },
        "bridge_id": "",
        "bridge_url": "",
        "bridge_url_resource": "",
        "bridge_subtitle": [],
        "bridge_metadata": null,
        "primary_lang": "ID",
        "duration": 113,
        "default_option": "",
        "option": [],
        "metadata": {
          "is_endplot": true,
          "media": {
            "id": 1,
            "link": "https://www.pegadaian.co.id/produk/tabungan-emas",
            "button": {
              "text": "Nabung Sekarang",
              "text_color": "#ffffff",
              "color": "#004d43"
            },
            "banner": {
              "horizontal": "https://vod.tadotv.com/banner/horizontal/1562751350078-ep3ps.jpg",
              "vertical_long": "https://vod.tadotv.com/banner/vertical_long/1562751851664-OFuwT.jpg",
              "vertical": "https://vod.tadotv.com/banner/vertical_regular/1563345607635-mdV52.jpg",
              "vertical_time": {
                "start": 112,
                "end": 0
              },
              "vertical_long_time": {
                "start": 112,
                "end": 0
              },
              "horizontal_time": {
                "start": 112,
                "end": 0
              }
            }
          }
        }
      },
      {
        "id": 3050,
        "title": {
          "ID": "Coba atau jangan?"
        },
        "clip_title": "Tunggu Reno",
        "subtitle": [],
        "url": "https://vod.tadotv.com/result/rs_1575441917974ECXjLy4L_1562262210383-qVwnD/master_anon_rs_1575441917974ECXjLy4L_1562262210383-qVwnD.m3u8",
        "url_resource": {
          "auto": "https://vod.tadotv.com/result/rs_1575441917974ECXjLy4L_1562262210383-qVwnD/master_auto_rs_1575441917974ECXjLy4L_1562262210383-qVwnD.m3u8",
          "anon": "https://vod.tadotv.com/result/rs_1575441917974ECXjLy4L_1562262210383-qVwnD/master_anon_rs_1575441917974ECXjLy4L_1562262210383-qVwnD.m3u8",
          "low": "https://vod.tadotv.com/result/rs_1575441917974ECXjLy4L_1562262210383-qVwnD/master_low_rs_1575441917974ECXjLy4L_1562262210383-qVwnD.m3u8",
          "basic": "https://vod.tadotv.com/result/rs_1575441917974ECXjLy4L_1562262210383-qVwnD/master_basic_rs_1575441917974ECXjLy4L_1562262210383-qVwnD.m3u8",
          "high": "https://vod.tadotv.com/result/rs_1575441917974ECXjLy4L_1562262210383-qVwnD/master_high_rs_1575441917974ECXjLy4L_1562262210383-qVwnD.m3u8",
          "ultra": "https://vod.tadotv.com/result/rs_1575441917974ECXjLy4L_1562262210383-qVwnD/master_ultra_rs_1575441917974ECXjLy4L_1562262210383-qVwnD.m3u8"
        },
        "bridge_id": 3052,
        "bridge_url": "https://vod.tadotv.com/result/rs_1562262919785e1tB2Z0f_1562262908485-rKS8g/master_anon_rs_1562262919785e1tB2Z0f_1562262908485-rKS8g.m3u8",
        "bridge_url_resource": {
          "auto": "https://vod.tadotv.com/result/rs_1562262919785e1tB2Z0f_1562262908485-rKS8g/master_auto_rs_1562262919785e1tB2Z0f_1562262908485-rKS8g.m3u8",
          "anon": "https://vod.tadotv.com/result/rs_1562262919785e1tB2Z0f_1562262908485-rKS8g/master_anon_rs_1562262919785e1tB2Z0f_1562262908485-rKS8g.m3u8",
          "low": "https://vod.tadotv.com/result/rs_1562262919785e1tB2Z0f_1562262908485-rKS8g/master_low_rs_1562262919785e1tB2Z0f_1562262908485-rKS8g.m3u8",
          "basic": "https://vod.tadotv.com/result/rs_1562262919785e1tB2Z0f_1562262908485-rKS8g/master_basic_rs_1562262919785e1tB2Z0f_1562262908485-rKS8g.m3u8",
          "high": "https://vod.tadotv.com/result/rs_1562262919785e1tB2Z0f_1562262908485-rKS8g/master_high_rs_1562262919785e1tB2Z0f_1562262908485-rKS8g.m3u8",
          "ultra": "https://vod.tadotv.com/result/rs_1562262919785e1tB2Z0f_1562262908485-rKS8g/master_ultra_rs_1562262919785e1tB2Z0f_1562262908485-rKS8g.m3u8"
        },
        "bridge_subtitle": [],
        "bridge_metadata": {
          "media": {
            "id": 3,
            "link": "https://www.pegadaian.co.id/produk/tabungan-emas",
            "button": {
              "text": "Nabung Sekarang",
              "text_color": "#ffffff",
              "color": "#004d43"
            },
            "banner": {
              "horizontal": "https://vod.tadotv.com/banner/horizontal/1562751350078-ep3ps.jpg",
              "vertical_long": null,
              "vertical": null,
              "horizontal_time": {
                "start": 1,
                "end": 0
              }
            }
          }
        },
        "primary_lang": "ID",
        "duration": 198,
        "default_option": 3058,
        "option": [
          {
            "id": 3058,
            "title": {
              "ID": "Coba"
            },
            "duration": 188,
            "locked": 0
          },
          {
            "id": 3059,
            "title": {
              "ID": "Jangan"
            },
            "duration": 113,
            "locked": 0
          }
        ],
        "metadata": {
          "is_endplot": false,
          "media": null
        }
      },
      {
        "id": 3051,
        "title": {
          "ID": "Coba atau jangan?"
        },
        "clip_title": "Tinggalkan Reno",
        "subtitle": [],
        "url": "https://vod.tadotv.com/result/rs_1562262265369r3NdUd1v_1562262253857-YDru8/master_anon_rs_1562262265369r3NdUd1v_1562262253857-YDru8.m3u8",
        "url_resource": {
          "auto": "https://vod.tadotv.com/result/rs_1562262265369r3NdUd1v_1562262253857-YDru8/master_auto_rs_1562262265369r3NdUd1v_1562262253857-YDru8.m3u8",
          "anon": "https://vod.tadotv.com/result/rs_1562262265369r3NdUd1v_1562262253857-YDru8/master_anon_rs_1562262265369r3NdUd1v_1562262253857-YDru8.m3u8",
          "low": "https://vod.tadotv.com/result/rs_1562262265369r3NdUd1v_1562262253857-YDru8/master_low_rs_1562262265369r3NdUd1v_1562262253857-YDru8.m3u8",
          "basic": "https://vod.tadotv.com/result/rs_1562262265369r3NdUd1v_1562262253857-YDru8/master_basic_rs_1562262265369r3NdUd1v_1562262253857-YDru8.m3u8",
          "high": "https://vod.tadotv.com/result/rs_1562262265369r3NdUd1v_1562262253857-YDru8/master_high_rs_1562262265369r3NdUd1v_1562262253857-YDru8.m3u8",
          "ultra": "https://vod.tadotv.com/result/rs_1562262265369r3NdUd1v_1562262253857-YDru8/master_ultra_rs_1562262265369r3NdUd1v_1562262253857-YDru8.m3u8"
        },
        "bridge_id": 3053,
        "bridge_url": "https://vod.tadotv.com/result/rs_15622629197817I1zuJ9I_1562262908529-TIbQ2/master_anon_rs_15622629197817I1zuJ9I_1562262908529-TIbQ2.m3u8",
        "bridge_url_resource": {
          "auto": "https://vod.tadotv.com/result/rs_15622629197817I1zuJ9I_1562262908529-TIbQ2/master_auto_rs_15622629197817I1zuJ9I_1562262908529-TIbQ2.m3u8",
          "anon": "https://vod.tadotv.com/result/rs_15622629197817I1zuJ9I_1562262908529-TIbQ2/master_anon_rs_15622629197817I1zuJ9I_1562262908529-TIbQ2.m3u8",
          "low": "https://vod.tadotv.com/result/rs_15622629197817I1zuJ9I_1562262908529-TIbQ2/master_low_rs_15622629197817I1zuJ9I_1562262908529-TIbQ2.m3u8",
          "basic": "https://vod.tadotv.com/result/rs_15622629197817I1zuJ9I_1562262908529-TIbQ2/master_basic_rs_15622629197817I1zuJ9I_1562262908529-TIbQ2.m3u8",
          "high": "https://vod.tadotv.com/result/rs_15622629197817I1zuJ9I_1562262908529-TIbQ2/master_high_rs_15622629197817I1zuJ9I_1562262908529-TIbQ2.m3u8",
          "ultra": "https://vod.tadotv.com/result/rs_15622629197817I1zuJ9I_1562262908529-TIbQ2/master_ultra_rs_15622629197817I1zuJ9I_1562262908529-TIbQ2.m3u8"
        },
        "bridge_subtitle": [],
        "bridge_metadata": {
          "media": {
            "id": 3,
            "link": "https://www.pegadaian.co.id/produk/tabungan-emas",
            "button": {
              "text": "Nabung Sekarang",
              "text_color": "#ffffff",
              "color": "#004d43"
            },
            "banner": {
              "horizontal": "https://vod.tadotv.com/banner/horizontal/1562751350078-ep3ps.jpg",
              "vertical_long": null,
              "vertical": null,
              "horizontal_time": {
                "start": 1,
                "end": 0
              }
            }
          }
        },
        "primary_lang": "ID",
        "duration": 191,
        "default_option": 3060,
        "option": [
          {
            "id": 3060,
            "title": {
              "ID": "Coba"
            },
            "duration": 188,
            "locked": 0
          },
          {
            "id": 3061,
            "title": {
              "ID": "Jangan"
            },
            "duration": 113,
            "locked": 0
          }
        ],
        "metadata": {
          "is_endplot": false,
          "media": null
        }
      },
      {
        "id": 3054,
        "title": {
          "ID": "Coba atau jangan?"
        },
        "clip_title": "Tunggu Reno",
        "subtitle": [],
        "url": "https://vod.tadotv.com/result/rs_1575441917974ECXjLy4L_1562262210383-qVwnD/master_anon_rs_1575441917974ECXjLy4L_1562262210383-qVwnD.m3u8",
        "url_resource": {
          "auto": "https://vod.tadotv.com/result/rs_1575441917974ECXjLy4L_1562262210383-qVwnD/master_auto_rs_1575441917974ECXjLy4L_1562262210383-qVwnD.m3u8",
          "anon": "https://vod.tadotv.com/result/rs_1575441917974ECXjLy4L_1562262210383-qVwnD/master_anon_rs_1575441917974ECXjLy4L_1562262210383-qVwnD.m3u8",
          "low": "https://vod.tadotv.com/result/rs_1575441917974ECXjLy4L_1562262210383-qVwnD/master_low_rs_1575441917974ECXjLy4L_1562262210383-qVwnD.m3u8",
          "basic": "https://vod.tadotv.com/result/rs_1575441917974ECXjLy4L_1562262210383-qVwnD/master_basic_rs_1575441917974ECXjLy4L_1562262210383-qVwnD.m3u8",
          "high": "https://vod.tadotv.com/result/rs_1575441917974ECXjLy4L_1562262210383-qVwnD/master_high_rs_1575441917974ECXjLy4L_1562262210383-qVwnD.m3u8",
          "ultra": "https://vod.tadotv.com/result/rs_1575441917974ECXjLy4L_1562262210383-qVwnD/master_ultra_rs_1575441917974ECXjLy4L_1562262210383-qVwnD.m3u8"
        },
        "bridge_id": 3056,
        "bridge_url": "https://vod.tadotv.com/result/rs_1562262919785e1tB2Z0f_1562262908485-rKS8g/master_anon_rs_1562262919785e1tB2Z0f_1562262908485-rKS8g.m3u8",
        "bridge_url_resource": {
          "auto": "https://vod.tadotv.com/result/rs_1562262919785e1tB2Z0f_1562262908485-rKS8g/master_auto_rs_1562262919785e1tB2Z0f_1562262908485-rKS8g.m3u8",
          "anon": "https://vod.tadotv.com/result/rs_1562262919785e1tB2Z0f_1562262908485-rKS8g/master_anon_rs_1562262919785e1tB2Z0f_1562262908485-rKS8g.m3u8",
          "low": "https://vod.tadotv.com/result/rs_1562262919785e1tB2Z0f_1562262908485-rKS8g/master_low_rs_1562262919785e1tB2Z0f_1562262908485-rKS8g.m3u8",
          "basic": "https://vod.tadotv.com/result/rs_1562262919785e1tB2Z0f_1562262908485-rKS8g/master_basic_rs_1562262919785e1tB2Z0f_1562262908485-rKS8g.m3u8",
          "high": "https://vod.tadotv.com/result/rs_1562262919785e1tB2Z0f_1562262908485-rKS8g/master_high_rs_1562262919785e1tB2Z0f_1562262908485-rKS8g.m3u8",
          "ultra": "https://vod.tadotv.com/result/rs_1562262919785e1tB2Z0f_1562262908485-rKS8g/master_ultra_rs_1562262919785e1tB2Z0f_1562262908485-rKS8g.m3u8"
        },
        "bridge_subtitle": [],
        "bridge_metadata": null,
        "primary_lang": "ID",
        "duration": 198,
        "default_option": 3062,
        "option": [
          {
            "id": 3062,
            "title": {
              "ID": "Coba"
            },
            "duration": 188,
            "locked": 0
          },
          {
            "id": 3063,
            "title": {
              "ID": "Jangan"
            },
            "duration": 113,
            "locked": 0
          }
        ],
        "metadata": {
          "is_endplot": false,
          "media": null
        }
      },
      {
        "id": 3055,
        "title": {
          "ID": "Coba atau jangan?"
        },
        "clip_title": "Tinggalkan Reno",
        "subtitle": [],
        "url": "https://vod.tadotv.com/result/rs_1562262265369r3NdUd1v_1562262253857-YDru8/master_anon_rs_1562262265369r3NdUd1v_1562262253857-YDru8.m3u8",
        "url_resource": {
          "auto": "https://vod.tadotv.com/result/rs_1562262265369r3NdUd1v_1562262253857-YDru8/master_auto_rs_1562262265369r3NdUd1v_1562262253857-YDru8.m3u8",
          "anon": "https://vod.tadotv.com/result/rs_1562262265369r3NdUd1v_1562262253857-YDru8/master_anon_rs_1562262265369r3NdUd1v_1562262253857-YDru8.m3u8",
          "low": "https://vod.tadotv.com/result/rs_1562262265369r3NdUd1v_1562262253857-YDru8/master_low_rs_1562262265369r3NdUd1v_1562262253857-YDru8.m3u8",
          "basic": "https://vod.tadotv.com/result/rs_1562262265369r3NdUd1v_1562262253857-YDru8/master_basic_rs_1562262265369r3NdUd1v_1562262253857-YDru8.m3u8",
          "high": "https://vod.tadotv.com/result/rs_1562262265369r3NdUd1v_1562262253857-YDru8/master_high_rs_1562262265369r3NdUd1v_1562262253857-YDru8.m3u8",
          "ultra": "https://vod.tadotv.com/result/rs_1562262265369r3NdUd1v_1562262253857-YDru8/master_ultra_rs_1562262265369r3NdUd1v_1562262253857-YDru8.m3u8"
        },
        "bridge_id": 3057,
        "bridge_url": "https://vod.tadotv.com/result/rs_15622629197817I1zuJ9I_1562262908529-TIbQ2/master_anon_rs_15622629197817I1zuJ9I_1562262908529-TIbQ2.m3u8",
        "bridge_url_resource": {
          "auto": "https://vod.tadotv.com/result/rs_15622629197817I1zuJ9I_1562262908529-TIbQ2/master_auto_rs_15622629197817I1zuJ9I_1562262908529-TIbQ2.m3u8",
          "anon": "https://vod.tadotv.com/result/rs_15622629197817I1zuJ9I_1562262908529-TIbQ2/master_anon_rs_15622629197817I1zuJ9I_1562262908529-TIbQ2.m3u8",
          "low": "https://vod.tadotv.com/result/rs_15622629197817I1zuJ9I_1562262908529-TIbQ2/master_low_rs_15622629197817I1zuJ9I_1562262908529-TIbQ2.m3u8",
          "basic": "https://vod.tadotv.com/result/rs_15622629197817I1zuJ9I_1562262908529-TIbQ2/master_basic_rs_15622629197817I1zuJ9I_1562262908529-TIbQ2.m3u8",
          "high": "https://vod.tadotv.com/result/rs_15622629197817I1zuJ9I_1562262908529-TIbQ2/master_high_rs_15622629197817I1zuJ9I_1562262908529-TIbQ2.m3u8",
          "ultra": "https://vod.tadotv.com/result/rs_15622629197817I1zuJ9I_1562262908529-TIbQ2/master_ultra_rs_15622629197817I1zuJ9I_1562262908529-TIbQ2.m3u8"
        },
        "bridge_subtitle": [],
        "bridge_metadata": {
          "media": {
            "id": 3,
            "link": "https://www.pegadaian.co.id/produk/tabungan-emas",
            "button": {
              "text": "Nabung Sekarang",
              "text_color": "#ffffff",
              "color": "#004d43"
            },
            "banner": {
              "horizontal": "https://vod.tadotv.com/banner/horizontal/1562751350078-ep3ps.jpg",
              "vertical_long": null,
              "vertical": null,
              "horizontal_time": {
                "start": 1,
                "end": 0
              }
            }
          }
        },
        "primary_lang": "ID",
        "duration": 191,
        "default_option": 3064,
        "option": [
          {
            "id": 3064,
            "title": {
              "ID": "Coba"
            },
            "duration": 188,
            "locked": 0
          },
          {
            "id": 3065,
            "title": {
              "ID": "Jangan"
            },
            "duration": 113,
            "locked": 0
          }
        ],
        "metadata": {
          "is_endplot": false,
          "media": null
        }
      },
      {
        "id": 3058,
        "title": "",
        "clip_title": "Coba",
        "subtitle": [],
        "url": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_anon_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
        "url_resource": {
          "auto": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_auto_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
          "anon": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_anon_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
          "low": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_low_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
          "basic": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_basic_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
          "high": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_high_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
          "ultra": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_ultra_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8"
        },
        "bridge_id": "",
        "bridge_url": "",
        "bridge_url_resource": "",
        "bridge_subtitle": [],
        "bridge_metadata": null,
        "primary_lang": "ID",
        "duration": 188,
        "default_option": "",
        "option": [],
        "metadata": {
          "is_endplot": true,
          "media": {
            "id": 1,
            "link": "https://www.pegadaian.co.id/produk/tabungan-emas",
            "button": {
              "text": "Nabung Sekarang",
              "text_color": "#ffffff",
              "color": "#004d43"
            },
            "banner": {
              "horizontal": "https://vod.tadotv.com/banner/horizontal/1562751350078-ep3ps.jpg",
              "vertical_long": "https://vod.tadotv.com/banner/vertical_long/1562751851664-OFuwT.jpg",
              "vertical": "https://vod.tadotv.com/banner/vertical_regular/1563345607635-mdV52.jpg",
              "vertical_time": {
                "start": 140,
                "end": 0
              },
              "vertical_long_time": {
                "start": 140,
                "end": 0
              },
              "horizontal_time": {
                "start": 140,
                "end": 0
              }
            }
          }
        }
      },
      {
        "id": 3059,
        "title": "",
        "clip_title": "Jangan",
        "subtitle": [],
        "url": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_anon_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
        "url_resource": {
          "auto": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_auto_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
          "anon": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_anon_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
          "low": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_low_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
          "basic": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_basic_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
          "high": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_high_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
          "ultra": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_ultra_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8"
        },
        "bridge_id": "",
        "bridge_url": "",
        "bridge_url_resource": "",
        "bridge_subtitle": [],
        "bridge_metadata": null,
        "primary_lang": "ID",
        "duration": 113,
        "default_option": "",
        "option": [],
        "metadata": {
          "is_endplot": true,
          "media": {
            "id": 1,
            "link": "https://www.pegadaian.co.id/produk/tabungan-emas",
            "button": {
              "text": "Nabung Sekarang",
              "text_color": "#ffffff",
              "color": "#004d43"
            },
            "banner": {
              "horizontal": "https://vod.tadotv.com/banner/horizontal/1562751350078-ep3ps.jpg",
              "vertical_long": "https://vod.tadotv.com/banner/vertical_long/1562751851664-OFuwT.jpg",
              "vertical": "https://vod.tadotv.com/banner/vertical_regular/1563345607635-mdV52.jpg",
              "vertical_time": {
                "start": 112,
                "end": 0
              },
              "vertical_long_time": {
                "start": 112,
                "end": 0
              },
              "horizontal_time": {
                "start": 112,
                "end": 0
              }
            }
          }
        }
      },
      {
        "id": 3060,
        "title": "",
        "clip_title": "Coba",
        "subtitle": [],
        "url": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_anon_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
        "url_resource": {
          "auto": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_auto_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
          "anon": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_anon_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
          "low": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_low_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
          "basic": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_basic_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
          "high": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_high_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
          "ultra": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_ultra_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8"
        },
        "bridge_id": "",
        "bridge_url": "",
        "bridge_url_resource": "",
        "bridge_subtitle": [],
        "bridge_metadata": null,
        "primary_lang": "ID",
        "duration": 188,
        "default_option": "",
        "option": [],
        "metadata": {
          "is_endplot": true,
          "media": {
            "id": 1,
            "link": "https://www.pegadaian.co.id/produk/tabungan-emas",
            "button": {
              "text": "Nabung Sekarang",
              "text_color": "#ffffff",
              "color": "#004d43"
            },
            "banner": {
              "horizontal": "https://vod.tadotv.com/banner/horizontal/1562751350078-ep3ps.jpg",
              "vertical_long": "https://vod.tadotv.com/banner/vertical_long/1562751851664-OFuwT.jpg",
              "vertical": "https://vod.tadotv.com/banner/vertical_regular/1563345607635-mdV52.jpg",
              "vertical_time": {
                "start": 140,
                "end": 0
              },
              "vertical_long_time": {
                "start": 140,
                "end": 0
              },
              "horizontal_time": {
                "start": 140,
                "end": 0
              }
            }
          }
        }
      },
      {
        "id": 3061,
        "title": "",
        "clip_title": "Jangan",
        "subtitle": [],
        "url": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_anon_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
        "url_resource": {
          "auto": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_auto_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
          "anon": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_anon_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
          "low": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_low_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
          "basic": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_basic_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
          "high": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_high_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
          "ultra": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_ultra_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8"
        },
        "bridge_id": "",
        "bridge_url": "",
        "bridge_url_resource": "",
        "bridge_subtitle": [],
        "bridge_metadata": null,
        "primary_lang": "ID",
        "duration": 113,
        "default_option": "",
        "option": [],
        "metadata": {
          "is_endplot": true,
          "media": {
            "id": 1,
            "link": "https://www.pegadaian.co.id/produk/tabungan-emas",
            "button": {
              "text": "Nabung Sekarang",
              "text_color": "#ffffff",
              "color": "#004d43"
            },
            "banner": {
              "horizontal": "https://vod.tadotv.com/banner/horizontal/1562751350078-ep3ps.jpg",
              "vertical_long": "https://vod.tadotv.com/banner/vertical_long/1562751851664-OFuwT.jpg",
              "vertical": "https://vod.tadotv.com/banner/vertical_regular/1563345607635-mdV52.jpg",
              "vertical_time": {
                "start": 112,
                "end": 0
              },
              "vertical_long_time": {
                "start": 112,
                "end": 0
              },
              "horizontal_time": {
                "start": 112,
                "end": 0
              }
            }
          }
        }
      },
      {
        "id": 3062,
        "title": "",
        "clip_title": "Coba",
        "subtitle": [],
        "url": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_anon_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
        "url_resource": {
          "auto": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_auto_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
          "anon": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_anon_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
          "low": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_low_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
          "basic": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_basic_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
          "high": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_high_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
          "ultra": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_ultra_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8"
        },
        "bridge_id": "",
        "bridge_url": "",
        "bridge_url_resource": "",
        "bridge_subtitle": [],
        "bridge_metadata": null,
        "primary_lang": "ID",
        "duration": 188,
        "default_option": "",
        "option": [],
        "metadata": {
          "is_endplot": true,
          "media": {
            "id": 1,
            "link": "https://www.pegadaian.co.id/produk/tabungan-emas",
            "button": {
              "text": "Nabung Sekarang",
              "text_color": "#ffffff",
              "color": "#004d43"
            },
            "banner": {
              "horizontal": "https://vod.tadotv.com/banner/horizontal/1562751350078-ep3ps.jpg",
              "vertical_long": "https://vod.tadotv.com/banner/vertical_long/1562751851664-OFuwT.jpg",
              "vertical": "https://vod.tadotv.com/banner/vertical_regular/1563345607635-mdV52.jpg",
              "vertical_time": {
                "start": 140,
                "end": 0
              },
              "vertical_long_time": {
                "start": 140,
                "end": 0
              },
              "horizontal_time": {
                "start": 140,
                "end": 0
              }
            }
          }
        }
      },
      {
        "id": 3063,
        "title": "",
        "clip_title": "Jangan",
        "subtitle": [],
        "url": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_anon_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
        "url_resource": {
          "auto": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_auto_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
          "anon": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_anon_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
          "low": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_low_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
          "basic": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_basic_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
          "high": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_high_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
          "ultra": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_ultra_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8"
        },
        "bridge_id": "",
        "bridge_url": "",
        "bridge_url_resource": "",
        "bridge_subtitle": [],
        "bridge_metadata": null,
        "primary_lang": "ID",
        "duration": 113,
        "default_option": "",
        "option": [],
        "metadata": {
          "is_endplot": true,
          "media": {
            "id": 1,
            "link": "https://www.pegadaian.co.id/produk/tabungan-emas",
            "button": {
              "text": "Nabung Sekarang",
              "text_color": "#ffffff",
              "color": "#004d43"
            },
            "banner": {
              "horizontal": "https://vod.tadotv.com/banner/horizontal/1562751350078-ep3ps.jpg",
              "vertical_long": "https://vod.tadotv.com/banner/vertical_long/1562751851664-OFuwT.jpg",
              "vertical": "https://vod.tadotv.com/banner/vertical_regular/1563345607635-mdV52.jpg",
              "vertical_time": {
                "start": 112,
                "end": 0
              },
              "vertical_long_time": {
                "start": 112,
                "end": 0
              },
              "horizontal_time": {
                "start": 112,
                "end": 0
              }
            }
          }
        }
      },
      {
        "id": 3064,
        "title": "",
        "clip_title": "Coba",
        "subtitle": [],
        "url": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_anon_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
        "url_resource": {
          "auto": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_auto_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
          "anon": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_anon_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
          "low": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_low_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
          "basic": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_basic_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
          "high": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_high_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
          "ultra": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_ultra_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8"
        },
        "bridge_id": "",
        "bridge_url": "",
        "bridge_url_resource": "",
        "bridge_subtitle": [],
        "bridge_metadata": null,
        "primary_lang": "ID",
        "duration": 188,
        "default_option": "",
        "option": [],
        "metadata": {
          "is_endplot": true,
          "media": {
            "id": 2,
            "link": "https://www.pegadaian.co.id/produk/tabungan-emas",
            "button": {
              "text": "Nabung Sekarang",
              "text_color": "#ffffff",
              "color": "#004d43"
            },
            "banner": {
              "horizontal": "https://vod.tadotv.com/banner/horizontal/1562751350078-ep3ps.jpg",
              "vertical_long": "https://vod.tadotv.com/banner/vertical_long/1562751851664-OFuwT.jpg",
              "vertical": "https://vod.tadotv.com/banner/vertical_regular/1563345607635-mdV52.jpg",
              "vertical_long_time": {
                "start": 140,
                "end": 0
              },
              "horizontal_time": {
                "start": 140,
                "end": 0
              },
              "vertical_time": {
                "start": 140,
                "end": 0
              }
            }
          }
        }
      },
      {
        "id": 3065,
        "title": "",
        "clip_title": "Jangan",
        "subtitle": [],
        "url": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_anon_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
        "url_resource": {
          "auto": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_auto_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
          "anon": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_anon_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
          "low": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_low_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
          "basic": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_basic_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
          "high": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_high_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
          "ultra": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_ultra_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8"
        },
        "bridge_id": "",
        "bridge_url": "",
        "bridge_url_resource": "",
        "bridge_subtitle": [],
        "bridge_metadata": null,
        "primary_lang": "ID",
        "duration": 113,
        "default_option": "",
        "option": [],
        "metadata": {
          "is_endplot": true,
          "media": {
            "id": 1,
            "link": "https://www.pegadaian.co.id/produk/tabungan-emas",
            "button": {
              "text": "Nabung Sekarang",
              "text_color": "#ffffff",
              "color": "#004d43"
            },
            "banner": {
              "horizontal": "https://vod.tadotv.com/banner/horizontal/1562751350078-ep3ps.jpg",
              "vertical_long": "https://vod.tadotv.com/banner/vertical_long/1562751851664-OFuwT.jpg",
              "vertical": "https://vod.tadotv.com/banner/vertical_regular/1563345607635-mdV52.jpg",
              "vertical_time": {
                "start": 112,
                "end": 0
              },
              "vertical_long_time": {
                "start": 112,
                "end": 0
              },
              "horizontal_time": {
                "start": 112,
                "end": 0
              }
            }
          }
        }
      },
      {
        "id": 3066,
        "title": {
          "ID": "Investasi apa yang akan dicoba?"
        },
        "clip_title": "Ngobrol dengan Reno",
        "subtitle": [],
        "url": "https://vod.tadotv.com/result/rs_1562261560618jwPNsuqf_1562261546627-41h8z/master_anon_rs_1562261560618jwPNsuqf_1562261546627-41h8z.m3u8",
        "url_resource": {
          "auto": "https://vod.tadotv.com/result/rs_1562261560618jwPNsuqf_1562261546627-41h8z/master_auto_rs_1562261560618jwPNsuqf_1562261546627-41h8z.m3u8",
          "anon": "https://vod.tadotv.com/result/rs_1562261560618jwPNsuqf_1562261546627-41h8z/master_anon_rs_1562261560618jwPNsuqf_1562261546627-41h8z.m3u8",
          "low": "https://vod.tadotv.com/result/rs_1562261560618jwPNsuqf_1562261546627-41h8z/master_low_rs_1562261560618jwPNsuqf_1562261546627-41h8z.m3u8",
          "basic": "https://vod.tadotv.com/result/rs_1562261560618jwPNsuqf_1562261546627-41h8z/master_basic_rs_1562261560618jwPNsuqf_1562261546627-41h8z.m3u8",
          "high": "https://vod.tadotv.com/result/rs_1562261560618jwPNsuqf_1562261546627-41h8z/master_high_rs_1562261560618jwPNsuqf_1562261546627-41h8z.m3u8",
          "ultra": "https://vod.tadotv.com/result/rs_1562261560618jwPNsuqf_1562261546627-41h8z/master_ultra_rs_1562261560618jwPNsuqf_1562261546627-41h8z.m3u8"
        },
        "bridge_id": 3068,
        "bridge_url": "https://vod.tadotv.com/result/rs_1562262805865Hri8COxE_1562262794549-MYlf1/master_anon_rs_1562262805865Hri8COxE_1562262794549-MYlf1.m3u8",
        "bridge_url_resource": {
          "auto": "https://vod.tadotv.com/result/rs_1562262805865Hri8COxE_1562262794549-MYlf1/master_auto_rs_1562262805865Hri8COxE_1562262794549-MYlf1.m3u8",
          "anon": "https://vod.tadotv.com/result/rs_1562262805865Hri8COxE_1562262794549-MYlf1/master_anon_rs_1562262805865Hri8COxE_1562262794549-MYlf1.m3u8",
          "low": "https://vod.tadotv.com/result/rs_1562262805865Hri8COxE_1562262794549-MYlf1/master_low_rs_1562262805865Hri8COxE_1562262794549-MYlf1.m3u8",
          "basic": "https://vod.tadotv.com/result/rs_1562262805865Hri8COxE_1562262794549-MYlf1/master_basic_rs_1562262805865Hri8COxE_1562262794549-MYlf1.m3u8",
          "high": "https://vod.tadotv.com/result/rs_1562262805865Hri8COxE_1562262794549-MYlf1/master_high_rs_1562262805865Hri8COxE_1562262794549-MYlf1.m3u8",
          "ultra": "https://vod.tadotv.com/result/rs_1562262805865Hri8COxE_1562262794549-MYlf1/master_ultra_rs_1562262805865Hri8COxE_1562262794549-MYlf1.m3u8"
        },
        "bridge_subtitle": [],
        "bridge_metadata": {
          "media": {
            "id": 3,
            "link": "https://www.pegadaian.co.id/produk/tabungan-emas",
            "button": {
              "text": "Nabung Sekarang",
              "text_color": "#ffffff",
              "color": "#004d43"
            },
            "banner": {
              "horizontal": "https://vod.tadotv.com/banner/horizontal/1562751350078-ep3ps.jpg",
              "vertical_long": null,
              "vertical": null,
              "horizontal_time": {
                "start": 1,
                "end": 0
              }
            }
          }
        },
        "primary_lang": "ID",
        "duration": 378,
        "default_option": 3070,
        "option": [
          {
            "id": 3070,
            "title": {
              "ID": "Investasi properti"
            },
            "duration": 58,
            "locked": 0
          },
          {
            "id": 3071,
            "title": {
              "ID": "Investasi ke teman"
            },
            "duration": 122,
            "locked": 0
          }
        ],
        "metadata": {
          "is_endplot": false,
          "media": null
        }
      },
      {
        "id": 3067,
        "title": {
          "ID": "Investasi apa yang akan dicoba?"
        },
        "clip_title": "Tinggalkan Reno",
        "subtitle": [],
        "url": "https://vod.tadotv.com/result/rs_1575441917991W4i2FyPh_1562261548116-jljAo/master_anon_rs_1575441917991W4i2FyPh_1562261548116-jljAo.m3u8",
        "url_resource": {
          "auto": "https://vod.tadotv.com/result/rs_1575441917991W4i2FyPh_1562261548116-jljAo/master_auto_rs_1575441917991W4i2FyPh_1562261548116-jljAo.m3u8",
          "anon": "https://vod.tadotv.com/result/rs_1575441917991W4i2FyPh_1562261548116-jljAo/master_anon_rs_1575441917991W4i2FyPh_1562261548116-jljAo.m3u8",
          "low": "https://vod.tadotv.com/result/rs_1575441917991W4i2FyPh_1562261548116-jljAo/master_low_rs_1575441917991W4i2FyPh_1562261548116-jljAo.m3u8",
          "basic": "https://vod.tadotv.com/result/rs_1575441917991W4i2FyPh_1562261548116-jljAo/master_basic_rs_1575441917991W4i2FyPh_1562261548116-jljAo.m3u8",
          "high": "https://vod.tadotv.com/result/rs_1575441917991W4i2FyPh_1562261548116-jljAo/master_high_rs_1575441917991W4i2FyPh_1562261548116-jljAo.m3u8",
          "ultra": "https://vod.tadotv.com/result/rs_1575441917991W4i2FyPh_1562261548116-jljAo/master_ultra_rs_1575441917991W4i2FyPh_1562261548116-jljAo.m3u8"
        },
        "bridge_id": 3069,
        "bridge_url": "https://vod.tadotv.com/result/rs_1562262805879GTmpd6pc_1562262794590-uNSNw/master_anon_rs_1562262805879GTmpd6pc_1562262794590-uNSNw.m3u8",
        "bridge_url_resource": {
          "auto": "https://vod.tadotv.com/result/rs_1562262805879GTmpd6pc_1562262794590-uNSNw/master_auto_rs_1562262805879GTmpd6pc_1562262794590-uNSNw.m3u8",
          "anon": "https://vod.tadotv.com/result/rs_1562262805879GTmpd6pc_1562262794590-uNSNw/master_anon_rs_1562262805879GTmpd6pc_1562262794590-uNSNw.m3u8",
          "low": "https://vod.tadotv.com/result/rs_1562262805879GTmpd6pc_1562262794590-uNSNw/master_low_rs_1562262805879GTmpd6pc_1562262794590-uNSNw.m3u8",
          "basic": "https://vod.tadotv.com/result/rs_1562262805879GTmpd6pc_1562262794590-uNSNw/master_basic_rs_1562262805879GTmpd6pc_1562262794590-uNSNw.m3u8",
          "high": "https://vod.tadotv.com/result/rs_1562262805879GTmpd6pc_1562262794590-uNSNw/master_high_rs_1562262805879GTmpd6pc_1562262794590-uNSNw.m3u8",
          "ultra": "https://vod.tadotv.com/result/rs_1562262805879GTmpd6pc_1562262794590-uNSNw/master_ultra_rs_1562262805879GTmpd6pc_1562262794590-uNSNw.m3u8"
        },
        "bridge_subtitle": [],
        "bridge_metadata": {
          "media": {
            "id": 3,
            "link": "https://www.pegadaian.co.id/produk/tabungan-emas",
            "button": {
              "text": "Nabung Sekarang",
              "text_color": "#ffffff",
              "color": "#004d43"
            },
            "banner": {
              "horizontal": "https://vod.tadotv.com/banner/horizontal/1562751350078-ep3ps.jpg",
              "vertical_long": null,
              "vertical": null,
              "horizontal_time": {
                "start": 1,
                "end": 0
              }
            }
          }
        },
        "primary_lang": "ID",
        "duration": 341,
        "default_option": 3072,
        "option": [
          {
            "id": 3072,
            "title": {
              "ID": "Investasi properti"
            },
            "duration": 58,
            "locked": 0
          },
          {
            "id": 3073,
            "title": {
              "ID": "Investasi ke teman"
            },
            "duration": 122,
            "locked": 0
          }
        ],
        "metadata": {
          "is_endplot": false,
          "media": null
        }
      },
      {
        "id": 3070,
        "title": {
          "ID": "Apa lagi sih si Reno ini?"
        },
        "clip_title": "Investasi properti",
        "subtitle": [],
        "url": "https://vod.tadotv.com/result/rs_1562262055509zzdwPFQ4_1562262040192-HIhyl/master_anon_rs_1562262055509zzdwPFQ4_1562262040192-HIhyl.m3u8",
        "url_resource": {
          "auto": "https://vod.tadotv.com/result/rs_1562262055509zzdwPFQ4_1562262040192-HIhyl/master_auto_rs_1562262055509zzdwPFQ4_1562262040192-HIhyl.m3u8",
          "anon": "https://vod.tadotv.com/result/rs_1562262055509zzdwPFQ4_1562262040192-HIhyl/master_anon_rs_1562262055509zzdwPFQ4_1562262040192-HIhyl.m3u8",
          "low": "https://vod.tadotv.com/result/rs_1562262055509zzdwPFQ4_1562262040192-HIhyl/master_low_rs_1562262055509zzdwPFQ4_1562262040192-HIhyl.m3u8",
          "basic": "https://vod.tadotv.com/result/rs_1562262055509zzdwPFQ4_1562262040192-HIhyl/master_basic_rs_1562262055509zzdwPFQ4_1562262040192-HIhyl.m3u8",
          "high": "https://vod.tadotv.com/result/rs_1562262055509zzdwPFQ4_1562262040192-HIhyl/master_high_rs_1562262055509zzdwPFQ4_1562262040192-HIhyl.m3u8",
          "ultra": "https://vod.tadotv.com/result/rs_1562262055509zzdwPFQ4_1562262040192-HIhyl/master_ultra_rs_1562262055509zzdwPFQ4_1562262040192-HIhyl.m3u8"
        },
        "bridge_id": 3074,
        "bridge_url": "https://vod.tadotv.com/result/rs_1562262865604iF1ow6ez_1562262854375-zp4kw/master_anon_rs_1562262865604iF1ow6ez_1562262854375-zp4kw.m3u8",
        "bridge_url_resource": {
          "auto": "https://vod.tadotv.com/result/rs_1562262865604iF1ow6ez_1562262854375-zp4kw/master_auto_rs_1562262865604iF1ow6ez_1562262854375-zp4kw.m3u8",
          "anon": "https://vod.tadotv.com/result/rs_1562262865604iF1ow6ez_1562262854375-zp4kw/master_anon_rs_1562262865604iF1ow6ez_1562262854375-zp4kw.m3u8",
          "low": "https://vod.tadotv.com/result/rs_1562262865604iF1ow6ez_1562262854375-zp4kw/master_low_rs_1562262865604iF1ow6ez_1562262854375-zp4kw.m3u8",
          "basic": "https://vod.tadotv.com/result/rs_1562262865604iF1ow6ez_1562262854375-zp4kw/master_basic_rs_1562262865604iF1ow6ez_1562262854375-zp4kw.m3u8",
          "high": "https://vod.tadotv.com/result/rs_1562262865604iF1ow6ez_1562262854375-zp4kw/master_high_rs_1562262865604iF1ow6ez_1562262854375-zp4kw.m3u8",
          "ultra": "https://vod.tadotv.com/result/rs_1562262865604iF1ow6ez_1562262854375-zp4kw/master_ultra_rs_1562262865604iF1ow6ez_1562262854375-zp4kw.m3u8"
        },
        "bridge_subtitle": [],
        "bridge_metadata": {
          "media": {
            "id": 3,
            "link": "https://www.pegadaian.co.id/produk/tabungan-emas",
            "button": {
              "text": "Nabung Sekarang",
              "text_color": "#ffffff",
              "color": "#004d43"
            },
            "banner": {
              "horizontal": "https://vod.tadotv.com/banner/horizontal/1562751350078-ep3ps.jpg",
              "vertical_long": null,
              "vertical": null,
              "horizontal_time": {
                "start": 1,
                "end": 0
              }
            }
          }
        },
        "primary_lang": "ID",
        "duration": 58,
        "default_option": 3078,
        "option": [
          {
            "id": 3078,
            "title": {
              "ID": "Tunggu Reno"
            },
            "duration": 198,
            "locked": 0
          },
          {
            "id": 3079,
            "title": {
              "ID": "Tinggalkan Reno"
            },
            "duration": 191,
            "locked": 0
          }
        ],
        "metadata": {
          "is_endplot": false,
          "media": null
        }
      },
      {
        "id": 3071,
        "title": {
          "ID": "Apa lagi sih si Reno ini?"
        },
        "clip_title": "Investasi ke teman",
        "subtitle": [],
        "url": "https://vod.tadotv.com/result/rs_1562262055513kAnybH8p_1562262041199-CVQZp/master_anon_rs_1562262055513kAnybH8p_1562262041199-CVQZp.m3u8",
        "url_resource": {
          "auto": "https://vod.tadotv.com/result/rs_1562262055513kAnybH8p_1562262041199-CVQZp/master_auto_rs_1562262055513kAnybH8p_1562262041199-CVQZp.m3u8",
          "anon": "https://vod.tadotv.com/result/rs_1562262055513kAnybH8p_1562262041199-CVQZp/master_anon_rs_1562262055513kAnybH8p_1562262041199-CVQZp.m3u8",
          "low": "https://vod.tadotv.com/result/rs_1562262055513kAnybH8p_1562262041199-CVQZp/master_low_rs_1562262055513kAnybH8p_1562262041199-CVQZp.m3u8",
          "basic": "https://vod.tadotv.com/result/rs_1562262055513kAnybH8p_1562262041199-CVQZp/master_basic_rs_1562262055513kAnybH8p_1562262041199-CVQZp.m3u8",
          "high": "https://vod.tadotv.com/result/rs_1562262055513kAnybH8p_1562262041199-CVQZp/master_high_rs_1562262055513kAnybH8p_1562262041199-CVQZp.m3u8",
          "ultra": "https://vod.tadotv.com/result/rs_1562262055513kAnybH8p_1562262041199-CVQZp/master_ultra_rs_1562262055513kAnybH8p_1562262041199-CVQZp.m3u8"
        },
        "bridge_id": 3075,
        "bridge_url": "https://vod.tadotv.com/result/rs_1562262865640rpZoiUjx_1562262854414-Dddz9/master_anon_rs_1562262865640rpZoiUjx_1562262854414-Dddz9.m3u8",
        "bridge_url_resource": {
          "auto": "https://vod.tadotv.com/result/rs_1562262865640rpZoiUjx_1562262854414-Dddz9/master_auto_rs_1562262865640rpZoiUjx_1562262854414-Dddz9.m3u8",
          "anon": "https://vod.tadotv.com/result/rs_1562262865640rpZoiUjx_1562262854414-Dddz9/master_anon_rs_1562262865640rpZoiUjx_1562262854414-Dddz9.m3u8",
          "low": "https://vod.tadotv.com/result/rs_1562262865640rpZoiUjx_1562262854414-Dddz9/master_low_rs_1562262865640rpZoiUjx_1562262854414-Dddz9.m3u8",
          "basic": "https://vod.tadotv.com/result/rs_1562262865640rpZoiUjx_1562262854414-Dddz9/master_basic_rs_1562262865640rpZoiUjx_1562262854414-Dddz9.m3u8",
          "high": "https://vod.tadotv.com/result/rs_1562262865640rpZoiUjx_1562262854414-Dddz9/master_high_rs_1562262865640rpZoiUjx_1562262854414-Dddz9.m3u8",
          "ultra": "https://vod.tadotv.com/result/rs_1562262865640rpZoiUjx_1562262854414-Dddz9/master_ultra_rs_1562262865640rpZoiUjx_1562262854414-Dddz9.m3u8"
        },
        "bridge_subtitle": [],
        "bridge_metadata": {
          "media": {
            "id": 3,
            "link": "https://www.pegadaian.co.id/produk/tabungan-emas",
            "button": {
              "text": "Nabung Sekarang",
              "text_color": "#ffffff",
              "color": "#004d43"
            },
            "banner": {
              "horizontal": "https://vod.tadotv.com/banner/horizontal/1562751350078-ep3ps.jpg",
              "vertical_long": null,
              "vertical": null,
              "horizontal_time": {
                "start": 1,
                "end": 0
              }
            }
          }
        },
        "primary_lang": "ID",
        "duration": 122,
        "default_option": 3080,
        "option": [
          {
            "id": 3080,
            "title": {
              "ID": "Tunggu Reno"
            },
            "duration": 198,
            "locked": 0
          },
          {
            "id": 3081,
            "title": {
              "ID": "Tinggalkan Reno"
            },
            "duration": 191,
            "locked": 0
          }
        ],
        "metadata": {
          "is_endplot": false,
          "media": null
        }
      },
      {
        "id": 3072,
        "title": {
          "ID": "Apa lagi sih si Reno ini?"
        },
        "clip_title": "Investasi properti",
        "subtitle": [],
        "url": "https://vod.tadotv.com/result/rs_1562262055509zzdwPFQ4_1562262040192-HIhyl/master_anon_rs_1562262055509zzdwPFQ4_1562262040192-HIhyl.m3u8",
        "url_resource": {
          "auto": "https://vod.tadotv.com/result/rs_1562262055509zzdwPFQ4_1562262040192-HIhyl/master_auto_rs_1562262055509zzdwPFQ4_1562262040192-HIhyl.m3u8",
          "anon": "https://vod.tadotv.com/result/rs_1562262055509zzdwPFQ4_1562262040192-HIhyl/master_anon_rs_1562262055509zzdwPFQ4_1562262040192-HIhyl.m3u8",
          "low": "https://vod.tadotv.com/result/rs_1562262055509zzdwPFQ4_1562262040192-HIhyl/master_low_rs_1562262055509zzdwPFQ4_1562262040192-HIhyl.m3u8",
          "basic": "https://vod.tadotv.com/result/rs_1562262055509zzdwPFQ4_1562262040192-HIhyl/master_basic_rs_1562262055509zzdwPFQ4_1562262040192-HIhyl.m3u8",
          "high": "https://vod.tadotv.com/result/rs_1562262055509zzdwPFQ4_1562262040192-HIhyl/master_high_rs_1562262055509zzdwPFQ4_1562262040192-HIhyl.m3u8",
          "ultra": "https://vod.tadotv.com/result/rs_1562262055509zzdwPFQ4_1562262040192-HIhyl/master_ultra_rs_1562262055509zzdwPFQ4_1562262040192-HIhyl.m3u8"
        },
        "bridge_id": 3076,
        "bridge_url": "https://vod.tadotv.com/result/rs_1562262865604iF1ow6ez_1562262854375-zp4kw/master_anon_rs_1562262865604iF1ow6ez_1562262854375-zp4kw.m3u8",
        "bridge_url_resource": {
          "auto": "https://vod.tadotv.com/result/rs_1562262865604iF1ow6ez_1562262854375-zp4kw/master_auto_rs_1562262865604iF1ow6ez_1562262854375-zp4kw.m3u8",
          "anon": "https://vod.tadotv.com/result/rs_1562262865604iF1ow6ez_1562262854375-zp4kw/master_anon_rs_1562262865604iF1ow6ez_1562262854375-zp4kw.m3u8",
          "low": "https://vod.tadotv.com/result/rs_1562262865604iF1ow6ez_1562262854375-zp4kw/master_low_rs_1562262865604iF1ow6ez_1562262854375-zp4kw.m3u8",
          "basic": "https://vod.tadotv.com/result/rs_1562262865604iF1ow6ez_1562262854375-zp4kw/master_basic_rs_1562262865604iF1ow6ez_1562262854375-zp4kw.m3u8",
          "high": "https://vod.tadotv.com/result/rs_1562262865604iF1ow6ez_1562262854375-zp4kw/master_high_rs_1562262865604iF1ow6ez_1562262854375-zp4kw.m3u8",
          "ultra": "https://vod.tadotv.com/result/rs_1562262865604iF1ow6ez_1562262854375-zp4kw/master_ultra_rs_1562262865604iF1ow6ez_1562262854375-zp4kw.m3u8"
        },
        "bridge_subtitle": [],
        "bridge_metadata": {
          "media": {
            "id": 3,
            "link": "https://www.pegadaian.co.id/produk/tabungan-emas",
            "button": {
              "text": "Nabung Sekarang",
              "text_color": "#ffffff",
              "color": "#004d43"
            },
            "banner": {
              "horizontal": "https://vod.tadotv.com/banner/horizontal/1562751350078-ep3ps.jpg",
              "vertical_long": null,
              "vertical": null,
              "horizontal_time": {
                "start": 1,
                "end": 0
              }
            }
          }
        },
        "primary_lang": "ID",
        "duration": 58,
        "default_option": 3082,
        "option": [
          {
            "id": 3082,
            "title": {
              "ID": "Tunggu Reno"
            },
            "duration": 198,
            "locked": 0
          },
          {
            "id": 3083,
            "title": {
              "ID": "Tinggalkan Reno"
            },
            "duration": 191,
            "locked": 0
          }
        ],
        "metadata": {
          "is_endplot": false,
          "media": null
        }
      },
      {
        "id": 3073,
        "title": {
          "ID": "Apa lagi sih si Reno ini?"
        },
        "clip_title": "Investasi ke teman",
        "subtitle": [],
        "url": "https://vod.tadotv.com/result/rs_1562262055513kAnybH8p_1562262041199-CVQZp/master_anon_rs_1562262055513kAnybH8p_1562262041199-CVQZp.m3u8",
        "url_resource": {
          "auto": "https://vod.tadotv.com/result/rs_1562262055513kAnybH8p_1562262041199-CVQZp/master_auto_rs_1562262055513kAnybH8p_1562262041199-CVQZp.m3u8",
          "anon": "https://vod.tadotv.com/result/rs_1562262055513kAnybH8p_1562262041199-CVQZp/master_anon_rs_1562262055513kAnybH8p_1562262041199-CVQZp.m3u8",
          "low": "https://vod.tadotv.com/result/rs_1562262055513kAnybH8p_1562262041199-CVQZp/master_low_rs_1562262055513kAnybH8p_1562262041199-CVQZp.m3u8",
          "basic": "https://vod.tadotv.com/result/rs_1562262055513kAnybH8p_1562262041199-CVQZp/master_basic_rs_1562262055513kAnybH8p_1562262041199-CVQZp.m3u8",
          "high": "https://vod.tadotv.com/result/rs_1562262055513kAnybH8p_1562262041199-CVQZp/master_high_rs_1562262055513kAnybH8p_1562262041199-CVQZp.m3u8",
          "ultra": "https://vod.tadotv.com/result/rs_1562262055513kAnybH8p_1562262041199-CVQZp/master_ultra_rs_1562262055513kAnybH8p_1562262041199-CVQZp.m3u8"
        },
        "bridge_id": 3077,
        "bridge_url": "https://vod.tadotv.com/result/rs_1562262865640rpZoiUjx_1562262854414-Dddz9/master_anon_rs_1562262865640rpZoiUjx_1562262854414-Dddz9.m3u8",
        "bridge_url_resource": {
          "auto": "https://vod.tadotv.com/result/rs_1562262865640rpZoiUjx_1562262854414-Dddz9/master_auto_rs_1562262865640rpZoiUjx_1562262854414-Dddz9.m3u8",
          "anon": "https://vod.tadotv.com/result/rs_1562262865640rpZoiUjx_1562262854414-Dddz9/master_anon_rs_1562262865640rpZoiUjx_1562262854414-Dddz9.m3u8",
          "low": "https://vod.tadotv.com/result/rs_1562262865640rpZoiUjx_1562262854414-Dddz9/master_low_rs_1562262865640rpZoiUjx_1562262854414-Dddz9.m3u8",
          "basic": "https://vod.tadotv.com/result/rs_1562262865640rpZoiUjx_1562262854414-Dddz9/master_basic_rs_1562262865640rpZoiUjx_1562262854414-Dddz9.m3u8",
          "high": "https://vod.tadotv.com/result/rs_1562262865640rpZoiUjx_1562262854414-Dddz9/master_high_rs_1562262865640rpZoiUjx_1562262854414-Dddz9.m3u8",
          "ultra": "https://vod.tadotv.com/result/rs_1562262865640rpZoiUjx_1562262854414-Dddz9/master_ultra_rs_1562262865640rpZoiUjx_1562262854414-Dddz9.m3u8"
        },
        "bridge_subtitle": [],
        "bridge_metadata": {
          "media": {
            "id": 3,
            "link": "https://www.pegadaian.co.id/produk/tabungan-emas",
            "button": {
              "text": "Nabung Sekarang",
              "text_color": "#ffffff",
              "color": "#004d43"
            },
            "banner": {
              "horizontal": "https://vod.tadotv.com/banner/horizontal/1562751350078-ep3ps.jpg",
              "vertical_long": null,
              "vertical": null,
              "horizontal_time": {
                "start": 1,
                "end": 0
              }
            }
          }
        },
        "primary_lang": "ID",
        "duration": 122,
        "default_option": 3084,
        "option": [
          {
            "id": 3084,
            "title": {
              "ID": "Tunggu Reno"
            },
            "duration": 198,
            "locked": 0
          },
          {
            "id": 3085,
            "title": {
              "ID": "Tinggalkan Reno"
            },
            "duration": 191,
            "locked": 0
          }
        ],
        "metadata": {
          "is_endplot": false,
          "media": null
        }
      },
      {
        "id": 3078,
        "title": {
          "ID": "Coba atau jangan?"
        },
        "clip_title": "Tunggu Reno",
        "subtitle": [],
        "url": "https://vod.tadotv.com/result/rs_1575441917974ECXjLy4L_1562262210383-qVwnD/master_anon_rs_1575441917974ECXjLy4L_1562262210383-qVwnD.m3u8",
        "url_resource": {
          "auto": "https://vod.tadotv.com/result/rs_1575441917974ECXjLy4L_1562262210383-qVwnD/master_auto_rs_1575441917974ECXjLy4L_1562262210383-qVwnD.m3u8",
          "anon": "https://vod.tadotv.com/result/rs_1575441917974ECXjLy4L_1562262210383-qVwnD/master_anon_rs_1575441917974ECXjLy4L_1562262210383-qVwnD.m3u8",
          "low": "https://vod.tadotv.com/result/rs_1575441917974ECXjLy4L_1562262210383-qVwnD/master_low_rs_1575441917974ECXjLy4L_1562262210383-qVwnD.m3u8",
          "basic": "https://vod.tadotv.com/result/rs_1575441917974ECXjLy4L_1562262210383-qVwnD/master_basic_rs_1575441917974ECXjLy4L_1562262210383-qVwnD.m3u8",
          "high": "https://vod.tadotv.com/result/rs_1575441917974ECXjLy4L_1562262210383-qVwnD/master_high_rs_1575441917974ECXjLy4L_1562262210383-qVwnD.m3u8",
          "ultra": "https://vod.tadotv.com/result/rs_1575441917974ECXjLy4L_1562262210383-qVwnD/master_ultra_rs_1575441917974ECXjLy4L_1562262210383-qVwnD.m3u8"
        },
        "bridge_id": 3086,
        "bridge_url": "https://vod.tadotv.com/result/rs_1562262919785e1tB2Z0f_1562262908485-rKS8g/master_anon_rs_1562262919785e1tB2Z0f_1562262908485-rKS8g.m3u8",
        "bridge_url_resource": {
          "auto": "https://vod.tadotv.com/result/rs_1562262919785e1tB2Z0f_1562262908485-rKS8g/master_auto_rs_1562262919785e1tB2Z0f_1562262908485-rKS8g.m3u8",
          "anon": "https://vod.tadotv.com/result/rs_1562262919785e1tB2Z0f_1562262908485-rKS8g/master_anon_rs_1562262919785e1tB2Z0f_1562262908485-rKS8g.m3u8",
          "low": "https://vod.tadotv.com/result/rs_1562262919785e1tB2Z0f_1562262908485-rKS8g/master_low_rs_1562262919785e1tB2Z0f_1562262908485-rKS8g.m3u8",
          "basic": "https://vod.tadotv.com/result/rs_1562262919785e1tB2Z0f_1562262908485-rKS8g/master_basic_rs_1562262919785e1tB2Z0f_1562262908485-rKS8g.m3u8",
          "high": "https://vod.tadotv.com/result/rs_1562262919785e1tB2Z0f_1562262908485-rKS8g/master_high_rs_1562262919785e1tB2Z0f_1562262908485-rKS8g.m3u8",
          "ultra": "https://vod.tadotv.com/result/rs_1562262919785e1tB2Z0f_1562262908485-rKS8g/master_ultra_rs_1562262919785e1tB2Z0f_1562262908485-rKS8g.m3u8"
        },
        "bridge_subtitle": [],
        "bridge_metadata": {
          "media": {
            "id": 3,
            "link": "https://www.pegadaian.co.id/produk/tabungan-emas",
            "button": {
              "text": "Nabung Sekarang",
              "text_color": "#ffffff",
              "color": "#004d43"
            },
            "banner": {
              "horizontal": "https://vod.tadotv.com/banner/horizontal/1562751350078-ep3ps.jpg",
              "vertical_long": null,
              "vertical": null,
              "horizontal_time": {
                "start": 1,
                "end": 0
              }
            }
          }
        },
        "primary_lang": "ID",
        "duration": 198,
        "default_option": 3094,
        "option": [
          {
            "id": 3094,
            "title": {
              "ID": "Coba"
            },
            "duration": 188,
            "locked": 0
          },
          {
            "id": 3095,
            "title": {
              "ID": "Jangan"
            },
            "duration": 113,
            "locked": 0
          }
        ],
        "metadata": {
          "is_endplot": false,
          "media": null
        }
      },
      {
        "id": 3079,
        "title": {
          "ID": "Coba atau jangan?"
        },
        "clip_title": "Tinggalkan Reno",
        "subtitle": [],
        "url": "https://vod.tadotv.com/result/rs_1562262265369r3NdUd1v_1562262253857-YDru8/master_anon_rs_1562262265369r3NdUd1v_1562262253857-YDru8.m3u8",
        "url_resource": {
          "auto": "https://vod.tadotv.com/result/rs_1562262265369r3NdUd1v_1562262253857-YDru8/master_auto_rs_1562262265369r3NdUd1v_1562262253857-YDru8.m3u8",
          "anon": "https://vod.tadotv.com/result/rs_1562262265369r3NdUd1v_1562262253857-YDru8/master_anon_rs_1562262265369r3NdUd1v_1562262253857-YDru8.m3u8",
          "low": "https://vod.tadotv.com/result/rs_1562262265369r3NdUd1v_1562262253857-YDru8/master_low_rs_1562262265369r3NdUd1v_1562262253857-YDru8.m3u8",
          "basic": "https://vod.tadotv.com/result/rs_1562262265369r3NdUd1v_1562262253857-YDru8/master_basic_rs_1562262265369r3NdUd1v_1562262253857-YDru8.m3u8",
          "high": "https://vod.tadotv.com/result/rs_1562262265369r3NdUd1v_1562262253857-YDru8/master_high_rs_1562262265369r3NdUd1v_1562262253857-YDru8.m3u8",
          "ultra": "https://vod.tadotv.com/result/rs_1562262265369r3NdUd1v_1562262253857-YDru8/master_ultra_rs_1562262265369r3NdUd1v_1562262253857-YDru8.m3u8"
        },
        "bridge_id": 3087,
        "bridge_url": "https://vod.tadotv.com/result/rs_15622629197817I1zuJ9I_1562262908529-TIbQ2/master_anon_rs_15622629197817I1zuJ9I_1562262908529-TIbQ2.m3u8",
        "bridge_url_resource": {
          "auto": "https://vod.tadotv.com/result/rs_15622629197817I1zuJ9I_1562262908529-TIbQ2/master_auto_rs_15622629197817I1zuJ9I_1562262908529-TIbQ2.m3u8",
          "anon": "https://vod.tadotv.com/result/rs_15622629197817I1zuJ9I_1562262908529-TIbQ2/master_anon_rs_15622629197817I1zuJ9I_1562262908529-TIbQ2.m3u8",
          "low": "https://vod.tadotv.com/result/rs_15622629197817I1zuJ9I_1562262908529-TIbQ2/master_low_rs_15622629197817I1zuJ9I_1562262908529-TIbQ2.m3u8",
          "basic": "https://vod.tadotv.com/result/rs_15622629197817I1zuJ9I_1562262908529-TIbQ2/master_basic_rs_15622629197817I1zuJ9I_1562262908529-TIbQ2.m3u8",
          "high": "https://vod.tadotv.com/result/rs_15622629197817I1zuJ9I_1562262908529-TIbQ2/master_high_rs_15622629197817I1zuJ9I_1562262908529-TIbQ2.m3u8",
          "ultra": "https://vod.tadotv.com/result/rs_15622629197817I1zuJ9I_1562262908529-TIbQ2/master_ultra_rs_15622629197817I1zuJ9I_1562262908529-TIbQ2.m3u8"
        },
        "bridge_subtitle": [],
        "bridge_metadata": {
          "media": {
            "id": 3,
            "link": "https://www.pegadaian.co.id/produk/tabungan-emas",
            "button": {
              "text": "Nabung Sekarang",
              "text_color": "#ffffff",
              "color": "#004d43"
            },
            "banner": {
              "horizontal": "https://vod.tadotv.com/banner/horizontal/1562751350078-ep3ps.jpg",
              "vertical_long": null,
              "vertical": null,
              "horizontal_time": {
                "start": 1,
                "end": 0
              }
            }
          }
        },
        "primary_lang": "ID",
        "duration": 191,
        "default_option": 3096,
        "option": [
          {
            "id": 3096,
            "title": {
              "ID": "Coba"
            },
            "duration": 188,
            "locked": 0
          },
          {
            "id": 3097,
            "title": {
              "ID": "Jangan"
            },
            "duration": 113,
            "locked": 0
          }
        ],
        "metadata": {
          "is_endplot": false,
          "media": null
        }
      },
      {
        "id": 3080,
        "title": {
          "ID": "Coba atau jangan?"
        },
        "clip_title": "Tunggu Reno",
        "subtitle": [],
        "url": "https://vod.tadotv.com/result/rs_1575441917974ECXjLy4L_1562262210383-qVwnD/master_anon_rs_1575441917974ECXjLy4L_1562262210383-qVwnD.m3u8",
        "url_resource": {
          "auto": "https://vod.tadotv.com/result/rs_1575441917974ECXjLy4L_1562262210383-qVwnD/master_auto_rs_1575441917974ECXjLy4L_1562262210383-qVwnD.m3u8",
          "anon": "https://vod.tadotv.com/result/rs_1575441917974ECXjLy4L_1562262210383-qVwnD/master_anon_rs_1575441917974ECXjLy4L_1562262210383-qVwnD.m3u8",
          "low": "https://vod.tadotv.com/result/rs_1575441917974ECXjLy4L_1562262210383-qVwnD/master_low_rs_1575441917974ECXjLy4L_1562262210383-qVwnD.m3u8",
          "basic": "https://vod.tadotv.com/result/rs_1575441917974ECXjLy4L_1562262210383-qVwnD/master_basic_rs_1575441917974ECXjLy4L_1562262210383-qVwnD.m3u8",
          "high": "https://vod.tadotv.com/result/rs_1575441917974ECXjLy4L_1562262210383-qVwnD/master_high_rs_1575441917974ECXjLy4L_1562262210383-qVwnD.m3u8",
          "ultra": "https://vod.tadotv.com/result/rs_1575441917974ECXjLy4L_1562262210383-qVwnD/master_ultra_rs_1575441917974ECXjLy4L_1562262210383-qVwnD.m3u8"
        },
        "bridge_id": 3088,
        "bridge_url": "https://vod.tadotv.com/result/rs_1562262919785e1tB2Z0f_1562262908485-rKS8g/master_anon_rs_1562262919785e1tB2Z0f_1562262908485-rKS8g.m3u8",
        "bridge_url_resource": {
          "auto": "https://vod.tadotv.com/result/rs_1562262919785e1tB2Z0f_1562262908485-rKS8g/master_auto_rs_1562262919785e1tB2Z0f_1562262908485-rKS8g.m3u8",
          "anon": "https://vod.tadotv.com/result/rs_1562262919785e1tB2Z0f_1562262908485-rKS8g/master_anon_rs_1562262919785e1tB2Z0f_1562262908485-rKS8g.m3u8",
          "low": "https://vod.tadotv.com/result/rs_1562262919785e1tB2Z0f_1562262908485-rKS8g/master_low_rs_1562262919785e1tB2Z0f_1562262908485-rKS8g.m3u8",
          "basic": "https://vod.tadotv.com/result/rs_1562262919785e1tB2Z0f_1562262908485-rKS8g/master_basic_rs_1562262919785e1tB2Z0f_1562262908485-rKS8g.m3u8",
          "high": "https://vod.tadotv.com/result/rs_1562262919785e1tB2Z0f_1562262908485-rKS8g/master_high_rs_1562262919785e1tB2Z0f_1562262908485-rKS8g.m3u8",
          "ultra": "https://vod.tadotv.com/result/rs_1562262919785e1tB2Z0f_1562262908485-rKS8g/master_ultra_rs_1562262919785e1tB2Z0f_1562262908485-rKS8g.m3u8"
        },
        "bridge_subtitle": [],
        "bridge_metadata": {
          "media": {
            "id": 3,
            "link": "https://www.pegadaian.co.id/produk/tabungan-emas",
            "button": {
              "text": "Nabung Sekarang",
              "text_color": "#ffffff",
              "color": "#004d43"
            },
            "banner": {
              "horizontal": "https://vod.tadotv.com/banner/horizontal/1562751350078-ep3ps.jpg",
              "vertical_long": null,
              "vertical": null,
              "horizontal_time": {
                "start": 1,
                "end": 0
              }
            }
          }
        },
        "primary_lang": "ID",
        "duration": 198,
        "default_option": 3098,
        "option": [
          {
            "id": 3098,
            "title": {
              "ID": "Coba"
            },
            "duration": 188,
            "locked": 0
          },
          {
            "id": 3099,
            "title": {
              "ID": "Jangan"
            },
            "duration": 113,
            "locked": 0
          }
        ],
        "metadata": {
          "is_endplot": false,
          "media": null
        }
      },
      {
        "id": 3081,
        "title": {
          "ID": "Coba atau jangan?"
        },
        "clip_title": "Tinggalkan Reno",
        "subtitle": [],
        "url": "https://vod.tadotv.com/result/rs_1562262265369r3NdUd1v_1562262253857-YDru8/master_anon_rs_1562262265369r3NdUd1v_1562262253857-YDru8.m3u8",
        "url_resource": {
          "auto": "https://vod.tadotv.com/result/rs_1562262265369r3NdUd1v_1562262253857-YDru8/master_auto_rs_1562262265369r3NdUd1v_1562262253857-YDru8.m3u8",
          "anon": "https://vod.tadotv.com/result/rs_1562262265369r3NdUd1v_1562262253857-YDru8/master_anon_rs_1562262265369r3NdUd1v_1562262253857-YDru8.m3u8",
          "low": "https://vod.tadotv.com/result/rs_1562262265369r3NdUd1v_1562262253857-YDru8/master_low_rs_1562262265369r3NdUd1v_1562262253857-YDru8.m3u8",
          "basic": "https://vod.tadotv.com/result/rs_1562262265369r3NdUd1v_1562262253857-YDru8/master_basic_rs_1562262265369r3NdUd1v_1562262253857-YDru8.m3u8",
          "high": "https://vod.tadotv.com/result/rs_1562262265369r3NdUd1v_1562262253857-YDru8/master_high_rs_1562262265369r3NdUd1v_1562262253857-YDru8.m3u8",
          "ultra": "https://vod.tadotv.com/result/rs_1562262265369r3NdUd1v_1562262253857-YDru8/master_ultra_rs_1562262265369r3NdUd1v_1562262253857-YDru8.m3u8"
        },
        "bridge_id": 3089,
        "bridge_url": "https://vod.tadotv.com/result/rs_15622629197817I1zuJ9I_1562262908529-TIbQ2/master_anon_rs_15622629197817I1zuJ9I_1562262908529-TIbQ2.m3u8",
        "bridge_url_resource": {
          "auto": "https://vod.tadotv.com/result/rs_15622629197817I1zuJ9I_1562262908529-TIbQ2/master_auto_rs_15622629197817I1zuJ9I_1562262908529-TIbQ2.m3u8",
          "anon": "https://vod.tadotv.com/result/rs_15622629197817I1zuJ9I_1562262908529-TIbQ2/master_anon_rs_15622629197817I1zuJ9I_1562262908529-TIbQ2.m3u8",
          "low": "https://vod.tadotv.com/result/rs_15622629197817I1zuJ9I_1562262908529-TIbQ2/master_low_rs_15622629197817I1zuJ9I_1562262908529-TIbQ2.m3u8",
          "basic": "https://vod.tadotv.com/result/rs_15622629197817I1zuJ9I_1562262908529-TIbQ2/master_basic_rs_15622629197817I1zuJ9I_1562262908529-TIbQ2.m3u8",
          "high": "https://vod.tadotv.com/result/rs_15622629197817I1zuJ9I_1562262908529-TIbQ2/master_high_rs_15622629197817I1zuJ9I_1562262908529-TIbQ2.m3u8",
          "ultra": "https://vod.tadotv.com/result/rs_15622629197817I1zuJ9I_1562262908529-TIbQ2/master_ultra_rs_15622629197817I1zuJ9I_1562262908529-TIbQ2.m3u8"
        },
        "bridge_subtitle": [],
        "bridge_metadata": {
          "media": {
            "id": 3,
            "link": "https://www.pegadaian.co.id/produk/tabungan-emas",
            "button": {
              "text": "Nabung Sekarang",
              "text_color": "#ffffff",
              "color": "#004d43"
            },
            "banner": {
              "horizontal": "https://vod.tadotv.com/banner/horizontal/1562751350078-ep3ps.jpg",
              "vertical_long": null,
              "vertical": null,
              "horizontal_time": {
                "start": 1,
                "end": 0
              }
            }
          }
        },
        "primary_lang": "ID",
        "duration": 191,
        "default_option": 3100,
        "option": [
          {
            "id": 3100,
            "title": {
              "ID": "Coba"
            },
            "duration": 188,
            "locked": 0
          },
          {
            "id": 3101,
            "title": {
              "ID": "Jangan"
            },
            "duration": 113,
            "locked": 0
          }
        ],
        "metadata": {
          "is_endplot": false,
          "media": null
        }
      },
      {
        "id": 3082,
        "title": {
          "ID": "Coba atau jangan?"
        },
        "clip_title": "Tunggu Reno",
        "subtitle": [],
        "url": "https://vod.tadotv.com/result/rs_1575441917974ECXjLy4L_1562262210383-qVwnD/master_anon_rs_1575441917974ECXjLy4L_1562262210383-qVwnD.m3u8",
        "url_resource": {
          "auto": "https://vod.tadotv.com/result/rs_1575441917974ECXjLy4L_1562262210383-qVwnD/master_auto_rs_1575441917974ECXjLy4L_1562262210383-qVwnD.m3u8",
          "anon": "https://vod.tadotv.com/result/rs_1575441917974ECXjLy4L_1562262210383-qVwnD/master_anon_rs_1575441917974ECXjLy4L_1562262210383-qVwnD.m3u8",
          "low": "https://vod.tadotv.com/result/rs_1575441917974ECXjLy4L_1562262210383-qVwnD/master_low_rs_1575441917974ECXjLy4L_1562262210383-qVwnD.m3u8",
          "basic": "https://vod.tadotv.com/result/rs_1575441917974ECXjLy4L_1562262210383-qVwnD/master_basic_rs_1575441917974ECXjLy4L_1562262210383-qVwnD.m3u8",
          "high": "https://vod.tadotv.com/result/rs_1575441917974ECXjLy4L_1562262210383-qVwnD/master_high_rs_1575441917974ECXjLy4L_1562262210383-qVwnD.m3u8",
          "ultra": "https://vod.tadotv.com/result/rs_1575441917974ECXjLy4L_1562262210383-qVwnD/master_ultra_rs_1575441917974ECXjLy4L_1562262210383-qVwnD.m3u8"
        },
        "bridge_id": 3090,
        "bridge_url": "https://vod.tadotv.com/result/rs_1562262919785e1tB2Z0f_1562262908485-rKS8g/master_anon_rs_1562262919785e1tB2Z0f_1562262908485-rKS8g.m3u8",
        "bridge_url_resource": {
          "auto": "https://vod.tadotv.com/result/rs_1562262919785e1tB2Z0f_1562262908485-rKS8g/master_auto_rs_1562262919785e1tB2Z0f_1562262908485-rKS8g.m3u8",
          "anon": "https://vod.tadotv.com/result/rs_1562262919785e1tB2Z0f_1562262908485-rKS8g/master_anon_rs_1562262919785e1tB2Z0f_1562262908485-rKS8g.m3u8",
          "low": "https://vod.tadotv.com/result/rs_1562262919785e1tB2Z0f_1562262908485-rKS8g/master_low_rs_1562262919785e1tB2Z0f_1562262908485-rKS8g.m3u8",
          "basic": "https://vod.tadotv.com/result/rs_1562262919785e1tB2Z0f_1562262908485-rKS8g/master_basic_rs_1562262919785e1tB2Z0f_1562262908485-rKS8g.m3u8",
          "high": "https://vod.tadotv.com/result/rs_1562262919785e1tB2Z0f_1562262908485-rKS8g/master_high_rs_1562262919785e1tB2Z0f_1562262908485-rKS8g.m3u8",
          "ultra": "https://vod.tadotv.com/result/rs_1562262919785e1tB2Z0f_1562262908485-rKS8g/master_ultra_rs_1562262919785e1tB2Z0f_1562262908485-rKS8g.m3u8"
        },
        "bridge_subtitle": [],
        "bridge_metadata": {
          "media": {
            "id": 3,
            "link": "https://www.pegadaian.co.id/produk/tabungan-emas",
            "button": {
              "text": "Nabung Sekarang",
              "text_color": "#ffffff",
              "color": "#004d43"
            },
            "banner": {
              "horizontal": "https://vod.tadotv.com/banner/horizontal/1562751350078-ep3ps.jpg",
              "vertical_long": null,
              "vertical": null,
              "horizontal_time": {
                "start": 1,
                "end": 0
              }
            }
          }
        },
        "primary_lang": "ID",
        "duration": 198,
        "default_option": 3102,
        "option": [
          {
            "id": 3102,
            "title": {
              "ID": "Coba"
            },
            "duration": 188,
            "locked": 0
          },
          {
            "id": 3103,
            "title": {
              "ID": "Jangan"
            },
            "duration": 113,
            "locked": 0
          }
        ],
        "metadata": {
          "is_endplot": false,
          "media": null
        }
      },
      {
        "id": 3083,
        "title": {
          "ID": "Coba atau jangan?"
        },
        "clip_title": "Tinggalkan Reno",
        "subtitle": [],
        "url": "https://vod.tadotv.com/result/rs_1562262265369r3NdUd1v_1562262253857-YDru8/master_anon_rs_1562262265369r3NdUd1v_1562262253857-YDru8.m3u8",
        "url_resource": {
          "auto": "https://vod.tadotv.com/result/rs_1562262265369r3NdUd1v_1562262253857-YDru8/master_auto_rs_1562262265369r3NdUd1v_1562262253857-YDru8.m3u8",
          "anon": "https://vod.tadotv.com/result/rs_1562262265369r3NdUd1v_1562262253857-YDru8/master_anon_rs_1562262265369r3NdUd1v_1562262253857-YDru8.m3u8",
          "low": "https://vod.tadotv.com/result/rs_1562262265369r3NdUd1v_1562262253857-YDru8/master_low_rs_1562262265369r3NdUd1v_1562262253857-YDru8.m3u8",
          "basic": "https://vod.tadotv.com/result/rs_1562262265369r3NdUd1v_1562262253857-YDru8/master_basic_rs_1562262265369r3NdUd1v_1562262253857-YDru8.m3u8",
          "high": "https://vod.tadotv.com/result/rs_1562262265369r3NdUd1v_1562262253857-YDru8/master_high_rs_1562262265369r3NdUd1v_1562262253857-YDru8.m3u8",
          "ultra": "https://vod.tadotv.com/result/rs_1562262265369r3NdUd1v_1562262253857-YDru8/master_ultra_rs_1562262265369r3NdUd1v_1562262253857-YDru8.m3u8"
        },
        "bridge_id": 3091,
        "bridge_url": "https://vod.tadotv.com/result/rs_15622629197817I1zuJ9I_1562262908529-TIbQ2/master_anon_rs_15622629197817I1zuJ9I_1562262908529-TIbQ2.m3u8",
        "bridge_url_resource": {
          "auto": "https://vod.tadotv.com/result/rs_15622629197817I1zuJ9I_1562262908529-TIbQ2/master_auto_rs_15622629197817I1zuJ9I_1562262908529-TIbQ2.m3u8",
          "anon": "https://vod.tadotv.com/result/rs_15622629197817I1zuJ9I_1562262908529-TIbQ2/master_anon_rs_15622629197817I1zuJ9I_1562262908529-TIbQ2.m3u8",
          "low": "https://vod.tadotv.com/result/rs_15622629197817I1zuJ9I_1562262908529-TIbQ2/master_low_rs_15622629197817I1zuJ9I_1562262908529-TIbQ2.m3u8",
          "basic": "https://vod.tadotv.com/result/rs_15622629197817I1zuJ9I_1562262908529-TIbQ2/master_basic_rs_15622629197817I1zuJ9I_1562262908529-TIbQ2.m3u8",
          "high": "https://vod.tadotv.com/result/rs_15622629197817I1zuJ9I_1562262908529-TIbQ2/master_high_rs_15622629197817I1zuJ9I_1562262908529-TIbQ2.m3u8",
          "ultra": "https://vod.tadotv.com/result/rs_15622629197817I1zuJ9I_1562262908529-TIbQ2/master_ultra_rs_15622629197817I1zuJ9I_1562262908529-TIbQ2.m3u8"
        },
        "bridge_subtitle": [],
        "bridge_metadata": {
          "media": {
            "id": 3,
            "link": "https://www.pegadaian.co.id/produk/tabungan-emas",
            "button": {
              "text": "Nabung Sekarang",
              "text_color": "#ffffff",
              "color": "#004d43"
            },
            "banner": {
              "horizontal": "https://vod.tadotv.com/banner/horizontal/1562751350078-ep3ps.jpg",
              "vertical_long": null,
              "vertical": null,
              "horizontal_time": {
                "start": 1,
                "end": 0
              }
            }
          }
        },
        "primary_lang": "ID",
        "duration": 191,
        "default_option": 3104,
        "option": [
          {
            "id": 3104,
            "title": {
              "ID": "Coba"
            },
            "duration": 188,
            "locked": 0
          },
          {
            "id": 3105,
            "title": {
              "ID": "Jangan"
            },
            "duration": 113,
            "locked": 0
          }
        ],
        "metadata": {
          "is_endplot": false,
          "media": null
        }
      },
      {
        "id": 3084,
        "title": {
          "ID": "Coba atau jangan?"
        },
        "clip_title": "Tunggu Reno",
        "subtitle": [],
        "url": "https://vod.tadotv.com/result/rs_1575441917974ECXjLy4L_1562262210383-qVwnD/master_anon_rs_1575441917974ECXjLy4L_1562262210383-qVwnD.m3u8",
        "url_resource": {
          "auto": "https://vod.tadotv.com/result/rs_1575441917974ECXjLy4L_1562262210383-qVwnD/master_auto_rs_1575441917974ECXjLy4L_1562262210383-qVwnD.m3u8",
          "anon": "https://vod.tadotv.com/result/rs_1575441917974ECXjLy4L_1562262210383-qVwnD/master_anon_rs_1575441917974ECXjLy4L_1562262210383-qVwnD.m3u8",
          "low": "https://vod.tadotv.com/result/rs_1575441917974ECXjLy4L_1562262210383-qVwnD/master_low_rs_1575441917974ECXjLy4L_1562262210383-qVwnD.m3u8",
          "basic": "https://vod.tadotv.com/result/rs_1575441917974ECXjLy4L_1562262210383-qVwnD/master_basic_rs_1575441917974ECXjLy4L_1562262210383-qVwnD.m3u8",
          "high": "https://vod.tadotv.com/result/rs_1575441917974ECXjLy4L_1562262210383-qVwnD/master_high_rs_1575441917974ECXjLy4L_1562262210383-qVwnD.m3u8",
          "ultra": "https://vod.tadotv.com/result/rs_1575441917974ECXjLy4L_1562262210383-qVwnD/master_ultra_rs_1575441917974ECXjLy4L_1562262210383-qVwnD.m3u8"
        },
        "bridge_id": 3092,
        "bridge_url": "https://vod.tadotv.com/result/rs_1562262919785e1tB2Z0f_1562262908485-rKS8g/master_anon_rs_1562262919785e1tB2Z0f_1562262908485-rKS8g.m3u8",
        "bridge_url_resource": {
          "auto": "https://vod.tadotv.com/result/rs_1562262919785e1tB2Z0f_1562262908485-rKS8g/master_auto_rs_1562262919785e1tB2Z0f_1562262908485-rKS8g.m3u8",
          "anon": "https://vod.tadotv.com/result/rs_1562262919785e1tB2Z0f_1562262908485-rKS8g/master_anon_rs_1562262919785e1tB2Z0f_1562262908485-rKS8g.m3u8",
          "low": "https://vod.tadotv.com/result/rs_1562262919785e1tB2Z0f_1562262908485-rKS8g/master_low_rs_1562262919785e1tB2Z0f_1562262908485-rKS8g.m3u8",
          "basic": "https://vod.tadotv.com/result/rs_1562262919785e1tB2Z0f_1562262908485-rKS8g/master_basic_rs_1562262919785e1tB2Z0f_1562262908485-rKS8g.m3u8",
          "high": "https://vod.tadotv.com/result/rs_1562262919785e1tB2Z0f_1562262908485-rKS8g/master_high_rs_1562262919785e1tB2Z0f_1562262908485-rKS8g.m3u8",
          "ultra": "https://vod.tadotv.com/result/rs_1562262919785e1tB2Z0f_1562262908485-rKS8g/master_ultra_rs_1562262919785e1tB2Z0f_1562262908485-rKS8g.m3u8"
        },
        "bridge_subtitle": [],
        "bridge_metadata": {
          "media": {
            "id": 3,
            "link": "https://www.pegadaian.co.id/produk/tabungan-emas",
            "button": {
              "text": "Nabung Sekarang",
              "text_color": "#ffffff",
              "color": "#004d43"
            },
            "banner": {
              "horizontal": "https://vod.tadotv.com/banner/horizontal/1562751350078-ep3ps.jpg",
              "vertical_long": null,
              "vertical": null,
              "horizontal_time": {
                "start": 1,
                "end": 0
              }
            }
          }
        },
        "primary_lang": "ID",
        "duration": 198,
        "default_option": 3106,
        "option": [
          {
            "id": 3106,
            "title": {
              "ID": "Coba"
            },
            "duration": 188,
            "locked": 0
          },
          {
            "id": 3107,
            "title": {
              "ID": "Jangan"
            },
            "duration": 113,
            "locked": 0
          }
        ],
        "metadata": {
          "is_endplot": false,
          "media": null
        }
      },
      {
        "id": 3085,
        "title": {
          "ID": "Coba atau jangan?"
        },
        "clip_title": "Tinggalkan Reno",
        "subtitle": [],
        "url": "https://vod.tadotv.com/result/rs_1562262265369r3NdUd1v_1562262253857-YDru8/master_anon_rs_1562262265369r3NdUd1v_1562262253857-YDru8.m3u8",
        "url_resource": {
          "auto": "https://vod.tadotv.com/result/rs_1562262265369r3NdUd1v_1562262253857-YDru8/master_auto_rs_1562262265369r3NdUd1v_1562262253857-YDru8.m3u8",
          "anon": "https://vod.tadotv.com/result/rs_1562262265369r3NdUd1v_1562262253857-YDru8/master_anon_rs_1562262265369r3NdUd1v_1562262253857-YDru8.m3u8",
          "low": "https://vod.tadotv.com/result/rs_1562262265369r3NdUd1v_1562262253857-YDru8/master_low_rs_1562262265369r3NdUd1v_1562262253857-YDru8.m3u8",
          "basic": "https://vod.tadotv.com/result/rs_1562262265369r3NdUd1v_1562262253857-YDru8/master_basic_rs_1562262265369r3NdUd1v_1562262253857-YDru8.m3u8",
          "high": "https://vod.tadotv.com/result/rs_1562262265369r3NdUd1v_1562262253857-YDru8/master_high_rs_1562262265369r3NdUd1v_1562262253857-YDru8.m3u8",
          "ultra": "https://vod.tadotv.com/result/rs_1562262265369r3NdUd1v_1562262253857-YDru8/master_ultra_rs_1562262265369r3NdUd1v_1562262253857-YDru8.m3u8"
        },
        "bridge_id": 3093,
        "bridge_url": "https://vod.tadotv.com/result/rs_15622629197817I1zuJ9I_1562262908529-TIbQ2/master_anon_rs_15622629197817I1zuJ9I_1562262908529-TIbQ2.m3u8",
        "bridge_url_resource": {
          "auto": "https://vod.tadotv.com/result/rs_15622629197817I1zuJ9I_1562262908529-TIbQ2/master_auto_rs_15622629197817I1zuJ9I_1562262908529-TIbQ2.m3u8",
          "anon": "https://vod.tadotv.com/result/rs_15622629197817I1zuJ9I_1562262908529-TIbQ2/master_anon_rs_15622629197817I1zuJ9I_1562262908529-TIbQ2.m3u8",
          "low": "https://vod.tadotv.com/result/rs_15622629197817I1zuJ9I_1562262908529-TIbQ2/master_low_rs_15622629197817I1zuJ9I_1562262908529-TIbQ2.m3u8",
          "basic": "https://vod.tadotv.com/result/rs_15622629197817I1zuJ9I_1562262908529-TIbQ2/master_basic_rs_15622629197817I1zuJ9I_1562262908529-TIbQ2.m3u8",
          "high": "https://vod.tadotv.com/result/rs_15622629197817I1zuJ9I_1562262908529-TIbQ2/master_high_rs_15622629197817I1zuJ9I_1562262908529-TIbQ2.m3u8",
          "ultra": "https://vod.tadotv.com/result/rs_15622629197817I1zuJ9I_1562262908529-TIbQ2/master_ultra_rs_15622629197817I1zuJ9I_1562262908529-TIbQ2.m3u8"
        },
        "bridge_subtitle": [],
        "bridge_metadata": {
          "media": {
            "id": 3,
            "link": "https://www.pegadaian.co.id/produk/tabungan-emas",
            "button": {
              "text": "Nabung Sekarang",
              "text_color": "#ffffff",
              "color": "#004d43"
            },
            "banner": {
              "horizontal": "https://vod.tadotv.com/banner/horizontal/1562751350078-ep3ps.jpg",
              "vertical_long": null,
              "vertical": null,
              "horizontal_time": {
                "start": 1,
                "end": 0
              }
            }
          }
        },
        "primary_lang": "ID",
        "duration": 191,
        "default_option": 3108,
        "option": [
          {
            "id": 3108,
            "title": {
              "ID": "Coba"
            },
            "duration": 188,
            "locked": 0
          },
          {
            "id": 3109,
            "title": {
              "ID": "Jangan"
            },
            "duration": 113,
            "locked": 0
          }
        ],
        "metadata": {
          "is_endplot": false,
          "media": null
        }
      },
      {
        "id": 3094,
        "title": "",
        "clip_title": "Coba",
        "subtitle": [],
        "url": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_anon_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
        "url_resource": {
          "auto": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_auto_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
          "anon": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_anon_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
          "low": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_low_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
          "basic": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_basic_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
          "high": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_high_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
          "ultra": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_ultra_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8"
        },
        "bridge_id": "",
        "bridge_url": "",
        "bridge_url_resource": "",
        "bridge_subtitle": [],
        "bridge_metadata": null,
        "primary_lang": "ID",
        "duration": 188,
        "default_option": "",
        "option": [],
        "metadata": {
          "is_endplot": true,
          "media": {
            "id": 3,
            "link": "https://www.pegadaian.co.id/produk/tabungan-emas",
            "button": {
              "text": "Nabung Sekarang",
              "text_color": "#ffffff",
              "color": "#004d43"
            },
            "banner": {
              "horizontal": "https://vod.tadotv.com/banner/horizontal/1562751350078-ep3ps.jpg",
              "vertical_long": "https://vod.tadotv.com/banner/vertical_long/1562751851664-OFuwT.jpg",
              "vertical": "https://vod.tadotv.com/banner/vertical_regular/1563345607635-mdV52.jpg",
              "horizontal_time": {
                "start": 140,
                "end": 0
              },
              "vertical_long_time": {
                "start": 140,
                "end": 0
              },
              "vertical_time": {
                "start": 140,
                "end": 0
              }
            }
          }
        }
      },
      {
        "id": 3095,
        "title": "",
        "clip_title": "Jangan",
        "subtitle": [],
        "url": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_anon_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
        "url_resource": {
          "auto": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_auto_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
          "anon": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_anon_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
          "low": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_low_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
          "basic": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_basic_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
          "high": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_high_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
          "ultra": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_ultra_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8"
        },
        "bridge_id": "",
        "bridge_url": "",
        "bridge_url_resource": "",
        "bridge_subtitle": [],
        "bridge_metadata": null,
        "primary_lang": "ID",
        "duration": 113,
        "default_option": "",
        "option": [],
        "metadata": {
          "is_endplot": true,
          "media": {
            "id": 1,
            "link": "https://www.pegadaian.co.id/produk/tabungan-emas",
            "button": {
              "text": "Nabung Sekarang",
              "text_color": "#ffffff",
              "color": "#004d43"
            },
            "banner": {
              "horizontal": "https://vod.tadotv.com/banner/horizontal/1562751350078-ep3ps.jpg",
              "vertical_long": "https://vod.tadotv.com/banner/vertical_long/1562751851664-OFuwT.jpg",
              "vertical": "https://vod.tadotv.com/banner/vertical_regular/1563345607635-mdV52.jpg",
              "vertical_time": {
                "start": 112,
                "end": 0
              },
              "vertical_long_time": {
                "start": 112,
                "end": 0
              },
              "horizontal_time": {
                "start": 112,
                "end": 0
              }
            }
          }
        }
      },
      {
        "id": 3096,
        "title": "",
        "clip_title": "Coba",
        "subtitle": [],
        "url": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_anon_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
        "url_resource": {
          "auto": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_auto_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
          "anon": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_anon_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
          "low": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_low_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
          "basic": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_basic_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
          "high": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_high_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
          "ultra": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_ultra_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8"
        },
        "bridge_id": "",
        "bridge_url": "",
        "bridge_url_resource": "",
        "bridge_subtitle": [],
        "bridge_metadata": null,
        "primary_lang": "ID",
        "duration": 188,
        "default_option": "",
        "option": [],
        "metadata": {
          "is_endplot": true,
          "media": {
            "id": 3,
            "link": "https://www.pegadaian.co.id/produk/tabungan-emas",
            "button": {
              "text": "Nabung Sekarang",
              "text_color": "#ffffff",
              "color": "#004d43"
            },
            "banner": {
              "horizontal": "https://vod.tadotv.com/banner/horizontal/1562751350078-ep3ps.jpg",
              "vertical_long": "https://vod.tadotv.com/banner/vertical_long/1562751851664-OFuwT.jpg",
              "vertical": "https://vod.tadotv.com/banner/vertical_regular/1563345607635-mdV52.jpg",
              "horizontal_time": {
                "start": 140,
                "end": 0
              },
              "vertical_time": {
                "start": 140,
                "end": 0
              },
              "vertical_long_time": {
                "start": 140,
                "end": 0
              }
            }
          }
        }
      },
      {
        "id": 3097,
        "title": "",
        "clip_title": "Jangan",
        "subtitle": [],
        "url": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_anon_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
        "url_resource": {
          "auto": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_auto_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
          "anon": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_anon_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
          "low": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_low_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
          "basic": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_basic_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
          "high": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_high_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
          "ultra": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_ultra_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8"
        },
        "bridge_id": "",
        "bridge_url": "",
        "bridge_url_resource": "",
        "bridge_subtitle": [],
        "bridge_metadata": null,
        "primary_lang": "ID",
        "duration": 113,
        "default_option": "",
        "option": [],
        "metadata": {
          "is_endplot": true,
          "media": {
            "id": 1,
            "link": "https://www.pegadaian.co.id/produk/tabungan-emas",
            "button": {
              "text": "Nabung Sekarang",
              "text_color": "#ffffff",
              "color": "#004d43"
            },
            "banner": {
              "horizontal": "https://vod.tadotv.com/banner/horizontal/1562751350078-ep3ps.jpg",
              "vertical_long": "https://vod.tadotv.com/banner/vertical_long/1562751851664-OFuwT.jpg",
              "vertical": "https://vod.tadotv.com/banner/vertical_regular/1563345607635-mdV52.jpg",
              "vertical_time": {
                "start": 112,
                "end": 0
              },
              "vertical_long_time": {
                "start": 112,
                "end": 0
              },
              "horizontal_time": {
                "start": 112,
                "end": 0
              }
            }
          }
        }
      },
      {
        "id": 3098,
        "title": "",
        "clip_title": "Coba",
        "subtitle": [],
        "url": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_anon_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
        "url_resource": {
          "auto": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_auto_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
          "anon": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_anon_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
          "low": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_low_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
          "basic": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_basic_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
          "high": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_high_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
          "ultra": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_ultra_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8"
        },
        "bridge_id": "",
        "bridge_url": "",
        "bridge_url_resource": "",
        "bridge_subtitle": [],
        "bridge_metadata": null,
        "primary_lang": "ID",
        "duration": 188,
        "default_option": "",
        "option": [],
        "metadata": {
          "is_endplot": true,
          "media": {
            "id": 3,
            "link": "https://www.pegadaian.co.id/produk/tabungan-emas",
            "button": {
              "text": "Nabung Sekarang",
              "text_color": "#ffffff",
              "color": "#004d43"
            },
            "banner": {
              "horizontal": "https://vod.tadotv.com/banner/horizontal/1562751350078-ep3ps.jpg",
              "vertical_long": "https://vod.tadotv.com/banner/vertical_long/1562751851664-OFuwT.jpg",
              "vertical": "https://vod.tadotv.com/banner/vertical_regular/1563345607635-mdV52.jpg",
              "horizontal_time": {
                "start": 140,
                "end": 0
              },
              "vertical_long_time": {
                "start": 140,
                "end": 0
              },
              "vertical_time": {
                "start": 140,
                "end": 0
              }
            }
          }
        }
      },
      {
        "id": 3099,
        "title": "",
        "clip_title": "Jangan",
        "subtitle": [],
        "url": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_anon_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
        "url_resource": {
          "auto": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_auto_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
          "anon": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_anon_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
          "low": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_low_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
          "basic": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_basic_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
          "high": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_high_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
          "ultra": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_ultra_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8"
        },
        "bridge_id": "",
        "bridge_url": "",
        "bridge_url_resource": "",
        "bridge_subtitle": [],
        "bridge_metadata": null,
        "primary_lang": "ID",
        "duration": 113,
        "default_option": "",
        "option": [],
        "metadata": {
          "is_endplot": true,
          "media": {
            "id": 1,
            "link": "https://www.pegadaian.co.id/produk/tabungan-emas",
            "button": {
              "text": "Nabung Sekarang",
              "text_color": "#ffffff",
              "color": "#004d43"
            },
            "banner": {
              "horizontal": "https://vod.tadotv.com/banner/horizontal/1562751350078-ep3ps.jpg",
              "vertical_long": "https://vod.tadotv.com/banner/vertical_long/1562751851664-OFuwT.jpg",
              "vertical": "https://vod.tadotv.com/banner/vertical_regular/1563345607635-mdV52.jpg",
              "vertical_time": {
                "start": 112,
                "end": 0
              },
              "vertical_long_time": {
                "start": 112,
                "end": 0
              },
              "horizontal_time": {
                "start": 112,
                "end": 0
              }
            }
          }
        }
      },
      {
        "id": 3100,
        "title": "",
        "clip_title": "Coba",
        "subtitle": [],
        "url": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_anon_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
        "url_resource": {
          "auto": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_auto_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
          "anon": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_anon_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
          "low": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_low_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
          "basic": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_basic_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
          "high": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_high_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
          "ultra": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_ultra_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8"
        },
        "bridge_id": "",
        "bridge_url": "",
        "bridge_url_resource": "",
        "bridge_subtitle": [],
        "bridge_metadata": null,
        "primary_lang": "ID",
        "duration": 188,
        "default_option": "",
        "option": [],
        "metadata": {
          "is_endplot": true,
          "media": {
            "id": 1,
            "link": "https://www.pegadaian.co.id/produk/tabungan-emas",
            "button": {
              "text": "Nabung Sekarang",
              "text_color": "#ffffff",
              "color": "#004d43"
            },
            "banner": {
              "horizontal": "https://vod.tadotv.com/banner/horizontal/1562751350078-ep3ps.jpg",
              "vertical_long": "https://vod.tadotv.com/banner/vertical_long/1562751851664-OFuwT.jpg",
              "vertical": "https://vod.tadotv.com/banner/vertical_regular/1563345607635-mdV52.jpg",
              "vertical_time": {
                "start": 140,
                "end": 0
              },
              "vertical_long_time": {
                "start": 140,
                "end": 0
              },
              "horizontal_time": {
                "start": 140,
                "end": 0
              }
            }
          }
        }
      },
      {
        "id": 3101,
        "title": "",
        "clip_title": "Jangan",
        "subtitle": [],
        "url": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_anon_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
        "url_resource": {
          "auto": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_auto_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
          "anon": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_anon_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
          "low": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_low_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
          "basic": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_basic_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
          "high": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_high_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
          "ultra": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_ultra_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8"
        },
        "bridge_id": "",
        "bridge_url": "",
        "bridge_url_resource": "",
        "bridge_subtitle": [],
        "bridge_metadata": null,
        "primary_lang": "ID",
        "duration": 113,
        "default_option": "",
        "option": [],
        "metadata": {
          "is_endplot": true,
          "media": {
            "id": 1,
            "link": "https://www.pegadaian.co.id/produk/tabungan-emas",
            "button": {
              "text": "Nabung Sekarang",
              "text_color": "#ffffff",
              "color": "#004d43"
            },
            "banner": {
              "horizontal": "https://vod.tadotv.com/banner/horizontal/1562751350078-ep3ps.jpg",
              "vertical_long": "https://vod.tadotv.com/banner/vertical_long/1562751851664-OFuwT.jpg",
              "vertical": "https://vod.tadotv.com/banner/vertical_regular/1563345607635-mdV52.jpg",
              "vertical_time": {
                "start": 112,
                "end": 0
              },
              "vertical_long_time": {
                "start": 112,
                "end": 0
              },
              "horizontal_time": {
                "start": 112,
                "end": 0
              }
            }
          }
        }
      },
      {
        "id": 3102,
        "title": "",
        "clip_title": "Coba",
        "subtitle": [],
        "url": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_anon_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
        "url_resource": {
          "auto": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_auto_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
          "anon": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_anon_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
          "low": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_low_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
          "basic": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_basic_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
          "high": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_high_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
          "ultra": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_ultra_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8"
        },
        "bridge_id": "",
        "bridge_url": "",
        "bridge_url_resource": "",
        "bridge_subtitle": [],
        "bridge_metadata": null,
        "primary_lang": "ID",
        "duration": 188,
        "default_option": "",
        "option": [],
        "metadata": {
          "is_endplot": true,
          "media": {
            "id": 1,
            "link": "https://www.pegadaian.co.id/produk/tabungan-emas",
            "button": {
              "text": "Nabung Sekarang",
              "text_color": "#ffffff",
              "color": "#004d43"
            },
            "banner": {
              "horizontal": "https://vod.tadotv.com/banner/horizontal/1562751350078-ep3ps.jpg",
              "vertical_long": "https://vod.tadotv.com/banner/vertical_long/1562751851664-OFuwT.jpg",
              "vertical": "https://vod.tadotv.com/banner/vertical_regular/1563345607635-mdV52.jpg",
              "vertical_time": {
                "start": 140,
                "end": 0
              },
              "vertical_long_time": {
                "start": 140,
                "end": 0
              },
              "horizontal_time": {
                "start": 140,
                "end": 0
              }
            }
          }
        }
      },
      {
        "id": 3103,
        "title": "",
        "clip_title": "Jangan",
        "subtitle": [],
        "url": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_anon_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
        "url_resource": {
          "auto": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_auto_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
          "anon": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_anon_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
          "low": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_low_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
          "basic": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_basic_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
          "high": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_high_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
          "ultra": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_ultra_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8"
        },
        "bridge_id": "",
        "bridge_url": "",
        "bridge_url_resource": "",
        "bridge_subtitle": [],
        "bridge_metadata": null,
        "primary_lang": "ID",
        "duration": 113,
        "default_option": "",
        "option": [],
        "metadata": {
          "is_endplot": true,
          "media": {
            "id": 1,
            "link": "https://www.pegadaian.co.id/produk/tabungan-emas",
            "button": {
              "text": "Nabung Sekarang",
              "text_color": "#ffffff",
              "color": "#004d43"
            },
            "banner": {
              "horizontal": "https://vod.tadotv.com/banner/horizontal/1562751350078-ep3ps.jpg",
              "vertical_long": "https://vod.tadotv.com/banner/vertical_long/1562751851664-OFuwT.jpg",
              "vertical": "https://vod.tadotv.com/banner/vertical_regular/1563345607635-mdV52.jpg",
              "vertical_time": {
                "start": 112,
                "end": 0
              },
              "vertical_long_time": {
                "start": 112,
                "end": 0
              },
              "horizontal_time": {
                "start": 112,
                "end": 0
              }
            }
          }
        }
      },
      {
        "id": 3104,
        "title": "",
        "clip_title": "Coba",
        "subtitle": [],
        "url": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_anon_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
        "url_resource": {
          "auto": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_auto_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
          "anon": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_anon_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
          "low": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_low_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
          "basic": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_basic_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
          "high": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_high_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
          "ultra": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_ultra_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8"
        },
        "bridge_id": "",
        "bridge_url": "",
        "bridge_url_resource": "",
        "bridge_subtitle": [],
        "bridge_metadata": null,
        "primary_lang": "ID",
        "duration": 188,
        "default_option": "",
        "option": [],
        "metadata": {
          "is_endplot": true,
          "media": {
            "id": 1,
            "link": "https://www.pegadaian.co.id/produk/tabungan-emas",
            "button": {
              "text": "Nabung Sekarang",
              "text_color": "#ffffff",
              "color": "#004d43"
            },
            "banner": {
              "horizontal": "https://vod.tadotv.com/banner/horizontal/1562751350078-ep3ps.jpg",
              "vertical_long": "https://vod.tadotv.com/banner/vertical_long/1562751851664-OFuwT.jpg",
              "vertical": "https://vod.tadotv.com/banner/vertical_regular/1563345607635-mdV52.jpg",
              "vertical_time": {
                "start": 140,
                "end": 0
              },
              "vertical_long_time": {
                "start": 140,
                "end": 0
              },
              "horizontal_time": {
                "start": 140,
                "end": 0
              }
            }
          }
        }
      },
      {
        "id": 3105,
        "title": "",
        "clip_title": "Jangan",
        "subtitle": [],
        "url": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_anon_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
        "url_resource": {
          "auto": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_auto_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
          "anon": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_anon_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
          "low": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_low_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
          "basic": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_basic_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
          "high": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_high_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
          "ultra": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_ultra_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8"
        },
        "bridge_id": "",
        "bridge_url": "",
        "bridge_url_resource": "",
        "bridge_subtitle": [],
        "bridge_metadata": null,
        "primary_lang": "ID",
        "duration": 113,
        "default_option": "",
        "option": [],
        "metadata": {
          "is_endplot": true,
          "media": {
            "id": 1,
            "link": "https://www.pegadaian.co.id/produk/tabungan-emas",
            "button": {
              "text": "Nabung Sekarang",
              "text_color": "#ffffff",
              "color": "#004d43"
            },
            "banner": {
              "horizontal": "https://vod.tadotv.com/banner/horizontal/1562751350078-ep3ps.jpg",
              "vertical_long": "https://vod.tadotv.com/banner/vertical_long/1562751851664-OFuwT.jpg",
              "vertical": "https://vod.tadotv.com/banner/vertical_regular/1563345607635-mdV52.jpg",
              "vertical_time": {
                "start": 112,
                "end": 0
              },
              "vertical_long_time": {
                "start": 112,
                "end": 0
              },
              "horizontal_time": {
                "start": 112,
                "end": 0
              }
            }
          }
        }
      },
      {
        "id": 3106,
        "title": "",
        "clip_title": "Coba",
        "subtitle": [],
        "url": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_anon_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
        "url_resource": {
          "auto": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_auto_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
          "anon": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_anon_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
          "low": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_low_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
          "basic": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_basic_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
          "high": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_high_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
          "ultra": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_ultra_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8"
        },
        "bridge_id": "",
        "bridge_url": "",
        "bridge_url_resource": "",
        "bridge_subtitle": [],
        "bridge_metadata": null,
        "primary_lang": "ID",
        "duration": 188,
        "default_option": "",
        "option": [],
        "metadata": {
          "is_endplot": true,
          "media": {
            "id": 1,
            "link": "https://www.pegadaian.co.id/produk/tabungan-emas",
            "button": {
              "text": "Nabung Sekarang",
              "text_color": "#ffffff",
              "color": "#004d43"
            },
            "banner": {
              "horizontal": "https://vod.tadotv.com/banner/horizontal/1562751350078-ep3ps.jpg",
              "vertical_long": "https://vod.tadotv.com/banner/vertical_long/1562751851664-OFuwT.jpg",
              "vertical": "https://vod.tadotv.com/banner/vertical_regular/1563345607635-mdV52.jpg",
              "vertical_time": {
                "start": 140,
                "end": 0
              },
              "vertical_long_time": {
                "start": 140,
                "end": 0
              },
              "horizontal_time": {
                "start": 140,
                "end": 0
              }
            }
          }
        }
      },
      {
        "id": 3107,
        "title": "",
        "clip_title": "Jangan",
        "subtitle": [],
        "url": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_anon_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
        "url_resource": {
          "auto": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_auto_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
          "anon": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_anon_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
          "low": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_low_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
          "basic": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_basic_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
          "high": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_high_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
          "ultra": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_ultra_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8"
        },
        "bridge_id": "",
        "bridge_url": "",
        "bridge_url_resource": "",
        "bridge_subtitle": [],
        "bridge_metadata": null,
        "primary_lang": "ID",
        "duration": 113,
        "default_option": "",
        "option": [],
        "metadata": {
          "is_endplot": true,
          "media": {
            "id": 1,
            "link": "https://www.pegadaian.co.id/produk/tabungan-emas",
            "button": {
              "text": "Nabung Sekarang",
              "text_color": "#ffffff",
              "color": "#004d43"
            },
            "banner": {
              "horizontal": "https://vod.tadotv.com/banner/horizontal/1562751350078-ep3ps.jpg",
              "vertical_long": "https://vod.tadotv.com/banner/vertical_long/1562751851664-OFuwT.jpg",
              "vertical": "https://vod.tadotv.com/banner/vertical_regular/1563345607635-mdV52.jpg",
              "vertical_time": {
                "start": 112,
                "end": 0
              },
              "vertical_long_time": {
                "start": 112,
                "end": 0
              },
              "horizontal_time": {
                "start": 112,
                "end": 0
              }
            }
          }
        }
      },
      {
        "id": 3108,
        "title": "",
        "clip_title": "Coba",
        "subtitle": [],
        "url": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_anon_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
        "url_resource": {
          "auto": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_auto_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
          "anon": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_anon_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
          "low": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_low_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
          "basic": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_basic_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
          "high": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_high_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8",
          "ultra": "https://vod.tadotv.com/result/rs_15754419179688LVzx644_1562262377219-iawPQ/master_ultra_rs_15754419179688LVzx644_1562262377219-iawPQ.m3u8"
        },
        "bridge_id": "",
        "bridge_url": "",
        "bridge_url_resource": "",
        "bridge_subtitle": [],
        "bridge_metadata": null,
        "primary_lang": "ID",
        "duration": 188,
        "default_option": "",
        "option": [],
        "metadata": {
          "is_endplot": true,
          "media": {
            "id": 1,
            "link": "https://www.pegadaian.co.id/produk/tabungan-emas",
            "button": {
              "text": "Nabung Sekarang",
              "text_color": "#ffffff",
              "color": "#004d43"
            },
            "banner": {
              "horizontal": "https://vod.tadotv.com/banner/horizontal/1562751350078-ep3ps.jpg",
              "vertical_long": "https://vod.tadotv.com/banner/vertical_long/1562751851664-OFuwT.jpg",
              "vertical": "https://vod.tadotv.com/banner/vertical_regular/1563345607635-mdV52.jpg",
              "vertical_time": {
                "start": 140,
                "end": 0
              },
              "vertical_long_time": {
                "start": 140,
                "end": 0
              },
              "horizontal_time": {
                "start": 140,
                "end": 0
              }
            }
          }
        }
      },
      {
        "id": 3109,
        "title": "",
        "clip_title": "Jangan",
        "subtitle": [],
        "url": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_anon_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
        "url_resource": {
          "auto": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_auto_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
          "anon": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_anon_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
          "low": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_low_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
          "basic": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_basic_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
          "high": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_high_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8",
          "ultra": "https://vod.tadotv.com/result/rs_1562262594739b0GbcNiU_1562262581368-ZsoY3/master_ultra_rs_1562262594739b0GbcNiU_1562262581368-ZsoY3.m3u8"
        },
        "bridge_id": "",
        "bridge_url": "",
        "bridge_url_resource": "",
        "bridge_subtitle": [],
        "bridge_metadata": null,
        "primary_lang": "ID",
        "duration": 113,
        "default_option": "",
        "option": [],
        "metadata": {
          "is_endplot": true,
          "media": {
            "id": 1,
            "link": "https://www.pegadaian.co.id/produk/tabungan-emas",
            "button": {
              "text": "Nabung Sekarang",
              "text_color": "#ffffff",
              "color": "#004d43"
            },
            "banner": {
              "horizontal": "https://vod.tadotv.com/banner/horizontal/1562751350078-ep3ps.jpg",
              "vertical_long": "https://vod.tadotv.com/banner/vertical_long/1562751851664-OFuwT.jpg",
              "vertical": "https://vod.tadotv.com/banner/vertical_regular/1563345607635-mdV52.jpg",
              "vertical_time": {
                "start": 112,
                "end": 0
              },
              "vertical_long_time": {
                "start": 112,
                "end": 0
              },
              "horizontal_time": {
                "start": 112,
                "end": 0
              }
            }
          }
        }
      }
    ]
  }
}
